export function businessGroupVerModal(item, entity) {
  return {
    id: item.id,
    infoTitle: "Ver Grupo de Empresas",
    texts: [
      {
        text: "Nombre del Grupo de Empresas",
        value: item.name,
      },
    ],
    buttons: [
      {
        text: "Editar",
        action: "link",
        link: "/editar-grupo-de-empresas",
        classes: "neutral",
        entity: entity,
      },
      {
        text: "Cerrar",
        action: "closeModal",
        classes: "safe",
        entity: entity,
      },
      {
        text: "Exportar Recuento de prendas en pedidos",
        action: "link",
        link: "/exportar-recuento-grupo-empresas",
        classes: "safe",
        entity: entity,
      },
    ],
  };
}
