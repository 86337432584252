import React, { useState, useEffect } from "react";
//Styles
import "../../../../assets/css/print-order-count-workplaces.css";
//Redux
import { useSelector } from "react-redux";
//Components
import CountByWorkplaceTemplate from "../count/CountByWorkplaceTemplate";
import CountWorkplacesReady from "../count/CountWorkplacesReady";

const CountByWorkplacesTemplate = ({ setFrameTarjet }) => {
  //Store data
  const { rowsToPrint } = useSelector((state) => state.dataTable);
  const { pedido } = useSelector((state) => state.dataForm);

  //States
  const [showDocuments, setShowDocuments] = useState(false);
  const [results, setResults] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState([]);

  //Functions
  const getRowsToPrint = () => {
    let res = [];
    if (rowsToPrint.length === 0) {
      res = pedido.rows;
    } else {
      // eslint-disable-next-line
      rowsToPrint.map((rowToPrint) => {
        res.push(pedido.rows[rowToPrint]);
      });
    }
    return res;
  };

  const getAllWorkplaces = (results) => {
    let allWorkplaces = [];

    // eslint-disable-next-line
    results.map((result) => {
      //first workplace
      if (allWorkplaces.length === 0) {
        allWorkplaces.push({
          workplace: result.workplace,
          allGarments: [],
        });
      } else {
        let containsWorkplace = false;

        //there are already some workplaces

        // eslint-disable-next-line
        allWorkplaces.map((workplace) => {
          if (
            //If is same workplace
            workplace.workplace === result.workplace
          ) {
            containsWorkplace = true;
          }
        });

        //adds it if doesn´t exists
        if (!containsWorkplace) {
          allWorkplaces.push({
            workplace: result.workplace,
            allGarments: [],
          });
        }
      }
    });
    return allWorkplaces;
  };

  const getAllWorkplacesWithAllGarments = (results, workplaces) => {
    let res = workplaces;

    // eslint-disable-next-line
    results.map((result) => {
      //Match workplace
      // eslint-disable-next-line
      workplaces.map((workplace, index) => {
        //If is same workplace
        if (workplace.workplace === result.workplace) {
          // eslint-disable-next-line
          result.garments.map((garment) => {
            res[index].allGarments.push(garment);
          });
        }
      });
    });

    return res;
  };

  const getWorkplacesWithFormattedGarments = (allWorkplacesWithAllGarments) => {
    let arrangedGarmentsByWorkplace = [];

    // eslint-disable-next-line
    allWorkplacesWithAllGarments.map((workplace) => {
      let garmentsCounts = [];

      // eslint-disable-next-line
      workplace.allGarments.map((garment) => {
        if (
          //No garments yet
          garmentsCounts.length === 0
        ) {
          //object per garment with array populated with size & quantity objects
          garmentsCounts.push({
            name: garment.prenda.name,
            sizes: [
              {
                size: garment.size,
                quantity: garment.quantity,
              },
            ],
          });
        } else {
          //Check against current saved garments

          let containsGarment = false;

          // eslint-disable-next-line
          garmentsCounts.map((garmentCount) => {
            //If same garment
            if (garmentCount.name === garment.prenda.name) {
              //Check against current saved garments

              let containsSize = false;

              // eslint-disable-next-line
              garmentCount.sizes.map((size) => {
                //if same size
                if (size.size === garment.size) {
                  size.quantity = size.quantity + garment.quantity;
                  containsSize = true;
                }
              });

              //if garment size wasn´t created adds it
              if (!containsSize) {
                garmentCount.sizes.push({
                  size: garment.size,
                  quantity: garment.quantity,
                });
              }
              containsGarment = true;
            }
          });
          //if garment wasn´t created adds it
          if (!containsGarment) {
            garmentsCounts.push({
              name: garment.prenda.name,
              sizes: [
                {
                  size: garment.size,
                  quantity: garment.quantity,
                },
              ],
            });
          }
        }
      });

      arrangedGarmentsByWorkplace.push({
        workplace: workplace.workplace,
        garments: garmentsCounts,
      });
    });

    return arrangedGarmentsByWorkplace;
  };

  const getWorplacesWithFormattedGarmentsAndTotals = (
    worplacesWithFormattedGarments
  ) => {
    let res = [];

    // eslint-disable-next-line
    worplacesWithFormattedGarments.map((workplace) => {
      // eslint-disable-next-line
      workplace.garments.map((garment, index) => {
        let total = 0;
        // eslint-disable-next-line
        garment.sizes.map((size) => {
          total = total + size.quantity;
        });
        workplace.garments[index].total = total;
      });
      res.push(workplace);
    });

    return res;
  };

  const getPaginatedCountByWorkplace = (
    worplacesWithFormattedGarmentsAndTotals
  ) => {
    let res = [];
    const maxLines = 35;
    let currentLines = 0;
    var page = { workplace: "", garments: [] };

    // One size is considered one line of maxLines. One header is considered 4 lines of max Lines.

    /**
     * Workplaces
     */

    // eslint-disable-next-line
    worplacesWithFormattedGarmentsAndTotals.map((workplace, index) => {
      //Reset vars for subsequent pages
      if (currentLines !== 0) {
        //Send last page
        res.push(page);
        //Reset vars
        currentLines = 0;
        page = { workplace: "", garments: [] };
      }

      const workplaceName = workplace.workplace;

      //Each workplace generates new page with his name and room for garments
      page = { workplace: workplaceName, garments: [] };

      /**
       * Garments
       */

      // eslint-disable-next-line
      workplace.garments.map((garment, ind) => {
        const garmentName = garment.name;
        const total = garment.total;

        //Each garment fills at least one garments position

        if (currentLines >= maxLines - 5) {
          //Garment does not fit in page (4 for the header and one for at least one size)

          //Pushes actual page
          res.push(page);

          //Reset variables
          currentLines = 0;
          page = {};

          //Add this iteration data & soom for sizes
          page = {
            workplace: workplaceName,
            garments: [{ name: garmentName, total: total, sizes: [] }],
          };

          currentLines = currentLines + 4;
        } else {
          //Garment fits in page

          page.garments.push({
            name: garmentName,
            total: total,
            sizes: [],
          });

          currentLines = currentLines + 4;
        }

        /**
         * Sizes
         */

        // eslint-disable-next-line
        garment.sizes.map((sizeInfo, index3) => {
          //Check if there are room in this page
          if (currentLines < maxLines) {
            // There´s enough room in the page
            page.garments[page.garments.length - 1].sizes.push({
              size: sizeInfo.size,
              quantity: sizeInfo.quantity,
            });

            currentLines = currentLines + 1;
          } else {
            // Pushes actual page
            res.push(page);

            // Reset variables
            currentLines = 0;
            page = {};

            // Add this iteration data & room for sizes
            page = {
              workplace: workplaceName,
              garments: [{ name: garmentName, total: total, sizes: [] }],
            };

            currentLines = currentLines + 4;

            // Add this iteration size

            page.garments[page.garments.length - 1].sizes.push({
              size: sizeInfo.size,
              quantity: sizeInfo.quantity,
            });

            currentLines = currentLines + 1;
          }

          // If this is the end of the Order
          if (
            worplacesWithFormattedGarmentsAndTotals.length - 1 === index &&
            workplace.garments.length - 1 === ind &&
            garment.sizes.length - 1 === index3
          ) {
            res.push(page);
          }
        });
      });
    });

    return res;
  };

  //useEffect
  useEffect(() => {
    if (pedido) {
      setResults(getRowsToPrint());
    }
    // eslint-disable-next-line
  }, [pedido, rowsToPrint]);

  useEffect(() => {
    if (results && results.length > 0) {
      //Get all workplaces
      let allWorkplaces = getAllWorkplaces(results);

      //Add all garments to each workplace
      let allWorkplacesWithAllGarments = getAllWorkplacesWithAllGarments(
        results,
        allWorkplaces
      );
      //Free memory
      allWorkplaces = false;

      //Group & format each workplace garments
      let worplacesWithFormattedGarments = getWorkplacesWithFormattedGarments(
        allWorkplacesWithAllGarments
      );
      //Free memory
      allWorkplacesWithAllGarments = false;

      //Add totals
      let worplacesWithFormattedGarmentsAndTotals =
        getWorplacesWithFormattedGarmentsAndTotals(
          worplacesWithFormattedGarments
        );
      //Free memory
      worplacesWithFormattedGarments = false;

      //Paginate results each workplace new page, each 35 lines new page
      const paginatedCountByWorkplace = getPaginatedCountByWorkplace(
        worplacesWithFormattedGarmentsAndTotals
      );
      //Free memory
      worplacesWithFormattedGarmentsAndTotals = false;

      setPaginatedResults(paginatedCountByWorkplace);
      setShowDocuments(true);
    } else {
      setShowDocuments(false);
    }
    // eslint-disable-next-line
  }, [results]);

  return (
    <div id="count-to-print-workplaces">
      {showDocuments && (
        <>
          {
            // eslint-disable-next-line
            paginatedResults.map((result, index) => (
              <div key={"count-garments-result-" + index}>
                <div>
                  <div
                    id={"count-garments"}
                    style={{
                      boxSizing: "border-box",
                      border: "1px solid black",
                      height: `${
                        index !== paginatedResults.length - 1 ? "100%" : "99%"
                      }`,
                      minHeight: "100%",
                      width: "100%",
                      margin: "auto",
                      marginTop: "0px !important",
                      fontFamily: "SF Pro Display Regular",
                      marginBottom: `${
                        index !== paginatedResults.length - 1 ? "3%" : "0"
                      }`,
                      position: "relative",
                    }}
                    key={Math.floor(Math.random() * 100001)}
                  >
                    <CountByWorkplaceTemplate
                      clientName={pedido.client.name}
                      result={result}
                      workplace={result.workplace}
                    />
                  </div>
                </div>
              </div>
            ))
          }
          <CountWorkplacesReady setFrameTarjet={setFrameTarjet} />
        </>
      )}
    </div>
  );
};

export default CountByWorkplacesTemplate;
