import React, { useState, useEffect } from "react";
//Styles
import "../../assets/css/document-select.css";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory, useParams } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Components
import SectionHeader from "../tableSections/SectionHeader";
import PrintOrderSelectDataButton from "../sections/documents/PrintOrderSelectDataButton";
import PrintOrderLabelsButton from "../sections/documents/label/PrintOrderLabelsButton";
import PrintOrderDeliveryNoteButton from "../sections/documents/deliveryNote/PrintOrderDeliveryNoteButton";
import PrintOrderCountButton from "../sections/documents/count/PrintOrderCountButton";
import PrintOrderReclamationButton from "../sections/documents/reclamation/PrintOrderReclamationButton";
import PrintOrderPartialSelectionModal from "../sections/documents/PrintOrderPartialSelectionModal";
import PrintOrderFrameLoad from "../sections/documents/PrintOrderFrameLoad";
import PrintOrderAlcampo from "./documents/label/PrintOrderLabelsButtonAlcampo";
//Redux
import { useSelector, useDispatch } from "react-redux";
//Redux actions
import {
  loadFormSettings,
  loadPedido,
  loadModalSelectOptions,
} from "../../actions/formDataActions";

const DocumentSelection = () => {
  //Store data
  const { settings } = useSelector((state) => state.dataForm);
  
  const { rowsToPrint } = useSelector((state) => state.dataTable);

  //States
  const [showModal, setShowModal] = useState(false);
  const [alcampo, setAlcampo] = useState(false);
  const [titleAlcampo, setTitleAlcampo] = useState(false)

  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");
  let { id } = useParams();

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadSettings = (defaultSettings) =>
    dispatch(loadFormSettings(defaultSettings));
  const storeLoadPedido = (pedido) => dispatch(loadPedido(pedido));
  const storeLoadModalSelectOptions = (rows) =>
    dispatch(loadModalSelectOptions(rows));

  //API
  const GET_PEDIDO = gql`
    query getPedido {
      getPedido(id: ${id}) {
        id
        client {
          name
          workplaces {
            name
            address
            postal_code
            location {
              name
            }
            province {
              name
            }
            country {
              name
            }
          }
        }
        order_date
        order_number
        isDeliveryNote
        isLabel
        isCount
        isReclamation
        rows {
          id
          worker_name
          worker_code
          dni
          workplace
          job
          sex
          garments {
            id
            size
            quantity
            prenda {
              name
              reference
            }
          }
        }
      }
    }
  `;

  const [getPedido, { error, data }] = useLazyQuery(GET_PEDIDO);

  

  //Functions

  const changeAlcampo = () => {
    setAlcampo(!alcampo)
    setTitleAlcampo(!titleAlcampo)
  }

  //useEffect
  useEffect(() => {
    //Get pedido info
    getPedido();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && !error) {


      //Section Settings
      const defaultSettings = {
        entity: "document",
        queryName: "getPedido",
        title: "Emitir documentos - Pedido para " + data.getPedido.client.name,
        text: "Por favor, pulse en un tipo de documento para emitirlo. Se enviarán a imprimir todas las líneas del pedido, si desea mandar solo una parte pulse Seleccionar datos a emitir.",
        selectPartialPrintButtonText: "Seleccionar datos a imprimir",
      };

      //Load section settings
      storeLoadSettings(defaultSettings);

      //Load pedido
      storeLoadPedido(data.getPedido);

      //Load modal select rows
      const getGarmentAmount = (garmentsData) => {
        let res = 0;
        // eslint-disable-next-line
        garmentsData.map((garment) => {
          res = res + garment.quantity;
        });
        return res;
      };
      let rows = [];
      // eslint-disable-next-line

      data.getPedido.rows.map((row, index) => {
        rows.push({
          pos: index,
          worker_name: row.worker_name,
          job: row.job,
          garmentAmount: getGarmentAmount(row.garments),
        });
      });
      storeLoadModalSelectOptions(rows);
    }
    // eslint-disable-next-line
  }, [data, error]);
  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">
            <SectionHeader title={settings.title} text={settings.text} />
            <div className="print-document__select-data-button-container">
              <PrintOrderSelectDataButton setShowModal={setShowModal} />
              <button style={{marginLeft: "20px"}} className="button neutral" onClick={changeAlcampo}>
                  {titleAlcampo ? (
                    "¿Desea exportar documentos de pedidos genéricos?"
                  ) : (
                    "¿Desea exportar documentos de pedidos de plantillas Alcampo?"
                  )}
              </button>
            </div>
            <div className="print-document__document-types-container">
              { alcampo ?  (
                <>
                <PrintOrderAlcampo />
                </>
              ) : (
                <>
                <PrintOrderLabelsButton />
                
                </>
              )}
              <PrintOrderDeliveryNoteButton />
              <PrintOrderCountButton />
              <PrintOrderReclamationButton />
            </div>
          </div>
          {showModal && (
            <PrintOrderPartialSelectionModal setShowModal={setShowModal} />
          )}
        </>
      )}
      <PrintOrderFrameLoad />
    </>
  );
};

export default DocumentSelection;
