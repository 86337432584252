import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import FieldOrderGarment from "../createOrderInputs/FieldOrderGarment";
import FieldOrderSize from "../createOrderInputs/FieldOrderSize";
import FieldOrderQuantity from "../createOrderInputs/FieldOrderQuantity";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux actions
import { loadGarmentRowDefault } from "../../../../../actions/formDataActions";

const OrderPrendaVariableRow = ({exampleOrderRow}) => {
  //Store Data
  const { formValues } = useSelector((state) => state.dataForm);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadGarmentRowDefault = (rowDefault) =>
    dispatch(loadGarmentRowDefault(rowDefault));

  //API
  const GET_PRENDAS = gql`
    query getPrendas {
      getPrendas {
        id
        name
      }
    }
  `;

  const [getPrendas, { error, data }] = useLazyQuery(GET_PRENDAS);

  //Functions
  const getGarmentsRows = () => {
    let res = [];
    let OrderKeys = Object.keys(formValues.order[exampleOrderRow]);
    //Order Row
    let OrderFirstRow = {};
    // eslint-disable-next-line
    OrderKeys.map((OrderKey) => {
      if(
        OrderKey !== formValues.name &&
        OrderKey !== formValues.workplace &&
        OrderKey !== formValues.job &&
        OrderKey !== formValues.surname1 &&
        OrderKey !== formValues.surname2 &&
        OrderKey !== formValues.worker_code &&
        OrderKey !== formValues.dni &&
        OrderKey !== formValues.sex
        ){
          OrderFirstRow[OrderKey] = formValues.order[exampleOrderRow][OrderKey];
        }
    })

    //Order valuePairs
    const garmentKeys = Object.keys(OrderFirstRow);

    // eslint-disable-next-line
    garmentKeys.map((garmentKey, index) => {
      if (index === 0 || index % 2 === 0) {
        res.push({
          name: garmentKey,
          dataOne: { name: garmentKey, value: OrderFirstRow[garmentKey] },
          dataTwo: {
            name: garmentKeys[index + 1],
            value: OrderFirstRow[garmentKeys[index + 1]],
          },
        });
      }
    });
    return res;
  };

  const garmentsRows = getGarmentsRows();

  useEffect(() => {
    //Get prendas options
    getPrendas();
    // eslint-disable-next-line
    garmentsRows.map((garment) => {
      storeLoadGarmentRowDefault({
        prenda_id: 0,
        match: garment.name,
        sizeValue: "",
        quantity: 0,
      });
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {data &&
        data.getPrendas && !error &&
        garmentsRows.map((garment, index) => (
          <div className="avoidFragmentKey" key={garment.name}>
            <FieldOrderGarment name={garment.name} pos={index} getPrendas={data.getPrendas} />
            <FieldOrderSize garment={garment} pos={index} />
            <FieldOrderQuantity garment={garment} pos={index} />
          </div>
        ))}
    </>
  );
};

export default OrderPrendaVariableRow;
