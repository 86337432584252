export function locationVerModal(item,entity){
    return {
      id: item.id,
      infoTitle: "Ver población",
      texts: [
        {
          text: "Nombre de la población",
          value: item.name,
        },
        {
          text: "Provincia a la que pertenece",
          value: item.province.name,
        },
        {
          text: "País al que pertenece",
          value: item.province.country.name,
        },
        {
          text: "Cantidad de centros de trabajo asociados",
          value: item.workplaces.length,
        },
      ],
      buttons: [
        {
          text: "Editar",
          action: "link",
          link: "/poblaciones/editar-poblacion",
          classes: "neutral",
          entity: entity,
        },
        {
          text: "Cerrar",
          action: "closeModal",
          classes: "safe",
          entity: entity,
        },
      ],
    };
}