import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as MagnifyingGlass } from "../../../assets/images/magnifyingGlass.svg";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  addLineToPrint,
  removeLineToPrint,
  addSearch,
} from "../../../actions/tableDataActions";
import { loadModalSelectOptions } from "../../../actions/formDataActions";

const PrintOrderPartialSelectionModal = ({ setShowModal }) => {
  //Store data
  const { selectOptionRows, pedido } = useSelector((state) => state.dataForm);
  const { search,rowsToPrint } = useSelector((state) => state.dataTable);

  //States
  const [activeSearch, setActiveSearch] = useState(search);

  //Redux actions
  const dispatch = useDispatch();
  const storeAddLineToPrint = (pos) => dispatch(addLineToPrint(pos));
  const storeRemoveLineToPrint = (pos) => dispatch(removeLineToPrint(pos));
  const storeLoadModalSelectOptions = (rows) =>
    dispatch(loadModalSelectOptions(rows));
  const storeAddSearch = (searchTerm) => dispatch(addSearch(searchTerm));

  //Functions
  const closeModal = () => {
    //Closes Modal
    setShowModal(false);
  };

  const avoidPropagation = (e) => {
    //Avoids propagation (close modal) when clicking a checkbox
    e.stopPropagation();
  };

  const checkHandler = (e) => {
    //Adds or removes line to print from store
    if (e.target.checked) {
      storeAddLineToPrint(parseInt(e.target.dataset.position));
    } else {
      storeRemoveLineToPrint(parseInt(e.target.dataset.position));
    }
  };

  const acceptHandler = () => {
    //Accept button, closes Modal
    setShowModal(false);
  };

  const getGarmentAmount = (garmentsData) => {
    //Gets row garment amount
    let res = 0;
    // eslint-disable-next-line
    garmentsData.map((garment) => {
      res = res + garment.quantity;
    });
    return res;
  };

  const searchHandler = (e) => {
    const searchTerm = e.target.value;
    storeAddSearch(searchTerm);
  };

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const getNewOptions = () => {
    //Filters select options
    //Load modal select rows
    let filteredRows = [];
    // eslint-disable-next-line
    pedido.rows.map((row, index) => {
      if (
        removeAccents(row.worker_name.toLowerCase()).includes(removeAccents(search.toLowerCase())) ||
        removeAccents(row.worker_code.toLowerCase()).includes(removeAccents(search.toLowerCase())) ||
        removeAccents(row.dni.toLowerCase()).includes(removeAccents(search.toLowerCase())) ||
        removeAccents(row.workplace.toLowerCase()).includes(removeAccents(search.toLowerCase())) ||
        removeAccents(row.job.toLowerCase()).includes(removeAccents(search.toLowerCase())) ||
        removeAccents(row.sex.toLowerCase()).includes(removeAccents(search.toLowerCase())) ||
        search === ""
      )
        filteredRows.push({
          pos: index,
          worker_name: row.worker_name,
          job: row.job,
          garmentAmount: getGarmentAmount(row.garments),
        });
    });
    storeLoadModalSelectOptions(filteredRows);
  };

  //useEffect
  useEffect(() => {
    setActiveSearch(search);
    getNewOptions();
    // eslint-disable-next-line
  }, [search]);

  return (
    <div className="modal__background" onClick={closeModal}>
      <div className="modal__body col-60" onClick={avoidPropagation}>
        <div className="modal-body__headers">
          <div className="modal-body-headers__title">
            Seleccione los datos a emitir
          </div>
          <div className="modal-body-headers__subtitle">
            Por favor, marque las casillas de las líneas del pedido que desee
            emitir
          </div>
        </div>
        <div className="modal-body__info bg-light-grey">
          <div className="modal-body-search__container">
            <input
              type="text"
              placeholder="Busque una línea del pedido"
              onChange={searchHandler}
              defaultValue={activeSearch}
            ></input>
            <MagnifyingGlass />
          </div>
          <div className="modal__table-headers-container">
            <div className="modal__table-header-spacer col-spacer">
              <p> </p>
            </div>
            <div className="modal__table-header-container col-10">
              <p>Línea</p>
            </div>
            <div className="modal__table-header-container col-50">
              <p>Trabajador</p>
            </div>
            <div className="modal__table-header-container col-15">
              <p>Prendas</p>
            </div>
            <div className="modal__table-header-container col-20">
              <p>Puesto</p>
            </div>
          </div>
          <div className="modal__table-rows-container">
            {selectOptionRows.map((row, index) => (
              <div className="modal__table-row-container" key={index}>
                <div className="modal__table-cell-spacer col-5">
                  <input
                    type="checkbox"
                    onChange={checkHandler}
                    data-position={row.pos}
                    checked={rowsToPrint.includes(index) ? true : false}
                  ></input>
                </div>
                <div className="modal__table-cell-container col-10">
                  <p>{row.pos + 1}</p>
                </div>
                <div className="modal__table-cell-container col-50">
                  <p>{row.worker_name}</p>
                </div>
                <div className="modal__table-cell-container col-15">
                  <p>{row.garmentAmount}</p>
                </div>
                <div className="modal__table-cell-container col-20">
                  <p>{row.job}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className="button safe col-20 mt-24" onClick={acceptHandler}>
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default PrintOrderPartialSelectionModal;
