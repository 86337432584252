import React from "react";
//GraphQL
import { useMutation, gql } from "@apollo/client";
//Redux
import { useSelector } from "react-redux";
//Notifications
import { toast } from "react-toastify";

const PrendasCreateButtonUpdate = () => {
  //Store Data
  const { formValues } = useSelector((state) => state.dataForm);

  //API
  const UPDATE_PRENDA = gql`
    mutation {
        updatePrenda(
            id: ${formValues.id},
            reference: "${formValues.reference ? formValues.reference : 0}",
            name: "${formValues.name ? formValues.name : 0}",
            tipo_prenda_id: ${
              formValues.tipo_prenda_id ? formValues.tipo_prenda_id : 0
            },
            description: "${
              formValues.description ? formValues.description : 0
            }",
            image: "${formValues.image ? formValues.image : ""}"
        ) {
            id
        }
    }
  `;

  // eslint-disable-next-line
  const [executeMutation, { data, error }] = useMutation(UPDATE_PRENDA);

  //Functions
  const retriveAction = () => {
    const messageOK = "La prenda se ha actualizado correctamente.";
    const messageKO = "Por favor, revise los campos marcados en rojo.";
    const redirection = `/buscar-prenda`;
    let validation = validatePrenda(formValues);

    if (validation) {
      //Validation ok

      //Execute mutation
      executeMutation()
        .then(({ data }) => {
          //Notify OK
          toast.success(messageOK);

          //Redirect
          window.location.href = redirection;
        })
        .catch((e) => {
          //Notify KO
          toast.error("Lo sentimos, no ha sido posible realizar la operación.");
        });
    } else {
      //Validation KO

      //Notify KO
      toast.error(messageKO);
    }
  };

  return (
    <button className="button safe" onClick={retriveAction}>
      Actualizar
    </button>
  );
};

export default PrendasCreateButtonUpdate;

function validatePrenda(formValues) {
  let res = false;

  if (
    formValues &&
    formValues.tipo_prenda_id &&
    formValues.name &&
    formValues.name !== "" &&
    formValues.description &&
    formValues.description !== "" &&
    formValues.image &&
    formValues.image !== ""
  ) {
    res = true;
  }

  return res;
}
