import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as MagnifyingGlass } from "../../assets/images/magnifyingGlass.svg";
import { ReactComponent as Chevron } from "../../assets/images/chevron.svg";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  addFilter,
  removeFilter,
  loadWorkOptions,
  reloadResults,
  loadCurrentPage,
} from "../../actions/tableDataActions";

const TableFilters = ({ position }) => {
  //Store data
  const { filters, settings, workData, originalData } = useSelector(
    (state) => state.dataTable
  );
  const { filterSettings } = settings;
  //States
  const [valuePlaceholder, setValuePlaceholder] = useState(
    filterSettings[position].placeholder
  );
  const [availableFilters, setAvailableFilters] = useState([]);
  const [filterSearchTerm, setFilterSearchTerm] = useState("");

  //Redux actions
  const dispatch = useDispatch();
  const storeAddFilter = (filter) => dispatch(addFilter(filter));
  const storeRemoveFilter = (filter) => dispatch(removeFilter(filter));
  const loadNewOptions = (config) => dispatch(loadWorkOptions(config));
  const storeReloadResults = (config) => dispatch(reloadResults(config));
  const storeLoadCurrentPage = (page) => dispatch(loadCurrentPage(page));

  //Functions
  const loadDateFilter = (e) => {
    //Sets filter value
    storeAddFilter({
      name: e.target.dataset.name,
      value: e.target.value,
      index: position,
    });

    // Resets page to 1
    storeLoadCurrentPage(1);

    //Adds Flag to reload content
    storeReloadResults(true);
  };

  const loadFilter = (e) => {
    //Sets filter value
    storeAddFilter({
      name: e.target.dataset.name,
      value: e.target.dataset.optionvalue,
      index: position,
    });

    // Sets new placeholder
    setValuePlaceholder(e.target.dataset.optionvalue);

    // Resets page to 1
    storeLoadCurrentPage(1);

    //Adds Flag to reload content
    storeReloadResults(true);

    //Resets search option input
    setFilterSearchTerm("");
  };

  const deleteFilter = (e) => {
    //Sets filter to default none
    storeRemoveFilter({
      name: e.target.dataset.name,
      value: "",
      index: position,
    });

    // Sets new placeholder
    setValuePlaceholder(filterSettings[position].placeholder);

    // Resets page to 1
    storeLoadCurrentPage(1);

    //Adds Flag to reload content
    storeReloadResults(true);
  };

  const filterFilterOptions = (e) => {
    setFilterSearchTerm(e.target.value);
  };

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const getNewFilterOptions = (searchTerm) => {
    let res = [];
    // eslint-disable-next-line
    if (availableFilters && availableFilters.length > 0) {
      // eslint-disable-next-line
      originalData[filterSettings[position].queryname].map((option) => {
        if (
          removeAccents(option.name.toLowerCase()).includes(removeAccents(searchTerm.toLowerCase())) ||
          searchTerm === ""
        ) {
          res.push(option);
        }
      });
    }
    return res;
  };

  //useEffect
  // Loads API options for this filter
  useEffect(() => {
    if (workData) {
      setAvailableFilters(
        workData[settings.filterSettings[position].queryname]
      );
    }
    // eslint-disable-next-line
  }, [workData]);

  useEffect(() => {
    // Sets placeholder to default or changed value

    // eslint-disable-next-line
    filters.map((activeFilter) => {
      if (activeFilter.name === filterSettings[position].name) {
        if (activeFilter.value === "") {
          //Default placeholder
          setValuePlaceholder(filterSettings[position].placeholder);
        } else {
          //Value placeholder
          setValuePlaceholder(filters[position].value);
        }
      }
    });

    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    //Loads new filter options according to search term if any
    const newOptions = getNewFilterOptions(filterSearchTerm);
    loadNewOptions({
      options: newOptions,
      attrname: filterSettings[position].queryname,
    });
    setAvailableFilters(workData[filterSettings[position].queryname]);
    // eslint-disable-next-line
  }, [filterSearchTerm]);

  return (
    <>
      {/*date filters*/}
      {(filters[position] && filters[position].name === "dateFrom") ||
      (filters[position] && filters[position].name === "dateTo") ||
      (filters[position] && filters[position].name === "date") ? (
        <>
          <input
            className="table__filter-date"
            type="date"
            data-name={filterSettings[position].name}
            onChange={loadDateFilter}
          ></input>
        </>
      ) : (
        <>
          {/* Display collapsed */}
          <div className="table__filter-collapsed">
            <input type="text" disabled placeholder={valuePlaceholder} />
            <Chevron />
          </div>
          {/* Display expanded */}
          <div className="table__filter-expanded">
            <div className="table__filter-expanded-container">
              {/* fixed search */}
              <div className="table__filter-search">
                <input type="text" onChange={filterFilterOptions} />
                <MagnifyingGlass />
              </div>
              <div className="table__filter-option-container">
                <div
                  className={`table__filter-option-default ${
                    filters[position].value === "" ? "current" : ""
                  }`}
                  data-name={filterSettings[position].name}
                  data-optionvalue=""
                  onClick={deleteFilter}
                >
                  Ninguno
                </div>
                {/* Filter - Select - Options - API */}
                {availableFilters &&
                  availableFilters.length > 0 &&
                  availableFilters.map((item, index) => (
                    <div
                      className={`table__filter-option ${
                        item.name === filters[position].value ? "current" : ""
                      }`}
                      key={index}
                      data-name={filterSettings[position].name}
                      data-optionvalue={item.name}
                      onClick={loadFilter}
                    >
                      {item.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TableFilters;
