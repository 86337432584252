import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/form.css";
//Cookie token
import cookie from "react-cookies";
import { useParams } from "react-router";
//Router redirect
import { useHistory } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormSettings,
  loadFormValue,
} from "../../../actions/formDataActions";
//Components
import SectionHeader from "../../tableSections/SectionHeader";
import ExportBusinessGroupCountFileGenerator from "../businessGroups/ExportBusinessGroupCountFileGenerator";
//Notifications
import { toast } from "react-toastify";

const ExportBusinessGroupCount = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");

  let { id } = useParams();

  //Store data
  const { settings, formValues } = useSelector((state) => state.dataForm);

  //States
  const [displayForm, setDisplayForm] = useState(false);
  const [displaySubmit, setDisplaySubmit] = useState(true);
  const [generateExport, setGenerateExport] = useState(false);
  const [dateFromValidation, setDateFromValidation] = useState(false);
  const [dateToValidation, setDateToValidation] = useState(false);

  //API
  const GET_BGROUP_NAME = gql`
  query getBusinessGroup {
    getBusinessGroup(id: ${id}) {
      name
    }
  }
  `;

  const [getBgroupName, { error, data }] = useLazyQuery(GET_BGROUP_NAME);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadSettings = (defaultSettings) =>
    dispatch(loadFormSettings(defaultSettings));
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //Functions
  const handleDateChange = (e) => {
    //Loads input value in store
    storeLoadFormValue([e.target.dataset.name, e.target.value]);
  };

  const validateForm = () => {
    let res = true;
    if (
      !formValues.dateFrom ||
      formValues.dateFrom === "" ||
      formValues.dateFrom > formValues.dateTo
    ) {
      setDateFromValidation(false);
      res = false;
    }

    if (
      !formValues.dateTo ||
      formValues.dateTo === "" ||
      formValues.dateFrom > formValues.dateTo
    ) {
      setDateToValidation(false);
      res = false;
    }

    if (res) {
      setDateFromValidation(true);
      setDateToValidation(true);
    }
    
    return res;
  };

  const clickHandler = () => {
    const validation = validateForm();

    if (!validation) {
      if (!formValues.dateFrom || formValues.dateFrom === "") {
        toast.error(
          "Por favor, seleccione la fecha DESDE donde desea exportar los pedidos del grupo."
        );
      } else if (!formValues.dateTo || formValues.dateTo === "") {
        toast.error(
          "Por favor, seleccione la fecha HASTA donde desea exportar los pedidos del grupo."
        );
      } else if (formValues.dateFrom > formValues.dateTo) {
        toast.error(
          "El inicio del rango de fechas no puede ser posterior al final."
        );
      }
    } else {
      setDisplaySubmit(false);
      setGenerateExport(true);
    }
  };

  //useEffect
  useEffect(() => {
    if (settings && settings !== {}) {
      setDisplayForm(true);
    } else {
      setDisplayForm(false);
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line
  }, [formValues.dateFrom, formValues.dateTo]);

  useEffect(() => {
    getBgroupName();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && data.getBusinessGroup && !error) {
      //Section Settings
      const defaultSettings = {
        entity: "businessGroup",
        queryName: "createBusinessGroup",
        title: "Exportar recuento de prendas de " + data.getBusinessGroup.name,
        text: "Por favor, seleccione el rango de fechas de los pedidos y después pulse en aceptar para descargar la excel.",
        formContent: [
          {
            type: "fieldText",
            name: "name",
            size: 75,
            label: "Nombre del Grupo de Empresas",
            placeholder: "Nombre del nuevo Grupo de Empresas",
            required: true,
            validated: true,
            validation: "notEmpty",
          },
          {
            type: "buttons",
            buttons: [
              {
                text: "Crear",
                action: "save",
                classes: "safe",
                actionType: "save",
                entity: "businessGroup",
              },
              {
                text: "Cancelar",
                action: "link",
                link: "/buscar-grupo-de-empresas",
                classes: "unsafe",
                entity: "businessGroup",
              },
            ],
          },
        ],
      };

      storeLoadSettings(defaultSettings);
    } else {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [data, error]);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">
            {displayForm && (
              <>
                <SectionHeader title={settings.title} text={settings.text} />
                <div className="form form__first-row">
                  <div>
                    <div className="input-label">Desde...</div>
                    <input
                      className={`table__filter-date ${
                        !dateFromValidation ? "errorInput" : ""
                      }`}
                      type="date"
                      data-name="dateFrom"
                      onChange={handleDateChange}
                    ></input>
                  </div>
                  <div>
                    <div className="input-label">Hasta...</div>
                    <input
                      className={`table__filter-date ${
                        !dateToValidation ? "errorInput" : ""
                      }`}
                      type="date"
                      data-name="dateTo"
                      onChange={handleDateChange}
                    ></input>
                  </div>
                </div>
                <div
                  className="form form__button-row"
                  style={{ marginTop: "24px" }}
                >
                  {displaySubmit && (
                    <button className="button safe" onClick={clickHandler}>
                      Aceptar
                    </button>
                  )}
                </div>
                <div
                  className="form form__button-row"
                  style={{ marginTop: "24px" }}
                >
                  {generateExport && (
                    <ExportBusinessGroupCountFileGenerator
                      setGenerateExport={setGenerateExport}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ExportBusinessGroupCount;
