import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/form.css";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormSettings,
} from "../../../actions/formDataActions";
//Components
import Form from "../../formSections/Form";

const BusinessGroupsCreate = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");

  //Store data
  const { settings } = useSelector((state) => state.dataForm);

  //States
  const [displayForm, setDisplayForm] = useState(false);

  //Section Settings
  const defaultSettings = {
    entity: "businessGroup",
    queryName: "createBusinessGroup",
    title: "Crear Grupo de Empresas",
    text: "Por favor, indique el nombre del Grupo de Empresas y después pulse en aceptar para que los cambios se guarden.",
    formContent: [
      {
        type: "fieldText",
        name: "name",
        size: 75,
        label: "Nombre del Grupo de Empresas",
        placeholder: "Nombre del nuevo Grupo de Empresas",
        required: true,
        validated: true,
        validation: "notEmpty",
      },
      {
        type: "buttons",
        buttons: [
          {
            text: "Crear",
            action: "save",
            classes: "safe",
            actionType: "save",
            entity: "businessGroup",
          },
          {
            text: "Cancelar",
            action: "link",
            link: "/buscar-grupo-de-empresas",
            classes: "unsafe",
            entity: "businessGroup",
          },
        ],
      },
    ],
  };

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadSettings = () => dispatch(loadFormSettings(defaultSettings));

  //useEffect
  useEffect(() => {
    if (settings && settings !== {}) {
      setDisplayForm(true);
    } else {
      setDisplayForm(false);
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    storeLoadSettings();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">{displayForm && <Form />}</div>
        </>
      )}
    </>
  );
};

export default BusinessGroupsCreate;

