export function prendaTypeVerModal(item,entity){
    return {
        id: item.id,
        infoTitle: "Ver tipo de prenda",
        texts: [
          {
            text: "Nombre",
            value: item.name,
          },
        ],
        buttons: [
          {
            text: "Editar",
            action: "link",
            link: "/editar-tipo-de-prenda",
            classes: "neutral",
            entity: entity,
          },
          {
            text: "Cerrar",
            action: "closeModal",
            classes: "safe",
            entity: entity,
          },
        ],
      }
}