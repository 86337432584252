import React from "react";
//Redux
import { useSelector } from "react-redux";

const PrintOrderSelectDataButton = ({ setShowModal }) => {
  //Store data
  const { settings } = useSelector((state) => state.dataForm);

  const retrieveAction = () => {
    setShowModal(true);
  };
  return (
    <button className="button neutral" onClick={retrieveAction}>
      {settings.selectPartialPrintButtonText}
    </button>
  );
};

export default PrintOrderSelectDataButton;
