import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { loadFormValue } from "../../../../actions/formDataActions";

const PrendasCreateDescription = ({ defaultValue }) => {
  //Store data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [displayError, setDisplayError] = useState(false);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //Functions
  const loadInputValue = (e) => {
    storeLoadFormValue(["description", e.target.value]);
  };

  //useEffect
  useEffect(() => {
    //Check field validation
    if (formValues && formValues.description && formValues.description !== "") {
      setDisplayError(false);
    } else {
      setDisplayError(true);
    }
    // eslint-disable-next-line
  }, [formValues.description]);

  useEffect(() => {
    //In case of update, load current value into form values
    if (defaultValue) {
      storeLoadFormValue(["description", defaultValue]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="input-container">
      <div className="input-label">Breve descripción de la prenda</div>
      <textarea
        type="text"
        name="description"
        placeholder="Escriba una breve descripción *"
        className={displayError === true ? " errorInput " : ""}
        onChange={loadInputValue}
        defaultValue={defaultValue ? defaultValue : ""}
      ></textarea>
    </div>
  );
};

export default PrendasCreateDescription;
