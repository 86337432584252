export function businessGroupEliminarModal(item, entity) {
  return {
    id: item.id,
    title: "Por favor, confirme que desea eliminar este Grupo de Empresas",
    subtitle:
      "¡CUIDADO! Eliminar este Grupo de Empresas ELIMINARÁ también TODOS los CLIENTES, PEDIDOS y CENTROS DE TRABAJO que tenga asociados. Esta acción no se puede deshacer.",
    texts: [
      {
        text: "Nombre del Grupo de Empresas",
        value: item.name,
      },
    ],
    buttons: [
      {
        text: "Confirmar",
        action: "delete",
        classes: "unsafe",
        entity: entity,
        actionType: "mutation",
      },
      {
        text: "Cancelar",
        action: "closeModal",
        classes: "safe",
        entity: entity,
      },
    ],
  };
}
