import React from "react";

const Label18Alcampo = ({ result }) => {
  return (
    <>
      <div>
        {/* Label Headers */}
        <div
          id="label__heading-container"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "2.5% 2.5%",
            borderBottom: "1px solid black",
            fontSize: "12px",
          }}
        >
          <div
            id="label__heading-row"
            style={{
              display: "flex",
            }}
          >
            <div style={{ width: "18%" }}>Cliente:</div>
            <div
              style={{
                width: "82%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
              }}
            >
              {result.cliente}
            </div>
          </div>
          <div
            id="label__heading-row"
            style={{
              display: "flex",
            }}
          >
            <div style={{ width: "18%" }}>Centro T.:</div>
            <div
              style={{
                width: "82%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
              }}
            >
              {result.workplace}
            </div>
          </div>
          <div
            id="label__heading-row"
            style={{
              display: "flex",
            }}
          >
            <div style={{ width: "18%" }}>Categoría:</div>
            <div
              style={{
                width: "82%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
              }}
            >
              {result.job}
            </div>
          </div>
          <div
            id="label__heading-row"
            style={{
              display: "flex",
            }}
          >
            <div style={{ width: "18%" }}>Empleado:</div>
            <div
              style={{
                width: "82%",
                maxWidth: "82%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {result.worker_code && result.worker_code !== "- -" && result.worker_code + " - "}
              {result.dni && result.dni !== "- -" && result.dni + " - "}
              {result.worker_name}
            </div>
          </div>
        </div>
        {/* Rows Headers */}
        <div
          className="label__rows-heading-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "2.5% 2.5% 1.5% 2.5%",
            borderBottom: "1px solid black",
            margin: "0 2.5% 4% 2.5%",
            fontSize: "12px",
          }}
        >
          <div
            style={{
              width: "70%",
              fontFamily: "SF Pro Display",
              fontWeight: 600,
            }}
          >
            Prenda
          </div>
          <div
            style={{
              width: "20%",
              fontFamily: "SF Pro Display",
              fontWeight: 600,
            }}
          >
            Tal.
          </div>
          <div
            style={{
              width: "10%",
              fontFamily: "SF Pro Display",
              fontWeight: 600,
            }}
          >
            Can.
          </div>
        </div>
        {/* Rows Garments */}
        {result.garments.map((garment, index) => (
          <div
            className="label__rows-container"
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: "0% 2.5%",
              margin: "1.5% 2.5%",
              fontSize: "12px",
            }}
            key={"prenda" + index}
          >
            <div
              style={{
                width: "69%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "1%",
              }}
            >
              {garment.prenda.reference}
            </div>
            <div
              style={{
                width: "20%",
              }}
            >
              {garment.size}
            </div>
            <div
              style={{
                width: "10%",
              }}
            >
              {garment.quantity}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Label18Alcampo;
