export function getQueryData(entity, id, textAction) {
  //Generic query to avoid exception. Will change if query needed.
  let res = {
    queryName: "updatePrenda",
    queryParameters: `(id: ${id})`,
    queryFields: ["id"],
  };

  switch (entity) {
    case "prenda":
      if (textAction === "Ver" || textAction === "Eliminar") {
        res.queryName = "getPrenda";
        res.queryFields = [
          "id",
          "name",
          "reference",
          "type { id name }",
          "description",
          "image",
        ];
      }
      break;
    case "prendaType":
      if (textAction === "Ver" || textAction === "Eliminar") {
        res.queryName = "getPrendaType";
        res.queryFields = ["id", "name"];
      }
      break;
    case "cliente":
      if (textAction === "Eliminar") {
        res.queryName = "getCliente";
        res.queryFields = [
          "id",
          "client_code",
          "name",
          "business_name",
          "orders{id}",
        ];
      }
      break;
    case "businessGroup":
      if (textAction === "Ver" || textAction === "Eliminar") {
        res.queryName = "getBusinessGroup";
        res.queryFields = [
          "id",
          "name",
        ];
      }
      break;
    case "pedido":
      if (textAction === "Eliminar") {
        res.queryName = "getPedido";
        res.queryFields = [
          "id",
          "client{name}",
          "order_date",
          "order_number",
          "isDeliveryNote",
          "isLabel",
          "isCount",
          "isReclamation",
        ];
      }
      break;
    case "pedidoRow":
      if (textAction === "Eliminar") {
        res.queryName = "getPedidoRow";
        res.queryFields = [
          "id",
          "worker_name",
          "worker_code",
          "dni",
          "workplace",
          "job",
          "sex",
          "garments{id prenda{name} size quantity}",
        ];
      }
      break;
    case "country":
      if (textAction === "Ver" || textAction === "Eliminar") {
        res.queryName = "getCountry";
        res.queryFields = ["name", "id"];
      }
      break;
    case "province":
      if (textAction === "Ver" || textAction === "Eliminar") {
        res.queryName = "getProvince";
        res.queryFields = ["name", "id", "country { id name }"];
      }
      break;
    case "location":
      if (textAction === "Ver" || textAction === "Eliminar") {
        res.queryName = "getLocation";
        res.queryFields = [
          "name",
          "id",
          "province { id name country { id name } }",
          "workplaces{ id }",
        ];
      }
      break;
    case "workplace":
      if (textAction === "Ver" || textAction === "Eliminar") {
        res.queryName = "getClienteWorkplace";
        res.queryFields = [
          "id",
          "name",
          "address",
          "postal_code",
          "location { name province { name country { name } } }",
        ];
      }
      break;
    default:
      break;
  }

  return res;
}
