import { LOAD_ORIGINAL_RESULTS } from "../types/tableData";
import { LOAD_WORK_RESULTS } from "../types/tableData";
import { INIT_FILTERS } from "../types/tableData";
import { ADD_FILTER } from "../types/tableData";
import { REMOVE_FILTER } from "../types/tableData";
import { ADD_SEARCH } from "../types/tableData";
import { REMOVE_SEARCH } from "../types/tableData";
import { ADD_ORDERBY } from "../types/tableData";
import { LOAD_SETTINGS } from "../types/tableData";
import { LOAD_FILTER_OPTIONS } from "../types/tableData";
import { LOAD_PAGINATOR_ITEMS } from "../types/tableData";
import { RELOAD_RESULTS } from "../types/tableData";
import { LOAD_CURRENT_PAGE } from "../types/tableData";
import { LOAD_ORDERBY_ITEMS } from "../types/tableData";
import { LOAD_MODAL_CONTENT } from "../types/tableData";
import { DELETE_TABLE_ITEM } from "../types/tableData";
import { ADD_ROW_2PRINT } from "../types/tableData";
import { REMOVE_ROW_2PRINT } from "../types/tableData";

//Load original results for reference
export function loadOriginalResults(data) {
  return (dispatch) => {
    dispatch({
      type: LOAD_ORIGINAL_RESULTS,
      payload: data,
    });
  };
}

export function loadWorkResults(data) {
  return (dispatch) => {
    dispatch({
      type: LOAD_WORK_RESULTS,
      payload: data,
    });
  };
}

export function initFilters(filters) {
  return (dispatch) => {
    dispatch({
      type: INIT_FILTERS,
      payload: filters,
    });
  };
}

export function addFilter(filter) {
  return (dispatch) => {
    dispatch({
      type: ADD_FILTER,
      payload: filter,
    });
  };
}

export function removeFilter(filter) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_FILTER,
      payload: filter,
    });
  };
}

export function loadWorkOptions(config) {
  return (dispatch) => {
    dispatch({
      type: LOAD_FILTER_OPTIONS,
      payload: config,
    });
  };
}

export function addSearch(searchTerm) {
  return (dispatch) => {
    dispatch({
      type: ADD_SEARCH,
      payload: searchTerm,
    });
  };
}

export function removeSearch() {
  return (dispatch) => {
    dispatch({
      type: REMOVE_SEARCH,
    });
  };
}

export function addOrderBy(order) {
  return (dispatch) => {
    dispatch({
      type: ADD_ORDERBY,
      payload: order,
    });
  };
}

export function loadSettings(settings) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SETTINGS,
      payload: settings,
    });
  };
}

export function loadPaginatorItems(paginatorItems) {
  return (dispatch) => {
    dispatch({
      type: LOAD_PAGINATOR_ITEMS,
      payload: paginatorItems,
    });
  };
}

export function reloadResults(conf) {
  return (dispatch) => {
    dispatch({
      type: RELOAD_RESULTS,
      payload: conf,
    });
  };
}

export function loadCurrentPage(page) {
  return (dispatch) => {
    dispatch({
      type: LOAD_CURRENT_PAGE,
      payload: page,
    });
  };
}

export function loadOrderBy(newOrderByItems) {
  return (dispatch) => {
    dispatch({
      type: LOAD_ORDERBY_ITEMS,
      payload: newOrderByItems,
    });
  };
}

export function loadModalContent(modalContent) {
  return (dispatch) => {
    dispatch({
      type: LOAD_MODAL_CONTENT,
      payload: modalContent,
    });
  };
}

// Deletes table item from store
export function deleteTableItem(id, queryName) {
  return (dispatch) => {
    dispatch({
      type: DELETE_TABLE_ITEM,
      id,
      queryName,
    });
  };
}

// Adds to array stor line to be printed
export function addLineToPrint(pos) {
  return (dispatch) => {
    dispatch({
      type: ADD_ROW_2PRINT,
      pos,
    });
  };
}

// Remove from array stor line to be printed
export function removeLineToPrint(pos) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ROW_2PRINT,
      pos,
    });
  };
}
