import React from "react";

const Label11Template = ({ pedido, result }) => {
  return (
    <>
      {/* Label Headers */}
      <div
        id="label__heading-container"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "5% 5%",
          borderBottom: "1px solid black",
        }}
      >
        <div
          id="label__heading-row"
          style={{
            display: "flex",
          }}
        >
          <div style={{ width: "20%" }}>Cliente:</div>
          <div
            style={{
              width: "80%",
              fontFamily: "SF Pro Display",
              fontWeight: 700,
            }}
          >
            {pedido.client.name}
          </div>
        </div>
        <div
          id="label__heading-row"
          style={{
            display: "flex",
          }}
        >
          <div style={{ width: "20%" }}>Centro T.:</div>
          <div
            style={{
              width: "80%",
              fontFamily: "SF Pro Display",
              fontWeight: 700,
            }}
          >
            {result.workplace}
          </div>
        </div>
        <div
          id="label__heading-row"
          style={{
            display: "flex",
          }}
        >
          <div style={{ width: "20%" }}>Categoría:</div>
          <div
            style={{
              width: "80%",
              fontFamily: "SF Pro Display",
              fontWeight: 700,
            }}
          >
            {result.job}
          </div>
        </div>
        <div
          id="label__heading-row"
          style={{
            display: "flex",
          }}
        >
          <div style={{ width: "20%" }}>Empleado:</div>
          <div
            style={{
              width: "80%",
              fontFamily: "SF Pro Display",
              fontWeight: 700,
            }}
          >
            {result.worker_code && result.worker_code !== "- -" && result.worker_code + " - "}
            {result.dni && result.dni !== "-" && result.dni !== "- -" && result.dni + " - "}
            {result.worker_name}
          </div>
        </div>
      </div>
      {/* Rows Headers */}
      <div
        className="label__rows-heading-container"
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "5% 2.5% 2.5% 2.5%",
          borderBottom: "1px solid black",
          margin: "0 5%",
        }}
      >
        <div
          style={{
            width: "75%",
            fontFamily: "SF Pro Display",
            fontWeight: 700,
          }}
        >
          Prenda
        </div>
        <div
          style={{
            width: "20%",
            fontFamily: "SF Pro Display",
            fontWeight: 700,
            whiteSpace: 'pre-wrap',
          }}
        >
          Talla
        </div>
        <div
          style={{
            width: "5%",
            fontFamily: "SF Pro Display",
            fontWeight: 700,
            whiteSpace: 'pre-wrap',
          }}
        >
          Cant.
        </div>
      </div>
      {/* Rows Garments */}
      {result.garments.map((garment, index) => (
        <div
          className="label__rows-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "0% 2.5%",
            margin: "2% 5%",
          }}
          key={"prenda" + index}
        >
          <div
            style={{
              width: "75%",
              whiteSpace: 'pre-wrap',
            }}
          >
            {garment.prenda.name}
          </div>
          <div
            style={{
              width: "20%",
              whiteSpace: 'pre-wrap',
            }}
          >
            {garment.size}
          </div>
          <div
            style={{
              width: "5%",
            }}
          >
            {garment.quantity}
          </div>
     <div id="labels-1-1-loaded"></div>
        </div>
      ))}
    </>
  );
};

export default Label11Template;
