export function lineaPedidoDescartarModal(entity, formValues) {
  return {
    id: formValues.id,
    title:
      "Por favor, confirme que desea cancelar la edición de esta línea del pedido",
    subtitle:
      "¡CUIDADO! Se perderán todos los cambios que haya realizado en esta línea del pedido. Esta acción no se puede deshacer.",
    buttons: [
      {
        text: "Confirmar",
        action: "link",
        classes: "unsafe",
        link: `/ver-pedido`,
        entity: entity,
      },
      {
        text: "Cancelar",
        action: "closeModal",
        classes: "safe",
        entity: entity,
      },
    ],
  };
}
