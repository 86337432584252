import React, { useEffect } from "react";
//Redux
import { useSelector } from "react-redux";
//Components
import OrderGarmentRow from "../createOrderInputs/OrderGarmentRow";

const OrderGarmentRows = ({ garments, setGarments, getGarments, exampleOrderRow }) => {
    //Redux Data
    const { formValues } = useSelector((state) => state.dataForm);

      //useEffect
  useEffect(() => {
    if (formValues.garments) {
      setGarments(getGarments());
    }
    // eslint-disable-next-line
  }, [formValues.garments]);
  return (
    <>
      {
        // eslint-disable-next-line
        garments.map((garment, index) => (
          <OrderGarmentRow pos={index} OrderGarmentRow={OrderGarmentRow} garment={garment} exampleOrderRow={exampleOrderRow} key={index}/>
        ))
      }
    </>
  );
};

export default OrderGarmentRows;
