export function clienteCancelarModal(item, entity) {
  return {
    id: 0,
    title:
      "Por favor, confirme que desea cancelar la creación de este Cliente",
    subtitle:
      "¡CUIDADO! Si cancela la creación del cliente se perderán todos los datos que ha introducido para este cliente. Esta acción no se puede deshacer.",
    buttons: [
      {
        text: "Confirmar",
        action: "link",
        link: "/buscar-cliente",
        classes: "unsafe",
        entity: entity,
      },
      {
        text: "Cancelar",
        action: "closeModal",
        classes: "safe",
        entity: entity,
      },
    ],
  };
}
