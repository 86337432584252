import React from "react";

const SectionHeader = ({ title, text, info }) => {
  return (
    <div className="texts">
      <div className="texts-title">{title}</div>
      <div className="texts-help">{text}</div>
      {info && (
        <div className="header-info__container">
          {info.map((item, index) => (
            <div className={"header-info__item col-" + item.size} key={item.label + index}>
              <div className="header-info__label">{item.label}</div>
              <div className="header-info__text">{item.text}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SectionHeader;
