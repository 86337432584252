import React, { useState, useEffect } from "react";
//Redux
import { useSelector } from "react-redux";
//Components
import SectionHeader from "../tableSections/SectionHeader";
import FieldText from "../formSections/FieldText";
import FieldTextArea from "../formSections/FieldTextArea";
import FieldSelect from "../formSections/FieldSelect";
import ButtonMutation from "../tableSections/ButtonMutation";
import Button from "../tableSections/Button";
import FieldUploadFile from "../formSections/FieldUploadFile";
import VariableRow from "../formSections/VariableRow";

const Form = () => {
  //Store data
  const { settings, formValues, variableRowValidations } = useSelector(
    (state) => state.dataForm
  );

  //States
  const [disableButton, setDisableButton] = useState(true);

  //Functions
  const canAddVariableRow = () => {
    let res = true;

    if (variableRowValidations) {
      // eslint-disable-next-line
      variableRowValidations.map((variableRowValidation) => {
        // eslint-disable-next-line
        variableRowValidation.map((variableRowVal) => {
          if (!variableRowVal[1]) {
            res = false;
          }
        });
      });
    } else {
      res = false;
    }

    return res;
  };

  //useEffect
  useEffect(() => {
    //check if new variable row is available (last one validated)
    if (settings && settings.variableRows && !canAddVariableRow()) {
      setDisableButton(true);
    } else if (settings && settings.variableRows && canAddVariableRow()) {
      setDisableButton(false);
    }
    // eslint-disable-next-line
  }, [formValues, variableRowValidations]);

  return (
    <>
      <SectionHeader title={settings.title} text={settings.text} />
      <div className="form">
        {settings.formContent &&
          settings.formContent.length > 0 &&
          settings.formContent.map((content, index) => (
            <div
              className={"form-container col-" + content.size}
              key={"input-" + index}
            >
              {content.type === "fieldText" && <FieldText pos={index} />}
              {content.type === "fieldSelect" && <FieldSelect pos={index} />}
              {content.type === "fieldTextArea" && (
                <FieldTextArea pos={index} />
              )}
              {content.type === "fieldUploadFile" && (
                <FieldUploadFile pos={index} />
              )}
            </div>
          ))}
        {formValues &&
          settings.variableRows &&
          settings.variableRows.entity &&
          formValues[settings.variableRows.entity] &&
          formValues[settings.variableRows.entity].create && (
            <div className="variable-rows-container">
              {formValues[settings.variableRows.entity].create.map(
                (row, index) => (
                  <VariableRow rowNumber={index} key={index} />
                )
              )}
            </div>
          )}
        {settings.formContent && settings.formContent.length > 0 && (
          <div className={"form-container"}>
            <div className="form-buttons">
              {settings.formContent[
                settings.formContent.length - 1
              ].buttons.map((button) => (
                <div key={button.text}>
                  {!button.actionType ? (
                    <Button
                      text={button.text}
                      action={button.action}
                      link={button.link}
                      classes={
                        button.classes +
                        " " +
                        (button.action === "addVariableRow" && disableButton
                          ? "disabled"
                          : "")
                      }
                      id={settings.id ? settings.id : "0"}
                      entity={button.entity}
                    />
                  ) : (
                    <ButtonMutation
                      entity={settings.entity}
                      text={button.text}
                      action={button.action}
                      link={button.link}
                      classes={button.classes}
                      id={settings.id}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Form;
