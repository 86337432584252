import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { loadFormValue } from "../../../../actions/formDataActions";

const InputEmail = ({ defaultValue }) => {
  //Store data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [displayError, setDisplayError] = useState(false);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //Functions
  const loadInputValue = (e) => {
    storeLoadFormValue(["email", e.target.value]);
  };

  //useEffect
  useEffect(() => {
    //Check field validation
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      formValues &&
      formValues.email &&
      formValues.email !== "" &&
      re.test(String(formValues["email"]).toLowerCase())
    ) {
      setDisplayError(false);
    } else {
      setDisplayError(true);
    }
    // eslint-disable-next-line
  }, [formValues.email]);

  useEffect(() => {
    //In case of update, load current value into form values
    if (defaultValue) {
      storeLoadFormValue(["email", defaultValue]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="input-container col-4">
      <div className="input-label">Email</div>
      <input
        type="text"
        name="email"
        placeholder="Escriba el teléfono *"
        className={displayError === true ? " errorInput " : ""}
        onChange={loadInputValue}
        defaultValue={defaultValue ? defaultValue : ""}
      />
    </div>
  );
};

export default InputEmail;
