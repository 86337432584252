import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/buscar-prenda.css";
//Components
import Table from "../../tableSections/Table";
import Loading from "../../tableSections/Loading";
import ModalGetClientes from "../../modal/ModalGetClientes";
//Cookie token
import cookie from "react-cookies";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadOriginalResults,
  loadWorkResults,
  loadSettings,
  initFilters,
  loadPaginatorItems,
  reloadResults,
} from "../../../actions/tableDataActions";
//External Function
import { getNewResults } from "../../../utilities/clientes";
//Utilities
import { paginator, getPaginatorNavItems } from "../../../utilities/paginator";

const Clientes = () => {
  const token = cookie.load("usertk"); // Used to redirect not logged in access attempts.
  //Store data
  const { reload, search, filters, originalData, settings, orderBy } =
    useSelector((state) => state.dataTable);

  //Section Settings
  const defaultSettings = {
    entity: "cliente",
    queryName: "getClientes",
    title: "Listado de clientes",
    text: "Puede utilizar el buscador para encontrar un Cliente por su nombre comercial, nombre fiscal, código de cliente, población, provincia o email.",
    createButtonText: "Crear Nuevo Cliente",
    createButtonLink: "/crear-cliente",
    tableHeaders: [
      { text: "Cod. Cliente", size: 15, orderBy: false, field: "reference" },
      { text: "Nombre", size: 30, orderBy: true, field: "name" },
      { text: "Población", size: 30, orderBy: true, field: "location" },
      {
        text: "Opciones disponibles",
        size: 25,
        orderBy: false,
        field: "options",
      },
    ],
    searchLabel: "Buscar Cliente",
    filterSettings: [
      {
        name: "location",
        placeholder: "Población",
        queryname: "getLocations",
      },
      {
        name: "business_group",
        placeholder: "Grupo de empresas",
        queryname: "getBusinessGroups",
      },
    ],
    paginationSettings: { page: 1, perPage: 6 },
    rowContent: [
      { size: 15, content: "client_code", align: "left" },
      { size: 30, content: "name", align: "left" },
      { size: 30, content: "location", align: "left" },
      {
        size: 25,
        buttons: [
          {
            text: "Ver",
            action: "link",
            link: "/ver-cliente",
            classes: "safe",
          },
          {
            text: "Editar",
            action: "link",
            link: "/editar-cliente",
            classes: "neutral",
          },
          { text: "Eliminar", action: "modal", classes: "unsafe" },
        ],
      },
    ],
  };
  const initalFilters = [
    {
      name: "location",
      value: "",
      index: 0,
    },
    {
      name: "business_groups",
      value: "",
      index: 1,
    },
  ];

  //States
  const [displayTable, setDisplayTable] = useState(false);

  //API
  const GET_CLIENTES = gql`
    query getClientes {
      getClientes {
        id
        client_code
        name
        business_name
        address
        email
        phone
        business_group {
          name
        }
        location {
          name
        }
        province {
          name
        }
        country {
          name
        }
      }
    }
  `;

  const [getClientes, { error, data }] = useLazyQuery(GET_CLIENTES);

  //Functions
  const goHome = () => {
    //Redirects if not logged in
    window.location.href = "/";
  };

  //Redux actions
  const dispatch = useDispatch();
  const storeOriginalLoad = (processedData) =>
    dispatch(loadOriginalResults(processedData));
  const storeWorkLoad = (paginatedResults) =>
    dispatch(loadWorkResults(paginatedResults));
  const storeSettings = (defaultSettings) =>
    dispatch(loadSettings(defaultSettings));
  const storeInitialFilters = (initalFilters) =>
    dispatch(initFilters(initalFilters));
  const loadNewPaginatorItems = (paginatorItems) =>
    dispatch(loadPaginatorItems(paginatorItems));
  const storeReloadResults = (config) => dispatch(reloadResults(config));
  const loadNewResults = (newResults) => dispatch(loadWorkResults(newResults));

  //useEffect
  useEffect(() => {
    // Results without error
    if (data && !error) {
      //Gets filter options
      let processedResults = { getClientes: [] };
      // eslint-disable-next-line
      data.getClientes.map((cliente) => {
        let processedCliente = {
          id: cliente.id,
          client_code: cliente.client_code,
          name: cliente.name,
          business_name: cliente.business_name,
          address: cliente.address,
          email: cliente.email,
          phone: cliente.phone,
          business_group: cliente.business_group.name,
          country: cliente.country.name,
          province: cliente.province.name,
          location: cliente.location.name,
        };

        processedResults.getClientes.push(processedCliente);
      });

      let getLocations = getFilterOptions(processedResults.getClientes, [
        "location",
      ]);

      let getBusinessGroups = getFilterOptions(processedResults.getClientes, [
        "business_group",
      ]);

      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        processedResults.getClientes,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      //Load work copy paginated
      let paginatedResults = {
        getLocations: getLocations,
        getBusinessGroups: getBusinessGroups,
        getClientes: paginator(
          processedResults.getClientes,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };

      storeWorkLoad(paginatedResults); // Loads work copy in store
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);

      // Loads original copy in store adding json data
      let processedData = {
        getClientes: processedResults.getClientes,
        getBusinessGroups: getBusinessGroups,
        getLocations: getLocations,
      };
      storeOriginalLoad(processedData);
      // Displays table
      setDisplayTable(true);
    } else if (data && data.getClientes.length === 0) {
      // No results message display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          No existe ningún resultado.
          <br />
          <br />
          Por favor, modifique la búsqueda o los filtros aplicados.
        </div>
      );
      toast.error(<Msg />);
    } else if (error) {
      // Technical error display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          Se ha producido un error técnico
          <br />
          <br />
          Por favor, contacte con nosotros en el email que encontrará al pie de
          esta página.
        </div>
      );
      console.log(error);
      toast.error(<Msg />);
    }
    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    //Generates new content and pagination
    if (reload) {
      //reset load flag to false to avoid loop
      storeReloadResults(false);

      // Gets new results
      const newResults = getNewResults(originalData, search, filters, orderBy);

      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        newResults.getClientes,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      // Gets paginated results
      let paginatedResults = {
        getLocations: newResults.getLocations,
        getBusinessGroups: newResults.getBusinessGroups,
        getClientes: paginator(
          newResults.getClientes,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };
      //Load paginated results
      loadNewResults(paginatedResults);
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);
    }
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    //Runs query at first load
    getClientes();
    storeSettings(defaultSettings);
    storeInitialFilters(initalFilters);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        goHome()
      ) : (
        <>
          <div className="table-container">
            <Loading />
            {displayTable && <Table />}
          </div>
          <ModalGetClientes />
        </>
      )}
    </>
  );
};

export default Clientes;

function getFilterOptions(results, keys) {
  //location, name
  let res = [];
  // eslint-disable-next-line
  results.map((result) => {
    if (res.length === 0 || !res.some((e) => e.name === result[keys[0]])) {
      res.push({ name: result[keys[0]] });
    }
  });
  return res;
}
