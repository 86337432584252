import React, { useState } from "react";
//Components
import Button from "./Button";
import { ReactComponent as IconOk } from "../../assets/images/iconOk.svg";
import { ReactComponent as IconKO } from "../../assets/images/iconKO.svg";
import { ReactComponent as IconExpandRow } from "../../assets/images/iconExpandRow.svg";
//Redux
import { useSelector } from "react-redux";

const TableRow = (item, index) => {
  //Store data
  const { settings } = useSelector((state) => state.dataTable);

  //States
  const [isExpanded, setIsExpanded] = useState(false);

  //Function
  const getCellCalc = (item, calc) => {
    let res = 0;
    // eslint-disable-next-line
    item.item[calc[0]].map((itemToCount) => {
      res += itemToCount[calc[1]];
    });
    return res;
  };

  const expandRow = () => {
    if (isExpanded) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  };

  return (
    <>
      <div
        className={"table-row " + (settings.expandibleRows ? "expandible" : "")}
        key={"item" + index}
      >
        <div className="table-row-normal">
          {settings.expandibleRows && (
            <IconExpandRow
              onClick={expandRow}
              className={isExpanded ? "opened" : ""}
            />
          )}
          {settings.rowContent.map((cell, i) => (
            <div
              className={
                "table-cell col-" +
                cell.size +
                " text-" +
                cell.align +
                (settings.expandibleRows && i === 0 ? " expandible" : "")
              }
              key={Math.random()}
            >
              {cell.subcontent &&
                cell.content &&
                item.item[cell.content][cell.subcontent]}
              {!cell.subcontent && cell.content && item.item[cell.content]}
              {cell.calc && getCellCalc(item, cell.calc)}
              {cell.buttons &&
                cell.buttons.map((button) => (
                  <Button
                    entity={settings.entity}
                    text={button.text}
                    action={button.action}
                    classes={button.classes}
                    id={item.item.id}
                    key={button.text + index}
                    link={button.link}
                  />
                ))}
              {cell.icon && item.item[cell.type] && <IconOk />}
              {cell.icon && !item.item[cell.type] && <IconKO />}
            </div>
          ))}
        </div>
        {settings.expandibleRows && (
          <div
            className={
              "table-row-expanded-info " + (isExpanded ? "opened" : "")
            }
          >
            {item.item[settings.extendedRowContent[0].keyName].map(
              (expandedItem, ind) => (
                <div className="table-row-expanded" key={ind}>
                  {settings.extendedRowContent.map((expandedCell, inde) => (
                    <div
                      className={
                        "table-cell col-" +
                        expandedCell.size +
                        " text-" +
                        expandedCell.align +
                        (settings.expandibleRows && inde === 0
                          ? " expandible"
                          : "")
                      }
                      key={Math.random()}
                    >
                      {expandedItem[expandedCell.content] &&
                      expandedItem[expandedCell.content].name
                        ? expandedItem[expandedCell.content].name
                        : expandedItem[expandedCell.content]}
                    </div>
                  ))}
                </div>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TableRow;
