import React, { useState } from "react";
//Router redirect
import { useHistory } from "react-router-dom";
//Redux
import { useSelector } from "react-redux";
//GraphQL
import { useMutation, gql } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";

const CreateOrderButtonCreate = ({ setDisplayThirdRow }) => {
  //Redux Data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [disableButton, setDisableButton] = useState(false);

  //Redirection
  let history = useHistory();

  //Functions
  const capitalize = (key, orderRow) => {
    const lowcaseName = orderRow[formValues[key]].toLowerCase();
    const splitCompoundName = lowcaseName.split(" ");

    for (var i = 0; i < splitCompoundName.length; i++) {
      splitCompoundName[i] =
        splitCompoundName[i].charAt(0).toUpperCase() +
        splitCompoundName[i].substring(1);
    }
    return splitCompoundName.join(" ");
  };

  //API
  const getMutation = () => {
    let mutationString = ``;
    mutationString += `mutation {
      createPedido(
        input: {
          cliente_id: ${formValues.client}
          order_date: "${formValues.order_date}"
          order_number: "${formValues.order_number}"
          isDeliveryNote: false
          isCount: false
          isLabel: false
          isReclamation: false
          rows: {
            create: [`;

    const orderData = formValues.order;

    // eslint-disable-next-line
    orderData.map((orderRow) => {
      //Avoid Alcampo non order lines in excel
      if (orderRow["Nombre"] !== "") {
        // Normalize data
        const name = capitalize("name", orderRow);
        const surname1 = orderRow[formValues.surname1]
          ? capitalize("surname1", orderRow)
          : "";
        const surname2 = orderRow[formValues.surname2]
          ? capitalize("surname2", orderRow)
          : "";
        const worker_name = `${name} ${surname1} ${surname2}`;
        const worker_code = `${
          formValues.worker_code ? orderRow[formValues.worker_code] : "- -"
        }`;
        const dni = `${formValues.dni ? orderRow[formValues.dni] : "- -"}`;
        const workplace = orderRow[formValues.workplace];
        const job = orderRow[formValues.job];
        const sex = `${formValues.sex ? orderRow[formValues.sex] : "- -"}`;

        //START - Worker data
        mutationString += `
            {
              worker_name: "${worker_name}"
              worker_code: "${worker_code}"
              dni: "${dni}"
              workplace: "${workplace}"
              job: "${job}"
              sex: "${sex}"
              garments: {
                create: [`;
        //START - Garments Data
        // eslint-disable-next-line
        formValues.garments.map((garment, index) => {
          let flag = false;
          let prenda_id;
          let size;
          let quantity;

          if (!orderRow[garment.quantity] || orderRow[garment.quantity] === 0) {
            flag = true;
          } else {
            prenda_id = formValues.garments[index].prenda_id;
            size = orderRow[garment.sizeValue];
            quantity = orderRow[garment.quantity];
          }

          if (!flag) {
            if (quantity !== "" && quantity !== ".") {
              mutationString += `{ 
            prenda_id: ${prenda_id},
            size: "${size}",
            quantity: ${quantity} 
          }`;
            }
          }
        });
        //END - ROW data
        mutationString += `]
              }
            }
         `;
      }
    });
    //END - MUTATION PARAM data
    mutationString += `
            ]
          }
        }
      ) {
        id
        order_date
        client {
          name
        }
      }
    }
  `;

    return gql`
      ${mutationString}
    `;
  };
  // eslint-disable-next-line
  const [executeMutation, { data, error }] = useMutation(getMutation());

  //Functions
  const retriveAction = () => {
    if (
      formValues.name &&
      formValues.name !== "" &&
      formValues.workplace &&
      formValues.workplace !== "" &&
      formValues.job &&
      formValues.job !== ""
    ) {
      let flag = false;
      // eslint-disable-next-line
      formValues.garments.map((garment) => {
        if (
          garment.prenda_id === 0 ||
          garment.match === "" ||
          garment.sizeValue === "" ||
          garment.quantity === 0
        ) {
          flag = true;
        }
      });
      if (flag) {
        toast.error(
          "Por favor, asigne los campos que le faltan en las prendas del pedido (Están marcados en rojo)."
        );
      } else {
        setDisableButton(true);
        //Execute mutation
        executeMutation()
          .then(({ data }) => {
            //Notify OK
            toast.success("El pedido se ha creado correctamente.");

            //Redirect
            history.push("/ver-pedido/" + data.createPedido.id);
          })
          .catch((e) => {
            //Notify KO
            toast.error(
              "Lo sentimos, no ha sido posible realizar la operación."
            );
          });
      }
    } else {
      setDisplayThirdRow(false);
      const messageKO = `
            Por favor, asigne los campos obligatorios que le faltan: 
            ${!formValues.name ? "Nombre" : ""}
            ${
              !formValues.name && !formValues.workplace
                ? ", Centro de trabajo"
                : ""
            }
            ${
              formValues.name && !formValues.workplace
                ? "Centro de trabajo"
                : ""
            }
            ${
              (!formValues.name || !formValues.workplace) && !formValues.job
                ? ", Puesto"
                : ""
            }
            ${
              formValues.name && formValues.workplace && !formValues.job
                ? "Puesto"
                : ""
            }
            `;
      toast.error(messageKO + ".");
    }
  };

  return (
    <button
      className={"button safe mt-24 " + (disableButton ? "disabled" : "")}
      onClick={retriveAction}
      disabled={disableButton}
    >
      Crear pedido
    </button>
  );
};

export default CreateOrderButtonCreate;
