import React, { useEffect } from "react";
//GraphQL
import { useMutation, gql } from "@apollo/client";
//Router
import { useParams } from "react-router-dom";

const Labels11Ready = ({ setFrameTarjet }) => {
  setFrameTarjet("labels-to-print-1-1");

  const { id } = useParams();

  const getMutation = () => {

    return gql`
    mutation updateOrderLabel{
      updateOrderLabel(id: ${parseInt(id)}, isLabel: true) {
          id
        }
      }
  `;
  };
  // eslint-disable-next-line
  const [executeMutation, { data, error }] = useMutation(getMutation());

  useEffect(() => {
    executeMutation();
    // eslint-disable-next-line
  }, []);
  return <></>;
};

export default Labels11Ready;
