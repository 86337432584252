export function countryEliminarModal(item, entity) {
  return {
    id: item.id,
    title: "Por favor, confirme que desea eliminar este país",
    subtitle:
      "¡CUIDADO! Eliminar este país ELIMINARÁ también TODAS sus PROVINCIAS, POBLACIONES y CENTROS DE TRABAJO situados en ellas. Esta acción no se puede deshacer.",
    texts: [
      {
        text: "Nombre del país",
        value: item.name,
      },
    ],
    buttons: [
      {
        text: "Confirmar",
        action: "delete",
        classes: "unsafe",
        entity: entity,
        actionType: "mutation",
      },
      {
        text: "Cancelar",
        action: "closeModal",
        classes: "safe",
        entity: entity,
      },
    ],
  };
}
