import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/form.css";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormSettings,
  loadSelectOptions,
} from "../../../actions/formDataActions";
//Components
import SectionHeader from "../../tableSections/SectionHeader";
import PrendasCreateName from "../prendas/prendasCreate/PrendasCreateName";
import PrendasCreateReference from "../prendas/prendasCreate/PrendasCreateReference";
import PrendasCreateType from "../prendas/prendasCreate/PrendasCreateType";
import PrendasCreateDescription from "../prendas/prendasCreate/PrendasCreateDescription";
import PrendasCreateImage from "../prendas/prendasCreate/PrendasCreateImage";
import PrendasCreateButtonCancel from "../prendas/prendasCreate/buttons/PrendasCreateButtonCancel";
import PrendasCreateButtonCreate from "../prendas/prendasCreate/buttons/PrendasCreateButtonCreate";

const PrendasCreate = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");

  //Store data
  const { settings } = useSelector((state) => state.dataForm);

  //States
  const [displayForm, setDisplayForm] = useState(false);

  //API
  const GET_PRENDAS_TYPE = gql`
    query getPrendaTypes {
      getPrendaTypes {
        id
        name
      }
    }
  `;

  const [getPrendaTypes, { error, data }] = useLazyQuery(GET_PRENDAS_TYPE);

  //Section Settings
  const defaultSettings = {
    entity: "prenda",
    queryName: "createPrenda",
    title: "Crear Prenda",
    text: "Por favor, rellene todos los campos para añadir una nueva prenda y después pulse en aceptar para que los cambios se guarden.",
  };

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadSettings = () => dispatch(loadFormSettings(defaultSettings));
  const storeLoadSelectOptions = (optionObject) =>
    dispatch(loadSelectOptions(optionObject));

  //useEffect
  useEffect(() => {
    if (settings && settings !== {}) {
      setDisplayForm(true);
    } else {
      setDisplayForm(false);
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    if (data && !error) {
      storeLoadSelectOptions({
        position: 0,
        options: {
          queryName: Object.keys(data)[0],
          originalOptions: data.getPrendaTypes,
          workOptions: data.getPrendaTypes,
        },
      });
    }

    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    storeLoadSettings();
    getPrendaTypes();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">
            {displayForm && (
              <>
                <SectionHeader title={settings.title} text={settings.text} />
                <div className="form form__first-row">
                  <PrendasCreateName />
                  <PrendasCreateReference />
                  <PrendasCreateType />
                </div>
                <div className="form ">
                  <PrendasCreateDescription />
                  <PrendasCreateImage />
                </div>
                <div className="form ">
                  <PrendasCreateButtonCreate />
                  <PrendasCreateButtonCancel />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PrendasCreate;
