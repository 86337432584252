import React from "react";
//Router redirect
import { useHistory } from "react-router-dom";
//GraphQL
import { useMutation, gql } from "@apollo/client";
//Redux
import { useSelector } from "react-redux";
//Notifications
import { toast } from "react-toastify";

const ButtonCreateCliente = () => {
  //Store Data
  const { formValues } = useSelector((state) => state.dataForm);

  //Redirection
  let history = useHistory();

  //API
  const CREATE_CLIENTE = gql`
  mutation {
    createCliente(
        name: "${formValues.name ? formValues.name : ""}",
        client_code: "${formValues.client_code ? formValues.client_code : 0}",
        business_group_id: ${
          formValues.business_group ? formValues.business_group : 0
        },
        phone: "${formValues.phone ? formValues.phone : ""}",
        email: "${formValues.email ? formValues.email : ""}",
        business_name: "${
          formValues.business_name ? formValues.business_name : ""
        }",
        address: "${formValues.address ? formValues.address : ""}",
        postal_code: "${
          formValues.postal_code ? formValues.postal_code : ""
        }"
        location_id: ${formValues.location_id ? formValues.location_id : 0}
        province_id: ${formValues.province_id ? formValues.province_id : 0}
        country_id: ${formValues.country_id ? formValues.country_id : 0}
        discharge_date: "${new Date().toISOString().substring(0, 10)}"
    ) {
        id
    }
}
  `;

  // eslint-disable-next-line
  const [executeMutation, { data, error }] = useMutation(CREATE_CLIENTE);

  //Functions
  const retriveAction = () => {
    const messageOK = "El cliente se ha creado correctamente.";
    const messageKO = "Por favor, revise los campos marcados en rojo.";
    const redirection = `/buscar-cliente`;
    let validation = validateCliente(formValues);

    if (validation) {
      //Validation ok
    
      //Execute mutation
      executeMutation()
        .then(({ data }) => {
          //Notify OK
          toast.success(messageOK);

          //Redirect
          history.push(redirection);
        })
        .catch((e) => {
          //Notify KO
          toast.error("Lo sentimos, no ha sido posible realizar la operación.");
        });
    } else {
      //Validation KO

      //Notify KO
      toast.error(messageKO);
    }
  };

  return (
    <button className="button safe" onClick={retriveAction}>
      Crear Cliente
    </button>
  );
};

export default ButtonCreateCliente;

function validateCliente(formValues) {
  let res = false;
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (
    formValues &&
    formValues.country_id &&
    formValues.province_id &&
    formValues.location_id &&
    formValues.client_code &&
    formValues.client_code !== "" &&
    formValues.business_name &&
    formValues.business_name !== "" &&
    formValues.business_group &&
    formValues.business_group !== "" &&
    formValues.name &&
    formValues.name !== "" &&
    formValues.address &&
    formValues.address !== "" &&
    formValues.postal_code &&
    formValues.postal_code !== "" &&
    formValues.phone &&
    formValues.phone !== "" &&
    formValues.email &&
    formValues.email !== "" &&
    re.test(String(formValues["email"]).toLowerCase())
  ) {
    res = true;
  }

  return res;
}
