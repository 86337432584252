import React, { useEffect } from "react";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  makeRoomVariableRow,
  loadDefaultVRValidation,
} from "../../actions/formDataActions";
//Components
import VariableRowFieldText from "./VariableRowFieldText";
import VariableRowFieldSelect from "./VariableRowFieldSelect";
import Button from "../tableSections/Button";

const VariableRow = ({ rowNumber }) => {
  //Store data
  const { settings, formValues, variableRowValidations } = useSelector(
    (state) => state.dataForm
  );

  //Redux actions
  const dispatch = useDispatch();
  const storeMakeRoomVariableRow = () => dispatch(makeRoomVariableRow());
  const storeLoadDefaultVRValidation = (res) =>
    dispatch(loadDefaultVRValidation(res));

  //useEffect
  useEffect(() => {
    //add default values to store on row creation or load existing ones
    //check if room is made
    if (!formValues[settings.variableRows.entity].create[rowNumber]) {
      storeMakeRoomVariableRow();
    }
    if (
      !variableRowValidations ||
      (variableRowValidations && !variableRowValidations[rowNumber])
    ) {
      let res = [];
      // eslint-disable-next-line
      settings.variableRows.fieldGroups.map((fieldGroup) => {
        if (fieldGroup.type !== "buttons" && fieldGroup.type !== "spacer") {
          res.push([fieldGroup.name, false]);
        }
      });

      storeLoadDefaultVRValidation(res);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="variable-row">
      {settings.variableRows.fieldGroups.map((content, index) => (
        <div
          className={"form-container col-" + content.size}
          key={"input-" + index}
        >
          {content.type === "fieldText" && (
            <VariableRowFieldText pos={index} rowNumber={rowNumber} />
          )}
          {content.type === "fieldSelect" && (
            <VariableRowFieldSelect pos={index} rowNumber={rowNumber} />
          )}
          {content.type === "buttons" && (
            <div className="form-buttons">
              {content.buttons.map((button) => (
                <div key={button.text}>
                  <Button
                    text={button.text}
                    action={button.action}
                    link={button.link}
                    classes={button.classes}
                    id={settings.id ? settings.id : "0"}
                    entity={button.entity}
                    pos={rowNumber}
                    disable={
                      formValues[settings.variableRows.entity].create.length ===
                      1
                        ? true
                        : false
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default VariableRow;
