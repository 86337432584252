import React from "react";

const Label116Template = ({ result }) => {
  return (
    <>
      <div>
        {/* Label Headers */}
        <div
          id="label__heading-container"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "2.5% 2.5%",
            borderBottom: "1px solid black",
            fontSize: "11px",
          }}
        >
          <div
            id="label__heading-row"
            style={{
              display: "flex",
            }}
          >
            <div style={{ width: "60%", display: "flex", gap: "5%" }}>
              <div style={{ width: "5%" }}>Cl:</div>
              <div
                style={{
                  width: "90%",
                  fontFamily: "SF Pro Display",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {result.cliente}
              </div>
            </div>
            <div style={{ width: "40%", display: "flex", gap: "5%" }}>
              <div style={{ width: "10%" }}>CT:</div>
              <div
                style={{
                  width: "85%",
                  fontFamily: "SF Pro Display",
                  fontWeight: 600,
                }}
              >
                {result.workplace}
              </div>
            </div>
          </div>
          <div
            id="label__heading-row"
            style={{
              display: "flex",
            }}
          >
            <div style={{ width: "60%", display: "flex", gap: "5%" }}>
              <div style={{ width: "5%" }}>Em:</div>
              <div
                style={{
                  width: "90%",
                  fontFamily: "SF Pro Display",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {result.worker_code &&
                  result.worker_code !== "- -" &&
                  result.worker_code + " - "}
                {result.dni && result.dni !== "- -" && result.dni + " - "}
                {result.worker_name}
              </div>
            </div>
            <div style={{ width: "40%", display: "flex", gap: "5%" }}>
              <div style={{ width: "10%" }}>Cat:</div>
              <div
                style={{
                  width: "85%",
                  fontFamily: "SF Pro Display",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                {result.job}
              </div>
            </div>
          </div>
        </div>
        {/* Rows Garments */}
        {result.garments.map((garment, index) => (
          <div
            className="label__rows-container"
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: "0% 2.5%",
              margin: "2% 2.5%",
              fontSize: "12px",
            }}
            key={"prenda" + index}
          >
            <div
              style={{
                width: "69%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "1%",
                fontSize: "10px",
                lineHeight: "10px"
                
              }}
            >
              {garment.prenda.name}
            </div>
            <div
              style={{
                width: "20%",
                fontSize: "10px",
                lineHeight: "10px"
                
              }}
            >
              {garment.size}
            </div>
            <div
              style={{
                width: "10%",
                fontSize: "10px",
                lineHeight: "10px"
              }}
            >
              {garment.quantity}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Label116Template;
