import React, { useState, useEffect } from "react";
//Styles
import "../../../../../assets/css/print-order-labels-18.css";
//Redux
import { useSelector } from "react-redux";
//Components
import Label18Alcampo from './Label18Alcampo'
import Labels18Ready from '../Labels18Ready'

const Labels18Alcampo = ({setFrameTarjet}) => {
  //Store data
  const { rowsToPrint } = useSelector((state) => state.dataTable);
  const { pedido } = useSelector((state) => state.dataForm);

  //States
  const [showDocuments, setShowDocuments] = useState(false);
  const [results, setResults] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState([]);

  //Functions
  const getRowsToPrint = () => {
    let res = [];
    if (rowsToPrint.length === 0) {
      res = pedido.rows;
    } else {
      // eslint-disable-next-line
      rowsToPrint.map((rowToPrint) => {
        res.push(pedido.rows[rowToPrint]);
      });
    }
    return res;
  };
  function chunkArray(myArray, chunk_size) {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = myArray.slice(index, index + chunk_size);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  //useEffect
  useEffect(() => {
    if (pedido) {
      setResults(getRowsToPrint());
    }
    // eslint-disable-next-line
  }, [pedido, rowsToPrint]);

  useEffect(() => {
    if (results && results.length > 0) {
      const newPagedResults = [];

      // eslint-disable-next-line
      results.map((result) => {
        //Separate garments due max document capacity
        let separatedDocumentRows = chunkArray(result.garments, 5);

        // eslint-disable-next-line
        separatedDocumentRows.map((separatedDocumentRow) => {
          const pagedResult = {
            cliente: pedido.client.name,
            workplace: result.workplace,
            job: result.job,
            worker_code: result.worker_code,
            worker_dni: result.worker_dni,
            worker_name: result.worker_name,
            worker_sex: result.worker_sex,
            garments: separatedDocumentRow,
          };
          newPagedResults.push(pagedResult);
        });
      });

      //Split in pages of 8 results
      let eightPerPage = chunkArray(newPagedResults, 8);
      if (eightPerPage[eightPerPage.length - 1].length < 8) {
        const currentLength = eightPerPage[eightPerPage.length - 1].length;
        for (let k = currentLength; k < 8; k++) {
          eightPerPage[eightPerPage.length - 1].push("spacer");
        }
      }
      setPaginatedResults(eightPerPage);

      setShowDocuments(true);
    } else {
      setShowDocuments(false);
    }
    // eslint-disable-next-line
  }, [results]);

  return (
    <div id="labels-to-print-1-8">
      {showDocuments && (
        <>
          {
            // eslint-disable-next-line
            paginatedResults.map((result, index) => (
              <div key={Math.floor(Math.random() * 100001)}>
                <div
                  id="labels-container"
                  style={{
                    boxSizing: "border-box",
                    border: "1px solid white",
                    height: `${
                      index !== paginatedResults.length - 1 ? "100%" : "99%"
                    }`,
                    minHeight: "100%",
                    width: "100%",
                    margin: "auto",
                    marginTop: "0px !important",
                    marginBottom: `${
                      index !== paginatedResults.length - 1 ? "3%" : "0"
                    }`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                  }}
                >
                  {
                    // eslint-disable-next-line
                    result.map((label) => (
                      <div
                        id="label"
                        style={{
                          boxSizing: "border-box",
                          border: `1px solid ${
                            label === "spacer" ? "white" : "black"
                          }`,
                          height: "21%",
                          width: "47%",
                          fontFamily: "SF Pro Display Regular",
                          marginBottom: "8%",
                          marginTop: "0 !important",
                        }}
                        key={Math.floor(Math.random() * 100001)}
                      >
                        {label !== "spacer" && (
                          <Label18Alcampo result={label} />
                        )}
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
          <Labels18Ready setFrameTarjet={setFrameTarjet}/>
        </>
      )}
    </div>
  );
};

export default Labels18Alcampo;
