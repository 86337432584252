import React from "react";
//Components
import Button from "../tableSections/Button";
import ButtonMutation from "../tableSections/ButtonMutation";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { loadModalContent } from "../../actions/tableDataActions";

const ModalGetClientes = () => {
  //Store data
  const { modal, settings } = useSelector((state) => state.dataTable);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadModalContent = (modalContent) =>
    dispatch(loadModalContent(modalContent));

  //Functions
  const closeModal = () => {
    storeLoadModalContent(false);
  };

  return (
    <>
      {modal && (
        <div className="modal__background" onClick={closeModal}>
          <div className="modal__body">
            <div className="modal-body__headers">
              {modal.title && (
                <div className="modal-body-headers__title">{modal.title}</div>
              )}
              {modal.subtitle && (
                <div className="modal-body-headers__subtitle">
                  {modal.subtitle}
                </div>
              )}
            </div>
            <div className="modal-body__info">
              {modal.image && (
                <div
                  className={
                    "modal-body-info__image " + (!modal.title ? "col-50" : "")
                  }
                >
                  <img src={modal.image} alt={"Imagen de " + settings.entity} />
                </div>
              )}
              <div
                className={
                  "modal-body-info__texts " + (!modal.title ? "col-50" : "")
                }
              >
                {modal.infoTitle && (
                  <div className="modal-body-info-texts__title">
                    {modal.infoTitle}
                  </div>
                )}
                {modal.texts && (
                  <>
                    {modal.texts.map((text, index) => (
                      <div key={index}>
                        <div className="modal-body-info-texts__label">
                          {text.text}
                        </div>
                        <div className="modal-body-info-texts__text">
                          {text.value}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="modal-body__buttons">
              {modal.buttons && (
                <>
                  {modal.buttons.map((button) => (
                    <div key={button.text}>
                      {!button.actionType ? (
                        <Button
                          text={button.text}
                          action={button.action}
                          link={button.link}
                          classes={button.classes}
                          id={modal.id}
                          entity={button.entity}
                          
                        />
                      ) : (
                        <ButtonMutation
                        text={button.text}
                        action={button.action}
                        link={button.link}
                        classes={button.classes}
                        id={modal.id}
                        entity={button.entity}
                        key={button.text}
                      />
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalGetClientes;
