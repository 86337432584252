import React from "react";
import { useSelector } from "react-redux";
import HeadersExcel from "./HeadersExcel";

const RowsExcel = () => {

    const { originalData } = useSelector((state) => state.dataTable);

    const headers = HeadersExcel()

    const rows = []
    const garments = []

    originalData.getPedido.rows.map( row => {
        let dataWorker = {}
        dataWorker.nombre = row.worker_name
        dataWorker.centro = row.workplace
        dataWorker.puesto = row.job

        if (
            row.worker_code &&
            row.worker_code !== "" &&
            row.worker_code !== "- -"
          ) {
            dataWorker.code_worker = row.worker_code
          }

        if (row.dni && row.dni !== "" && row.dni !== "- -") {
        dataWorker.dni = row.dni
        }

        if (row.sex && row.sex !== "" && row.sex !== "- -") {
        dataWorker.sexo = row.sex
        }

        row.garments.map(garment => {
            let namePrenda = garment.prenda.name
            let nameSin = namePrenda.replace(" ", "_")
            let celdaTalla = nameSin + '_talla'
            let celdaCantidad = nameSin + '_cantidad'
            dataWorker[celdaTalla] = garment.size
            dataWorker[celdaCantidad] = garment.quantity
        })

        rows.push(dataWorker)

    } )

    return rows


}


export default RowsExcel