import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/form.css";
//Cookie token
import cookie from "react-cookies";
//Router
import { useHistory, useParams } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormSettings,
  loadFormValue,
} from "../../../actions/formDataActions";
//Components
import Form from "../../formSections/Form";

const PrendaTypesUpdate = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");
  let { id } = useParams();

  //Store data
  const { settings } = useSelector((state) => state.dataForm);

  //States
  const [displayForm, setDisplayForm] = useState(false);

  //API
  const GET_PRENDASTYPE_2UPDATE = gql`
    query getPrendaType {
        getPrendaType(id: ${id}) {
        id
        name
      }
    }
  `;

  const [getPrendaType, { error, data }] = useLazyQuery(
    GET_PRENDASTYPE_2UPDATE
  );

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadSettings = (defaultSettings) =>
    dispatch(loadFormSettings(defaultSettings));
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //useEffect
  useEffect(() => {
    if (settings && settings !== {}) {
      setDisplayForm(true);
    } else {
      setDisplayForm(false);
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    if (data && !error) {    
      //Section Settings
      const defaultSettings = {
        entity: "prendaType",
        id: 0,
        queryName: "updatePrendaType",
        title: "Editar tipo de prenda",
        text: "Por favor, modifique el nombre de este tipo de prenda y después pulse en aceptar para que los cambios se guarden.",
        formContent: [
          {
            type: "fieldText",
            name: "name",
            size: 75,
            label: "Nombre del tipo de prenda",
            placeholder: "Nombre del tipo de prenda *",
            required: true,
            validated: true,
            validation: "notEmpty",
            defaultValue: data.getPrendaType.name,
          },
          {
            type: "buttons",
            buttons: [
              {
                text: "Actualizar",
                action: "update",
                classes: "safe",
                actionType: "update",
                entity: "getPrendaType",
                id: id,
              },
              {
                text: "Cancelar",
                action: "link",
                link: "/buscar-tipo-de-prenda",
                classes: "unsafe",
                entity: "getPrendaType",
              },
            ],
          },
        ],
      };

      storeLoadSettings(defaultSettings);
    }

    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    //Get options for select
    getPrendaType();
    //Load id value to store
    storeLoadFormValue(["id", id]);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">{displayForm && <Form />}</div>
        </>
      )}
    </>
  );
};

export default PrendaTypesUpdate;
