import React from "react";
//SCG Icons
import { ReactComponent as OrderByArrow } from "../../assets/images/orderBy.svg";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { loadOrderBy, reloadResults } from "../../actions/tableDataActions";

const TableHeaders = ({ headers }) => {
  //Store data
  const { orderBy, settings } = useSelector((state) => state.dataTable);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadOrderBy = (newOrderByData) =>
    dispatch(loadOrderBy(newOrderByData));
  const storeReloadResults = (config) => dispatch(reloadResults(config));

  //Functions
  const setNewOrderBy = (e) => {
    //New orderBy values
    const newOrder = {
      field: e.target.dataset.field,
      criteria: e.target.dataset.criteria,
    };

    //Copy of current orderBy
    let newOrderByData = orderBy;

    //Flag if it´s already present
    let isAlready = false;
    //Check if it´s already in store
    if (orderBy.length > 0) {
      // eslint-disable-next-line
      orderBy.map((item, index) => {
        if (item.field === e.target.dataset.field) {
          //Remove old position and value
          newOrderByData.splice(index, 1);

          //Add in first position
          newOrderByData.unshift(newOrder);

          //Trigger flag isAlready
          isAlready = true;
        }
      });
      if (!isAlready) {
        //if is not present add it at the end
        newOrderByData.push(newOrder);
      }
    } else {
      //if is an empty array
      newOrderByData.push(newOrder);
    }
    //Loads ordeby to store
    storeLoadOrderBy(newOrderByData);

    //Adds Flag to reload content
    storeReloadResults(true);
  };

  return (
    <div className="table-headers">
      {headers.map((header,index) => (
        <div className={"table-header col-" + header.size + (settings.expandibleRows && index=== 0 ? " expandible" : "")} key={header.text}>
          {header.text}
          {header.orderBy && (
            <>
              <div className="orderBy-desc">
                <OrderByArrow />
                <div
                  className="orderBy-hitpoint"
                  data-field={header.field}
                  data-criteria="desc"
                  onClick={setNewOrderBy}
                ></div>
              </div>
              <div className="orderBy-asc">
                <OrderByArrow />
                <div
                  className="orderBy-hitpoint"
                  data-field={header.field}
                  data-criteria="asc"
                  onClick={setNewOrderBy}
                ></div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default TableHeaders;
