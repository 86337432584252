
import React from "react";
import { useSelector } from "react-redux";

const HeadersExcel = () => {

    
    const { originalData } = useSelector((state) => state.dataTable);

    //Obtener datos de los trabajadores para nombres columnas excel

    const getDataWorkers = () => {

        const row = originalData.getPedido.rows[0]

       

        const headers = [{label: "Nombre", key: "nombre"}, {label: "Centro de Trabajo", key: "centro"}, {label: "Puesto", key: "puesto"}]

        if(row.worker_code &&
            row.worker_code !== "" &&
            row.worker_code !== "- -") {
                headers.push({label: "Código de Empleado", key: "code_worker"})
            }
        if(row.dni && row.dni !== "" && row.dni !== "- -") {
            headers.push({label: "DNI", key: "dni"})
        }
    
        if(row.sex && row.sex !== "" && row.sex !== "- -") {
            headers.push({label: "Sexo", key: "sexo"})
        }

        return headers
    }

    const headersWorkers = getDataWorkers();

    //Obtener datos de las prendas para el pedido

    const getOrdersHeaders = () => {

        const headersOrders = headersWorkers

        const garments = []

        originalData.getPedido.rows.map( row => {
            row.garments.map( garment => {
                let nombrePrenda = garment.prenda.name
                let nombreSin = nombrePrenda.replace(" ", '_')
                let codeTalla = nombreSin + '_talla'
                let codeCantidad = nombreSin + '_cantidad'
                if(!garments.includes(garment.prenda.name)) {
                    garments.push(garment.prenda.name)
                    headersOrders.push({label: `${garment.prenda.name} - Talla `, key: codeTalla})
                    headersOrders.push({label: `${garment.prenda.name} - Cantidad `, key: codeCantidad })
                }
            })
        })

        return headersOrders

    }

    const headersExcel = getOrdersHeaders()

    return headersExcel
}

export default HeadersExcel