import React, { useEffect } from "react";
//Styles
import "../../../assets/css/form.css";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Components
import SelectCountry from "../clientes/clientesInputs/SelectCountry";
import SelectProvince from "../clientes/clientesInputs/SelectProvince";
import SelectLocation from "../clientes/clientesInputs/SelectLocation";
import InputClientCode from "../clientes/clientesInputs/InputClientCode";
import SelectBusinessGroup from "../clientes/clientesInputs/SelectBusinessGroup";
import InputName from "../clientes/clientesInputs/InputName";
import InputBusinessName from "../clientes/clientesInputs/InputBusinessName";
import InputAddress from "../clientes/clientesInputs/InputAddress";
import InputPostalCode from "../clientes/clientesInputs/InputPostalCode";
import InputPhone from "../clientes/clientesInputs/InputPhone";
import InputEmail from "../clientes/clientesInputs/InputEmail";
import ButtonUpdateCliente from "../clientes/clientesButtons/ButtonUpdateCliente";
import ButtonCancelCliente from "../clientes/clientesButtons/ButtonCancelCliente";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory, useParams } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
//Actions
import { loadSettings } from "../../../actions/tableDataActions";
import { loadFormValue } from "../../../actions/formDataActions";

//Components
import SectionHeader from "../../tableSections/SectionHeader";

const ClientesUpdate = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");
  let { id } = useParams();
  //Store data
  const { settings, formValues } = useSelector((state) => state.dataForm);

  //Redux actions
  const dispatch = useDispatch();
  const storeSettings = (defaultSettings) =>
    dispatch(loadSettings(defaultSettings));
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //API
  const GET_CLIENTE = gql`
    query getCliente {
      getCliente(id: ${id}) {
        id
        client_code
        name
        business_name
        address
        email
        phone
        postal_code
        discharge_date
        business_group {
          name
          id
        }
        location {
          name
          id
        }
        province {
          name
          id
        }
        country {
          name
          id
        }
      }
    }
`;

  const [getCliente, { error, data }] = useLazyQuery(GET_CLIENTE);

  //useEffect
  useEffect(() => {
    getCliente();
    const defaultSettings = {
      entity: "cliente",
      queryName: "getCliente",
      title: "Editar cliente",
      text: "Modifique correctamente los campos obligatiorios (marcados con un asterisco) y pulse en el botón Crear para guardar los cambios en el cliente.",
    };
    storeSettings(defaultSettings);
    storeLoadFormValue(["client_id", parseInt(id)]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && !error && data.getCliente) {
      storeLoadFormValue(["client_code", data.getCliente.client_code]);
      storeLoadFormValue(["name", data.getCliente.name]);
      storeLoadFormValue(["business_name", data.getCliente.business_name]);
      storeLoadFormValue([
        "business_group_id",
        data.getCliente.business_group.id,
      ]);
      storeLoadFormValue(["discharge_date", data.getCliente.discharge_date]);
      storeLoadFormValue(["address", data.getCliente.address]);
      storeLoadFormValue(["postal_code", data.getCliente.postal_code]);
      storeLoadFormValue(["phone", data.getCliente.phone]);
      storeLoadFormValue(["email", data.getCliente.email]);
      storeLoadFormValue(["country_id", data.getCliente.country.id]);
      storeLoadFormValue(["province_id", data.getCliente.province.id]);
      storeLoadFormValue(["location_id", data.getCliente.location.id]);
    }
    // eslint-disable-next-line
  }, [data, error]);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          {data && !error && data.getCliente && (
            <div className="table-container">
              <SectionHeader title={settings.title} text={settings.text} />
              <div className="form form__first-row">
                <InputClientCode defaultValue={formValues.client_code} />
                <SelectBusinessGroup
                  defaultValue={formValues.business_group}
                />
                <InputName defaultValue={formValues.name} />
                <InputBusinessName defaultValue={formValues.business_name} />
              </div>
              <div className="form form__second-row">
                <SelectCountry defaultValue={formValues.country_id} />
                {formValues && formValues.country_id && (
                  <SelectProvince defaultValue={formValues.province_id} />
                )}
                {formValues && formValues.province_id && (
                  <SelectLocation defaultValue={formValues.location_id} />
                )}
              </div>
              <div className="form form__first-row">
                <InputAddress defaultValue={formValues.address} />
                <InputPostalCode defaultValue={formValues.postal_code} />
                <InputPhone defaultValue={formValues.phone} />
                <InputEmail defaultValue={formValues.email} />
              </div>
              <div className="form form__button-row">
                <ButtonUpdateCliente />
                <ButtonCancelCliente />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ClientesUpdate;
