export function workplaceEliminarModal(item,entity){
    return {
      id: item.id,
      title:
        "Por favor, confirme que desea eliminar este centro de trabajo",
      subtitle: "¡CUIDADO! Esta acción no se puede deshacer.",
      texts: [
        {
          text: "Nombre del Centro de trabajo",
          value: item.name,
        },
        {
          text: "Dirección",
          value: item.address,
        },
        {
          text: "Código Postal",
          value: item.postal_code,
        },
        {
          text: "Población a la que pertenece",
          value: item.location.name,
        },
        {
          text: "Provincia a la que pertenece",
          value: item.location.province.name,
        },
        {
          text: "País al que pertenece",
          value: item.location.province.country.name,
        },
      ],
      buttons: [
        {
          text: "Confirmar",
          action: "delete",
          classes: "unsafe",
          entity: entity,
          actionType: "mutation",
        },
        {
          text: "Cancelar",
          action: "closeModal",
          classes: "safe",
          entity: entity,
        },
      ],
    };
}