import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
/* import ReactExport from "react-data-export"; */
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useSelector } from "react-redux";

/* const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet; */

const ExportBusinessGroupCountFileGenerator = ({ setGenerateExport }) => {
  let { id } = useParams();

  //Store data
  const { formValues, settings } = useSelector((state) => state.dataForm);

  //States
  const [multiDataSet, setMultiDataSet] = useState([]);
  const [triggerDownload, setTriggerDownload] = useState(false);

  //API
  const GET_ORDERS_2EXPORT = gql`
    {
      getPedidosPorFecha(order_date: { from: "${formValues.dateFrom}", to: "${formValues.dateTo}" }) {
        client {
          id
          business_group {
            name
          }
        }
        order_date
        rows {
          garments {
            prenda {
              name
            }
            size
            quantity
          }
        }
      }
      getClientesBusinessGroup(business_group_id: ${id}){
        id
      }
    }
  `;

  const [getOrders2Export, { error, data }] = useLazyQuery(GET_ORDERS_2EXPORT);

  //useEffect

  useEffect(() => {
    getOrders2Export();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      data &&
      data.getPedidosPorFecha &&
      data.getClientesBusinessGroup &&
      !error
    ) {
      //Filter proper orders
      let validatedOrders = [];
      // eslint-disable-next-line
      data.getPedidosPorFecha.map((pedido) => {
        // eslint-disable-next-line
        data.getClientesBusinessGroup.map((client) => {
          if (pedido.client.id === client.id) {
            validatedOrders.push(pedido);
          }
        });
      });

      //Get all garments
      let allGarments = [];
      // eslint-disable-next-line
      validatedOrders.map((validatedOrder) => {
        // eslint-disable-next-line
        validatedOrder.rows.map((row) => {
          // eslint-disable-next-line
          row.garments.map((garment) => {
            allGarments.push({
              name: garment.prenda.name,
              size: garment.size,
              quantity: garment.quantity,
            });
          });
        });
      });

      const sortedAllGarments = allGarments.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      //Group garments
      let arrangedGarments = [];

      // eslint-disable-next-line
      sortedAllGarments.map((garment) => {
        //First garment
        if (arrangedGarments.length === 0) {
          arrangedGarments.push(garment);
        } else {
          //From garment 2 to the last one
          let isPresent = false;
          // eslint-disable-next-line
          arrangedGarments.map((arrangedGarment) => {
            if (!isPresent) {
              if (
                arrangedGarment.name === garment.name &&
                (arrangedGarment.size === garment.size || garment.size === "UNICA" || garment.size === "")
              ) {
                arrangedGarment.quantity =
                  arrangedGarment.quantity + garment.quantity;
                isPresent = true;
              }
            }
          });
          if (!isPresent) {
            arrangedGarments.push(garment);
            isPresent = false;
          }
        }
      });

      //Prepare file
      //Column Headers
      const columnHeaders = [
        {
          title: "Prenda",
          style: {
            alignment: {
              wrapText: true,
              horizontal: "center",
              vertical: "center",
            },
            font: {
              bold: true,
              sz: "10",
            },
            fill: {
              patternType: "solid",
              fgColor: { rgb: "FFFFFF99" },
            },
            border: {
              bottom: { style: "thin", color: { rgb: "000000" } },
              top: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          },
          width: { wch: 70 },
        },
        {
          title: "Talla",
          style: {
            alignment: {
              wrapText: true,
              horizontal: "center",
              vertical: "center",
            },
            font: {
              bold: true,
              sz: "10",
            },
            fill: {
              patternType: "solid",
              fgColor: { rgb: "FFFFFF99" },
            },
            border: {
              bottom: { style: "thin", color: { rgb: "000000" } },
              top: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          },
          width: { wch: 10 },
        },
        {
          title: "Cantidad",
          style: {
            alignment: {
              wrapText: true,
              horizontal: "center",
              vertical: "center",
            },
            font: {
              bold: true,
              sz: "10",
            },
            fill: {
              patternType: "solid",
              fgColor: { rgb: "FFFFFF99" },
            },
            border: {
              bottom: { style: "thin", color: { rgb: "000000" } },
              top: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          },
          width: { wch: 10 },
        },
      ];

      const getAllRows = () => {
        let res = [];
        // eslint-disable-next-line
        arrangedGarments.map((garment2Export) => {
          let arrayToAdd = [];
          //Set worker data
          arrayToAdd.push({ value: garment2Export.name });
          arrayToAdd.push({ value: garment2Export.size });
          arrayToAdd.push({ value: garment2Export.quantity });

          res.push(arrayToAdd);
        });
        return res;
      };

      const allRows = getAllRows();

      setMultiDataSet([
        {
          columns: columnHeaders,
          data: allRows,
        },
      ]);
    } else {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    if (multiDataSet !== []) {
      setTriggerDownload(true);
    }
  }, [multiDataSet]);

  return (
    <>
      {!triggerDownload && <p>Generando su archivo...</p>}
      {triggerDownload && (
        {/* <div>
          <p style={{marginBottom: "12px", textAlign: "left", fontWeight: "600"}}>¡Su archivo está listo!</p>
          <ExcelFile
            element={
              <div className="print">
                <button>Exportar a Excel</button>
              </div>
            }
            filename={`Recuento_prendas_de_${settings.title.replace(
              "Exportar recuento de prendas de ",
              ""
            )}_del_${formValues.dateFrom}_al_${formValues.dateTo}`}
          >
            <ExcelSheet dataSet={multiDataSet} name="Pedido exportado" />
          </ExcelFile>
        </div> */}
      )}
    </>
  );
};

export default ExportBusinessGroupCountFileGenerator;
