import React, { useState, useEffect } from "react";
import { getNoImageImage } from "../../utilities/form/getNoImageImage";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormValue,
  loadValidationStates,
} from "../../actions/formDataActions";
//Utilities
import { validateForm } from "../../utilities/form/validation";

const FieldUploadFile = ({ pos }) => {
  //States
  const [displayError, setDisplayError] = useState(false);

  //Store data
  const { formValues, settings } = useSelector((state) => state.dataForm);
  const {
    name,
    label,
    placeholder,
    textOne,
    textTwo,
    validated,
    defaultValue,
    noPreview,
  } = useSelector((state) => state.dataForm.settings.formContent[pos]);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));
  const storeLoadValidationStates = (validationResult) =>
    dispatch(loadValidationStates(validationResult));

  //Functions
  const loadInputValue = (e) => {
    const file = e.target.files[0];
    if (file) {
      getBase64(file, storeLoadFormValue);
    }
  };

  //useEffect
  useEffect(() => {
    if (!noPreview) {
      let imageFrame = document.getElementById("preview");
      if (formValues.image) {
        //Load on frame
        imageFrame.src = formValues.image;
        //upload store validation state
        const validation = validateForm(settings.formContent, formValues);
        storeLoadValidationStates(validation.validationResult);
      } else {
        const noImageImage = defaultValue
          ? defaultValue
          : getNoImageImage(settings.entity, settings.queryName);
        imageFrame.src = noImageImage;
      }
    }
    // eslint-disable-next-line
  }, [formValues.image]);

  useEffect(() => {
    if (!validated) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
    }
    // eslint-disable-next-line
  }, [validated]);

  useEffect(() => {
    //In case of update, load current value into form values
    if (defaultValue) {
      storeLoadFormValue(["image", defaultValue]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={"input-container fileUpload " + (!noPreview ? "col-75" : "col-100 mb-48")}>
      <div className={"input-container-left " + (noPreview ? "col-100" : "")}>
        {label && <div className="input-label">{label}</div>}
        {label && <div className="input-text">{textOne}</div>}
        {label && <div className="input-text">{textTwo}</div>}
        <input
          type="file"
          name={name}
          placeholder={placeholder}
          onChange={loadInputValue}
        />
      </div>
      {!noPreview && (
        <div
          className={
            "input-container-right" + (displayError ? " errorInput" : "")
          }
        >
          <img id="preview" src={"#"} alt="Imagen de la prenda" />
        </div>
      )}
    </div>
  );
};

export default FieldUploadFile;

function getBase64(file, storeLoadFormValue) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    storeLoadFormValue(["image", reader.result]);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}
