import React, { useState, useEffect } from "react";
//Styles
import "../../../../assets/css/print-order-count-garments.css";
//Redux
import { useSelector } from "react-redux";
//Components
import CountByGarmentTemplate from "../count/CountByGarmentTemplate";
import CountGarmentsReady from "../count/CountGarmentsReady";

const CountByGarmentsTemplate = ({ setFrameTarjet }) => {
  //Store data
  const { rowsToPrint } = useSelector((state) => state.dataTable);
  const { pedido } = useSelector((state) => state.dataForm);


  //States
  const [showDocuments, setShowDocuments] = useState(false);
  const [results, setResults] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState([]);

  //Functions
  const getRowsToPrint = () => {
    let res = [];
    if (rowsToPrint.length === 0) {
      res = pedido.rows;
    } else {
      // eslint-disable-next-line
      rowsToPrint.map((rowToPrint) => {
        res.push(pedido.rows[rowToPrint]);
      });
    }
    return res;
  };

  function getPaginatedCountByGarment(allResults) {
    // Sets max lines per page & gives amount of lines to headers

    var res = [];
    var page = [];

    const maxLines = 35;
    let currentLines = 0;

    let currentGarment = "";

    // eslint-disable-next-line
    allResults.map((result, index) => {
      // Case 1 - Page is full
      if (
        // IF Max lines reached or...
        currentLines === maxLines ||
        // IF New header does not fit page with almost one size line
        (currentGarment !== result.name && currentLines + 5 >= maxLines)
      ) {
        //Pushes the previous page
        res.push(page);

        //Reset var values
        page = [];
        currentLines = 0;

        //Add current Garment to new page

        //header
        currentGarment = result.name;
        currentLines = currentLines + 4;

        //size
        page.push(result);
        currentLines = currentLines + 1;
      } else if (
        // IF Garment already has header
        currentGarment === result.name &&
        // IF no maxLines reached
        currentLines < maxLines
      ) {
        // Case 2.1 - New size fits page
        page.push(result);
        currentLines = currentLines + 1;
      } else if (
        // IF Garment needs a new header
        currentGarment !== result.name &&
        // IF header and at least one size fits page
        currentLines < maxLines - 5
      ) {
        // Case 2.2 - New header & almost one size fits page

        //Add current Garment to new page
        //header
        currentGarment = result.name;
        currentLines = currentLines + 4;
        //size
        page.push(result);
        currentLines = currentLines + 1;
      }

      // Pushes page if this is the end of results
      if (index === allResults.length - 1) {
        //Pushes the last page
        res.push(page);
      }
    });
console.log("res",res)
    return res;
  }

  const getAllOrderGarments = (results) => {
    let res = [];

    // eslint-disable-next-line
    results.map((result) => {
      //Get all order garments
      // eslint-disable-next-line
      result.garments.map((garment) => {
        res.push(garment);
      });
    });

    return res;
  };

  const getGarmentsCounts = (allOrderGarments) => {
    let res = [];

    // eslint-disable-next-line
    allOrderGarments.map((garment) => {
      if (res.length === 0) {
        //object per garment with array populated with size & quantity objects
        res.push({
          name: garment.prenda.name,
          sizes: [
            {
              size: garment.size,
              quantity: garment.quantity,
            },
          ],
        });
      } else {
        //Check with current saved garments
        let containsGarment = false;
        // eslint-disable-next-line
        res.map((garmentCount) => {
          //If same garment
          if (garmentCount.name === garment.prenda.name) {
            let containsSize = false;
            // eslint-disable-next-line
            garmentCount.sizes.map((size) => {
              //if same size
              if (size.size === garment.size) {
                size.quantity = size.quantity + garment.quantity;
                containsSize = true;
              }
            });
            //if garment size wasn´t created adds it
            if (!containsSize) {
              garmentCount.sizes.push({
                size: garment.size,
                quantity: garment.quantity,
              });
            }
            containsGarment = true;
          }
        });
        //if garment wasn´t created adds it
        if (!containsGarment) {
          res.push({
            name: garment.prenda.name,
            sizes: [
              {
                size: garment.size,
                quantity: garment.quantity,
              },
            ],
          });
        }
      }
    });

    return res;
  };

  const getGarmentsCountsWithTotals = (garmentsCounts) => {
    let res = [];

    // eslint-disable-next-line
    garmentsCounts.map((garmentsCount) => {
      let total = 0;
      // eslint-disable-next-line
      garmentsCount.sizes.map((size) => {
        total = total + size.quantity;
      });
      garmentsCount.total = total;
      res.push(garmentsCount);
    });

    return res;
  };

  //useEffect
  useEffect(() => {
    if (pedido) {
      setResults(getRowsToPrint());
    }
    // eslint-disable-next-line
  }, [pedido, rowsToPrint]);

  useEffect(() => {
    if (results && results.length > 0) {
      let newPagedResults = [];

      //Get all order garments
      let allOrderGarments = getAllOrderGarments(results);

      //object per garment with array populated with size & quantity objects
      let garmentsCounts = getGarmentsCounts(allOrderGarments);
      //Free memory
      allOrderGarments = false;

      //Add totals
      let garmentsCountsWithTotals =
        getGarmentsCountsWithTotals(garmentsCounts);
      //Free memory
      garmentsCounts = false;

      //Special pagination depending on garment sizes per page: MAX 35
      let pages = [];
      let page = [];
      const maxSizesPerPage = 35;

      // Convert array of garments in array of sizes with name and total data in each size.
      let newSizesArray = [];

      // eslint-disable-next-line
      garmentsCountsWithTotals.map((garmentCount) => {
        const name = garmentCount.name;
        const total = garmentCount.total;

        // eslint-disable-next-line
        garmentCount.sizes.map((size) => {
          size.name = name;
          size.total = total;
          newSizesArray.push(size);
        });
      });

      //Divide sizes due to max line capacity of page
      let sizeAmountPerPage = getPaginatedCountByGarment(newSizesArray);

      //Make room for each page
      // eslint-disable-next-line
      sizeAmountPerPage.map((page) => {
        pages.push([]);
      });

      //Reconvert sizes in garment objects

      let currentGarment = "";
      let res = { sizes: [] };

      // eslint-disable-next-line
      sizeAmountPerPage.map((sizePageItems, index) => {
        //Each page
        // eslint-disable-next-line
        sizePageItems.map((sizePageItem, ind) => {
          if (sizePageItem.name === currentGarment) {
            //This garment is already started in this page, so res is populated

            //Add size
            res.sizes.push({
              size: sizePageItem.size,
              quantity: sizePageItem.quantity,
            });

            //If is end of page
            if (
              ind === maxSizesPerPage - 1 ||
              ind === sizePageItems.length - 1
            ) {
              page.push(res);
            }
          } else if (sizePageItem.name !== currentGarment) {
            //This garment is NOT started yet in this page, so res is NOT populated

            //Submit finished last one
            if (currentGarment !== "") {
              page.push(res);
            }
            //Reset garment object container
            res = { sizes: [] };

            //Set garment as started
            currentGarment = sizePageItem.name;

            //Populate res
            res.name = sizePageItem.name;
            res.total = sizePageItem.total;

            //Add size
            res.sizes.push({
              size: sizePageItem.size,
              quantity: sizePageItem.quantity,
            });

            //Add garment if no more info in this page
            if (
              ind === maxSizesPerPage - 1 ||
              ind === sizePageItems.length - 1
            ) {
              page.push(res);
            }
          }
        });

        //Pushes page
        // eslint-disable-next-line
        page.map((garment) => {
          pages[index].push(garment);
        });

        //Reset temp variables
        currentGarment = "";
        page = [];
        res = { sizes: [] };
      });

      newPagedResults = pages;

      setPaginatedResults(newPagedResults);
      setShowDocuments(true);
    } else {
      setShowDocuments(false);
    }
    // eslint-disable-next-line
  }, [results]);

  return (
    <div id="count-to-print-garments">
      {showDocuments && (
        <>
          {
            // eslint-disable-next-line
            paginatedResults.map((result, index) => (
              <div key={"count-garments-result-" + index}>
                <div>
                  <div
                    id={"count-garments"}
                    style={{
                      boxSizing: "border-box",
                      border: "1px solid black",
                      height: `${
                        index !== paginatedResults.length - 1 ? "100%" : "99%"
                      }`,
                      minHeight: "100%",
                      width: "100%",
                      margin: "auto",
                      marginTop: "0px !important",
                      fontFamily: "SF Pro Display Regular",
                      marginBottom: `${
                        index !== paginatedResults.length - 1 ? "3%" : "0"
                      }`,
                      position: "relative",
                    }}
                    key={Math.floor(Math.random() * 100001)}
                  >
                    <CountByGarmentTemplate
                      clientName={pedido.client.name}
                      result={result}
                    />
                  </div>
                </div>
              </div>
            ))
          }
          <CountGarmentsReady setFrameTarjet={setFrameTarjet} />
        </>
      )}
    </div>
  );
};

export default CountByGarmentsTemplate;
