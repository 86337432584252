export function prendaTypeEliminarModal(item, entity) {
  return {
    id: item.id,
    title: "Por favor, confirme que desea eliminar este tipo de prenda",
    subtitle: "¡CUIDADO! Eliminar un tipo de prenda también ELIMINARÁ las PRENDAS de este tipo. Esta acción no se puede deshacer",
    texts: [
      {
        text: "Nombre",
        value: item.name,
      },
    ],
    buttons: [
      {
        text: "Confirmar",
        action: "delete",
        classes: "unsafe",
        entity: entity,
        actionType: "mutation",
      },
      {
        text: "Cancelar",
        action: "closeModal",
        classes: "safe",
        entity: entity,
      },
    ],
  };
}
