import { CONFIGURAR_TOKEN } from '../types/auth/index';

//Load user token
export function loadUserTokenAction(token) {
    return (dispatch) => {
        dispatch({
            type: CONFIGURAR_TOKEN,
            payload: token
    });
    }
}