import React, { useState } from "react";

const NavItem = ({ parent, children, link }) => {
  const [visible, setVisible] = useState(false);

  const showSubmenu = () => {
    setVisible(true);
  };

  const hideSubmenu = () => {
    setVisible(false);
  };

  return (
    <>
      <div
        className="nav-container__item"
        onMouseEnter={showSubmenu}
        onMouseLeave={hideSubmenu}
      >
        {link ? <a href={link}>{parent}</a> : parent}
        {children && (
          <span className="nav-container__item-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="9"
              viewBox="0 0 10 9"
            >
              <g
                id="Polígono_2"
                data-name="Polígono 2"
                transform="translate(10 9) rotate(180)"
                fill="#fff"
              >
                <path
                  d="M 8.300484657287598 8 L 1.699515581130981 8 L 5 2.059128522872925 L 8.300484657287598 8 Z"
                  stroke="none"
                />
                <path
                  d="M 5 4.118248462677002 L 3.399026870727539 7.000000476837158 L 6.600973129272461 7.000000476837158 L 5 4.118248462677002 M 5 9.5367431640625e-07 L 10 9 L 0 9 L 5 9.5367431640625e-07 Z"
                  stroke="none"
                  fill="#707070"
                />
              </g>
            </svg>
          </span>
        )}
        {visible && children && (
          <div className="nav-container__item-dropdown-container">
            {children.map((subitem, index) => (
              <div
                className="nav-container__item-dropdown-item"
                key={index.toString()}
              >
                <a href={subitem.link}>{subitem.name}</a>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default NavItem;
