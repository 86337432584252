import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormValue,
  loadValidationStates,
} from "../../actions/formDataActions";
//Utilities
import { validateForm } from "../../utilities/form/validation";

const FieldTextArea = ({ pos }) => {
  //Store data
  const { settings, formValues } = useSelector((state) => state.dataForm);
  const {
    name,
    label,
    placeholder,
    textOne,
    textTwo,
    validated,
    defaultValue,
  } = useSelector((state) => state.dataForm.settings.formContent[pos]);

  //States
  const [displayError, setDisplayError] = useState(false);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));
  const storeLoadValidationStates = (validationResult) =>
    dispatch(loadValidationStates(validationResult));

  //Functions
  const loadInputValue = (e) => {
    storeLoadFormValue([e.target.name, e.target.value]);
    //upload store validation state
    const validation = validateForm(settings.formContent, formValues);
    storeLoadValidationStates(validation.validationResult);
  };

  //useEffect
  useEffect(() => {
    if (!validated) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
    }
    // eslint-disable-next-line
  }, [validated]);

  useEffect(() => {
    //In case of update, load current value into form values
    if (defaultValue) {
      storeLoadFormValue([name, defaultValue]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="input-container">
      {label && <div className="input-label">{label}</div>}
      {label && <div className="input-text">{textOne}</div>}
      {label && <div className="input-text">{textTwo}</div>}
      <textarea
        type="text"
        name={name}
        className={"" + (displayError ? " errorInput" : "")}
        rows="1"
        onChange={loadInputValue}
        defaultValue={defaultValue ? defaultValue : placeholder}
      ></textarea>
    </div>
  );
};

export default FieldTextArea;
