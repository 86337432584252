import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as MagnifyingGlass } from "../../../../../assets/images/magnifyingGlass.svg";
import { ReactComponent as Chevron } from "../../../../../assets/images/chevron.svg";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  loadFormValue,
  deleteFormValue,
} from "../../../../../actions/formDataActions";

const FieldSelectTemplate = () => {
  //Store data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [valuePlaceholder, setValuePlaceholder] = useState("Sin plantilla");
  const [selectOptions, setSelectOptions] = useState({
    originalOptions: false,
    workOptions: false,
  });
  const [activeValue, setActiveValue] = useState("");

  //API
  const GET_TEMPLATES = gql`
    query getOrderTemplates {
      getOrderTemplates {
        id
        name
      }
    }
  `;

  const [getOrderTemplates, { error, data }] = useLazyQuery(GET_TEMPLATES);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));
  const storeDeleteFormValue = (key) => dispatch(deleteFormValue(key));

  //Functions
  const loadInputValue = (e) => {
    //Loads input value in store
    storeLoadFormValue([
      e.target.dataset.name,
      parseInt(e.target.dataset.optionvalue),
    ]);
    setValuePlaceholder(e.target.dataset.optionname);

    setActiveValue(e.target.dataset.optionname);
  };

  const removeTemplateValue = (e) => {
    //Remove template value when choosing Sin PLantilla
    setValuePlaceholder(e.target.dataset.optionname);
    storeDeleteFormValue("template_id");
    setActiveValue("Sin plantilla");
  };

  const filterSelectOptions = (e) => {
    //Loads new select options according to search field value
    const searchTerm = e.target.value;
    const newOptions = [];
    if (selectOptions) {
      // eslint-disable-next-line
      selectOptions.originalOptions.map((originalOption) => {
        if (
          originalOption.name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          newOptions.push(originalOption);
        }
      });
    }
    setSelectOptions({ ...selectOptions, workOptions: newOptions });
  };

  const isCurrent = (option) => {
    //Load style if item is selected
    if (option === "without") {
      return " current";
    } else if (option === formValues["template_id"]) {
      return " current";
    } else {
      return " ";
    }
  };

  //useEffect
  useEffect(() => {
    //get all countries
    getOrderTemplates();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && !error) {
      setSelectOptions({
        originalOptions: data.getOrderTemplates,
        workOptions: data.getOrderTemplates,
      });
      //Load name in input
      if (!formValues.template_id) {
        setActiveValue("");
      } else {
        // eslint-disable-next-line
        data.getOrderTemplates.map((template) => {
          if (template.id === formValues.template_id) {
            setActiveValue(template.name);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [data, error]);

  return (
    <>
      {data &&
        data.getOrderTemplates &&
        selectOptions &&
        selectOptions.workOptions && (
          <div className="input-container select">
            <div className="input-label">¿Usar una plantilla?</div>
            <>
              {/* Display collapsed */}
              <div className="input-select-collapsed">
                <input
                  type="text"
                  disabled
                  placeholder={valuePlaceholder}
                  value={activeValue}
                />
                <Chevron />
              </div>
              {/* Display expanded */}
              <div className="input-select-expanded">
                <div className="input-select-expanded__container">
                  {/* fixed search */}
                  <div className="input-select-expanded__search">
                    <input type="text" onChange={filterSelectOptions} />
                    <MagnifyingGlass />
                  </div>
                  <div className="input-select-expanded__option-container">
                    <div
                      className={
                        "input-select-expanded__option " + isCurrent("without")
                      }
                      data-name="template_id"
                      data-optionvalue="without"
                      data-optionname="template_id"
                      onClick={removeTemplateValue}
                    >
                      Sin plantilla
                    </div>
                    {/* Filter - Select - Options - API */}
                    {selectOptions &&
                      selectOptions.workOptions.map((item, index) => (
                        <div
                          className={
                            "input-select-expanded__option " +
                            isCurrent(item.id)
                          }
                          key={index}
                          data-name="template_id"
                          data-optionvalue={item.id}
                          data-optionname={item.name}
                          onClick={loadInputValue}
                        >
                          {item.name}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          </div>
        )}
    </>
  );
};

export default FieldSelectTemplate;
