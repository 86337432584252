import React from "react";
//Styles
import "../../../assets/css/login.css";


const QueryNewPassword = () => {


  return (
    <>
      <div className="login">
        <div className="login-container">
          <div className="login-container__title">
            Solicitar nueva contraseña
          </div>
          <div className="login-container__text-before">
            Por favor, escribanos a <a href="mailto:oscar@birdcom.es">oscar@birdcom.es</a> desde el correo con el que esté dado de alta para que le enviemos una nueva contraseña.
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryNewPassword;
