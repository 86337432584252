import React from "react";

const CreateButton = () => {
  return (
    <div className="loading" height="100%">
      <div className="item">
        <i className="loader spinner"></i>
      </div>
    </div>
  );
};

export default CreateButton;
