import React from "react";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory } from "react-router-dom";

const OrderManualAssign = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <h1>Asignar datos pedido manualmente</h1>
          <h1>Asignar datos pedido manualmente</h1>
          <h1>Asignar datos pedido manualmente</h1>
          <h1>Asignar datos pedido manualmente</h1>
          <h1>Asignar datos pedido manualmente</h1>
          <h1>Asignar datos pedido manualmente</h1>
          <h1>Asignar datos pedido manualmente</h1>
          <h1>Asignar datos pedido manualmente</h1>
          <h1>Asignar datos pedido manualmente</h1>
          <h1>Asignar datos pedido manualmente</h1>
        </>
      )}
    </>
  );
};

export default OrderManualAssign;
