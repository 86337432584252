import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/login.css";
//Router
import { useParams } from "react-router-dom";
//API
import { gql, useMutation } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";
//Components
import LostPasswordLink from "../login/LostPasswordLink";

const ResetPassword = () => {
  let { token, user, dateQueried } = useParams();

  //States
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [passwordOneError, setPasswordOneError] = useState("false");
  const [passwordTwoError, setPasswordTwoError] = useState("false");
  const [hashedPassword, setHashedPassword] = useState("");
  const [isTimedOut, setIsTimedOut] = useState(false);

  //API
  const UPDATE_USER_PASSWORD = gql`mutation updateForgottenPassword {
    updateForgottenPassword(input: {token: "${token}", password: "${hashedPassword}", password_confirmation: "${hashedPassword}", email: "${user + (user === "birdcom" ? "@oroelapp.com" : "@oroel.com")}"}) {
      message
    }
  }`;
// eslint-disable-next-line
  const [updateForgottenPassword, { data, error }] =
    useMutation(UPDATE_USER_PASSWORD);

  //Functions

  const changeHandlerPasswordOne = (e) => {
    setPasswordOne(e.target.value);
  };

  const changeHandlerPasswordTwo = (e) => {
    setPasswordTwo(e.target.value);
  };

  const actionRetrieve = () => {
    if (
      passwordOne !== "" &&
      passwordTwo !== "" &&
      passwordOne === passwordTwo &&
      passwordTwo.length >= 8
    ) {
      var sha256 = require("js-sha256");
      let hashed = sha256(passwordOne);
      setHashedPassword(hashed);
    } else {
      setPasswordOneError(true);
      setPasswordTwoError(true);
      toast.error("Lo sentimos, se ha producido un error.");
    }
  };

  //Use effect
  useEffect(() => {
    //check input error styles
    if (
      passwordOne !== "" &&
      passwordTwo !== "" &&
      passwordOne === passwordTwo &&
      passwordOne.length > 8
    ) {
      setPasswordOneError("false");
    } else {
      setPasswordOneError("true");
    }
    if (
      passwordTwo !== "" &&
      passwordOne !== "" &&
      passwordTwo === passwordOne &&
      passwordTwo.length > 8
    ) {
      setPasswordTwoError("false");
    } else {
      setPasswordTwoError("true");
    }
  }, [passwordOne, passwordTwo]);

  useEffect(() => {
    if (hashedPassword !== "") {

      updateForgottenPassword()
        .then(({ data }) => {
          //Notify
          toast.success("Se ha cambiado la contraseña correctamente.");
          window.location.href = "/";
        })
        .catch((e) => {
          //Notify KO
          toast.error("Lo sentimos, Se ha producido un error.");
        });
    }
    // eslint-disable-next-line
  }, [hashedPassword]);

  useEffect(() => {

    const urlDate = parseInt(dateQueried + "000");
    const dateObject = new Date(urlDate);
    let currentDate = new Date();
    const finalAvailableDate = new Date(dateObject.getTime() + 60 * 60000);

    if (currentDate > dateObject && currentDate < finalAvailableDate) {
      setIsTimedOut(false);
    } else {
      setIsTimedOut(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isTimedOut ? (
        <div className="login">
          <div className="login-container">
            <div className="login-container__title">
              Escriba la nueva contraseña
            </div>
            <div className="login-container__text-before">
              Por favor, escriba dos veces la nueva contraseña (mínimo 8
              caracteres) y pulse en aceptar para continuar.
            </div>
            <div className="login-container__form-container">
              <div className="login-container__form-field-container">
                <div className="login-container__form-field-label">
                  Nueva contraseña
                </div>
                <div
                  className="login-container__form-field-input"
                  style={{ marginBottom: "24px" }}
                >
                  <input
                    type="password"
                    onChange={changeHandlerPasswordOne}
                    className={passwordOneError !== "false" ? "errorInput" : ""}
                    placeholder={
                      passwordOneError !== "false"
                        ? "Por favor, escriba dos veces la misma contraseña"
                        : ""
                    }
                  />
                </div>
                <div className="login-container__form-field-label">
                  Repita contraseña
                </div>
                <div className="login-container__form-field-input">
                  <input
                    type="password"
                    onChange={changeHandlerPasswordTwo}
                    className={passwordTwoError !== "false" ? "errorInput" : ""}
                    placeholder={
                      passwordTwoError !== "false"
                        ? "Por favor, escriba dos veces la misma contraseña"
                        : ""
                    }
                  />
                </div>
              </div>
              <button
                className="login-container__form-field-submit"
                type="submit"
                onClick={actionRetrieve}
              >
                Actualizar la contraseña
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="login">
          <div className="login-container">
            <div className="login-container__title">Solicitud caducada</div>
            <div className="login-container__text-before">
              Por favor, recuerde que tiene 60 minutos para atender la solicitud
              de cambio de password.
              <br />
              <br />
              Vuelva a solicitarla pulsando en el enlace bajo estas líneas.
            </div>
            <LostPasswordLink />
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
