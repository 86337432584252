export const LOAD_ORIGINAL_RESULTS = 'LOAD_ORIGINAL_RESULTS';
export const LOAD_WORK_RESULTS = 'LOAD_WORK_RESULTS';
export const INIT_FILTERS = 'INIT_FILTERS';
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const ADD_SEARCH = 'ADD_SEARCH';
export const REMOVE_SEARCH = 'REMOVE_SEARCH';
export const ADD_ORDERBY = 'ADD_ORDERBY';
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const LOAD_FILTER_OPTIONS = 'LOAD_FILTER_OPTIONS';
export const LOAD_PAGINATOR_ITEMS = 'LOAD_PAGINATOR_ITEMS';
export const RELOAD_RESULTS = 'RELOAD_RESULTS';
export const LOAD_CURRENT_PAGE = 'LOAD_CURRENT_PAGE';
export const LOAD_ORDERBY_ITEMS = 'LOAD_ORDERBY_ITEMS';
export const LOAD_MODAL_CONTENT = 'LOAD_MODAL_CONTENT';
export const DELETE_TABLE_ITEM = 'DELETE_TABLE_ITEM';
export const ADD_ROW_2PRINT = 'ADD_ROW_2PRINT';
export const REMOVE_ROW_2PRINT = 'REMOVE_ROW_2PRINT';
