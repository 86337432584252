export function workplaceVerModal(item,entity){
    return {
      id: item.id,
      infoTitle: "Ver Centro de trabajo",
      texts: [
        {
          text: "Nombre del Centro de trabajo",
          value: item.name,
        },
        {
          text: "Dirección",
          value: item.address,
        },
        {
          text: "Código Postal",
          value: item.postal_code,
        },
        {
          text: "Población a la que pertenece",
          value: item.location.name,
        },
        {
          text: "Provincia a la que pertenece",
          value: item.location.province.name,
        },
        {
          text: "País al que pertenece",
          value: item.location.province.country.name,
        },
      ],
      buttons: [
        {
          text: "Editar",
          action: "link",
          link: "/centros-de-trabajo/editar-centro-de-trabajo",
          classes: "neutral",
          entity: entity,
        },
        {
          text: "Cerrar",
          action: "closeModal",
          classes: "safe",
          entity: entity,
        },
      ],
    };
}