import React from "react";
import { CSVLink } from "react-csv";
import HeadersExcel from "./HeadersExcel";
import RowsExcel from "./RowsExcel";
import { useSelector } from "react-redux";
import { gql, useQuery } from "@apollo/client";


const NewBotonExport = () => {

    const GET_PRENDAS = gql`
    query getPrendas {
      getPrendas {
        id
        name 
        reference
      }
    }`

    const {loading, data, error} = useQuery(GET_PRENDAS)

    if(!loading && !error ) {
      console.log(data)
    }

    

    const {originalData } = useSelector((state) => state.dataTable);
    const headers = HeadersExcel()
    const rows = RowsExcel()

    console.log(originalData)

      const nameFile = `Pedido_${originalData.getPedido.order_number}_modificado-${
        originalData.getPedido.client.name
      }-${new Date().toLocaleDateString()}`


    return(
        <div className="print">
            <button style={{color: "white!important"}}><CSVLink
            data={rows}
            headers={headers}
            filename={nameFile}
            >Exportar a Excel
            </CSVLink></button>
        </div>
    )
}

export default NewBotonExport