import React from "react";
//Cookie token
import cookie from "react-cookies";

const Footer = () => {
  var token = cookie.load("usertk");
  var url = window.location.pathname;
  var show = token && url !== "/logout" ? true : false;

  return (
    <>
      {show && (
        <div
          style={{
            backgroundColor: "rgb(44,61,113)",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            borderRadius: "5px 5px 0 0",
            border: 0,
            color: "white",
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="15"
            viewBox="0 0 12 15"
            style={{
              marginBottom: 4,
              marginRight: "10px",
              marginLeft: "5px",
              color: "white",
            }}
          >
            <g
              id="Grupo_38"
              data-name="Grupo 38"
              transform="translate(-763 -1052)"
            >
              <text
                id="c"
                transform="translate(764 1052)"
                fill="#fff"
                fontSize="12"
                fontFamily="SFProDisplay-Regular, SF Pro Display"
              >
                <tspan x="1.988" y="11">
                  c
                </tspan>
              </text>
              <g
                id="Elipse_1"
                data-name="Elipse 1"
                transform="translate(763 1054)"
                fill="none"
                stroke="#fff"
                strokeWidth="1"
              >
                <circle cx="6" cy="6" r="6" stroke="none" />
                <circle cx="6" cy="6" r="5.5" fill="none" />
              </g>
            </g>
          </svg>
          Birdcom 2023 |
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.941"
            height="10"
            viewBox="0 0 12.941 10"
            style={{ marginRight: "10px", marginLeft: "5px", color: "white" }}
          >
            <path
              id="Trazado_31"
              data-name="Trazado 31"
              d="M14.353,7H2.588A.588.588,0,0,0,2,7.588v8.824A.588.588,0,0,0,2.588,17H14.353a.588.588,0,0,0,.588-.588V7.588A.588.588,0,0,0,14.353,7Zm-.588,8.824H3.176V9.853l5.147,3.029a.265.265,0,0,0,.294,0l5.147-3.118ZM8.618,11.529a.265.265,0,0,1-.294,0L3.176,8.176H13.765Z"
              transform="translate(-2 -7)"
              fill="#fff"
            />
          </svg>
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="mailto:oscar@birdcom.es"
          >
            oscar@birdcom.es
          </a>
        </div>
      )}
    </>
  );
};

export default Footer;
