import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/login.css";
//API
import { gql, useMutation } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";
//Redux
import { loadUserTokenAction } from "../../../actions/authActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory } from "react-router-dom";
//Components
import LostPasswordLink from "../login/LostPasswordLink";

const Login = () => {
  //Redirect if already logged in
  let history = useHistory();
  const alreadyLoggedIn = cookie.load("usertk");
  
  //Store Data
  const token = useSelector((state) => state.auth.usertk);

  //States
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [hash, setHash] = useState("");
  const [userError, setUserError] = useState("false");
  const [passwordError, setPasswordError] = useState("false");

  //API
  const LOGIN_ATTEMPT = gql`mutation login {
    login(input: {username: "${user}", password: "${hash}"}) {
      access_token
    }
  }`;

  const [tryToLog, { data, error }] = useMutation(LOGIN_ATTEMPT);

  //Store Action Dispatch
  const dispatch = useDispatch();
  const storeTokenLoad = (res) => dispatch(loadUserTokenAction(res));

  //Functions
  const updateUser = (e) => {
    setUser(e.target.value);
  };

  const updatePassword = (e) => {
    setPassword(e.target.value);
  };

  const attempLogin = () => {
    if (user === "" || password === "") {
      if (user === "" && password === "") {
        toast.error("Por favor, introduzca sus credenciales");
        setUserError(true);
        setPasswordError(true);
      } else {
        if (user === "") {
          setUserError(true);
          toast.error("Por favor, introduzca su usuario");
        }
        if (password === "") {
          setPasswordError(true);
          toast.error("Por favor, introduzca su contraseña");
        }
      }
    } else {
      var sha256 = require("js-sha256");
      let hashed = sha256(password);
      setHash(hashed);
    }
  };

  //Use effect
  useEffect(() => {
    if (data && !error) {
      //Notify
      toast.success("Las credenciales son correctas");
      //Load to store
      if (data.login.access_token) {
        let res = "Bearer " + data.login.access_token;
        storeTokenLoad(res);
      }
    } else if (error) {
      //Notify
      toast.error("Las credenciales no son correctas");
    }
    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    //check input error styles
    if (user !== "") {
      setUserError("false");
    }
    if (password !== "") {
      setPasswordError("false");
    }
  }, [user, password]);

  useEffect(() => {
    //Redirect on success

    if (token !== "") {
      cookie.save("usertk", token);
      window.location.href = "/dashboard";
    }
  }, [token]);

  useEffect(() => {
    //API call if new hash is ready

    if (hash !== "") {
      tryToLog(hash).catch((e) => {});
    }
    // eslint-disable-next-line
  }, [hash]);

  return (
    <>
      {alreadyLoggedIn ? (
        history.push("/dashboard")
      ) : (
        <div className="login">
          <div className="login-container">
            <div className="login-container__title">Bienvenid@</div>
            <div className="login-container__text-before">
              Por favor, introduzca sus credenciales para continuar.
            </div>
            <div className="login-container__form-container">
              <div className="login-container__form-field-container">
                <div className="login-container__form-field-label">Usuario</div>
                <div className="login-container__form-field-input">
                  <input
                    type="text"
                    onChange={updateUser}
                    className={userError !== "false" ? "errorInput" : ""}
                    placeholder={
                      userError !== "false"
                        ? "Por favor, introduzca su usuario"
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="login-container__form-field-container">
                <div className="login-container__form-field-label">
                  Contraseña
                </div>
                <div className="login-container__form-field-input">
                  <input
                    type="password"
                    onChange={updatePassword}
                    className={passwordError !== "false" ? "errorInput" : ""}
                    placeholder={
                      passwordError !== "false"
                        ? "Por favor, introduzca su contraseña"
                        : ""
                    }
                  />
                </div>
              </div>
              <button
                className="login-container__form-field-submit"
                type="submit"
                onClick={attempLogin}
              >
                Aceptar
              </button>
            </div>
            <LostPasswordLink />
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
