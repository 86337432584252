import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as MagnifyingGlass } from "../../../../../assets/images/magnifyingGlass.svg";
import { ReactComponent as Chevron } from "../../../../../assets/images/chevron.svg";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { loadGarmentValue } from "../../../../../actions/formDataActions";

const FieldOrderGarment = ({ name, pos, getPrendas }) => {
  //Store data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [valuePlaceholder, setValuePlaceholder] = useState(
    "Seleccione una prenda"
  );
  const [displayError, setDisplayError] = useState(false);
  const [selectOptions, setSelectOptions] = useState({
    originalOptions: false,
    workOptions: false,
  });
  const [activeValue, setActiveValue] = useState("");

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadGarmentValue = (valuePair) =>
    dispatch(loadGarmentValue(valuePair));

  //Functions
  const loadInputValue = (e) => {
    //Loads input value in store
    storeLoadGarmentValue([
      pos,
      "prenda_id",
      parseInt(e.target.dataset.optionvalue),
    ]);
    setValuePlaceholder(e.target.dataset.optionname);

    setActiveValue(e.target.dataset.optionname);
  };

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const filterSelectOptions = (e) => {
    //Loads new select options according to search field value
    const searchTerm = e.target.value;
    const newOptions = [];
    if (selectOptions) {
      // eslint-disable-next-line
      selectOptions.originalOptions.map((originalOption) => {
        if (
          removeAccents(originalOption.name.toLowerCase()).includes(
            removeAccents(searchTerm.toLowerCase())
          )
        ) {
          newOptions.push(originalOption);
        }
      });
    }

    setSelectOptions({ ...selectOptions, workOptions: newOptions });
  };

  const isCurrent = (option) => {
    //Load style if item is selected
    if (option === formValues["tipo_prenda_id"]) {
      return " current";
    } else {
      return " ";
    }
  };

  //useEffect
  useEffect(() => {
    if (
      formValues.garments[pos].prenda_id &&
      formValues.garments[pos].prenda_id !== 0
    ) {
      setDisplayError(false);
    } else {
      setDisplayError(true);
    }
    // eslint-disable-next-line
  }, [formValues.garments[pos].prenda_id]);

  useEffect(() => {
    //get all countries
    setSelectOptions({
      originalOptions: getPrendas,
      workOptions: getPrendas,
    });
    //Load name in input
    if (!formValues.tipo_prenda_id) {
      setActiveValue("");
    } else {
      // eslint-disable-next-line
      getPrendas.map((prendaType) => {
        if (prendaType.id === formValues.tipo_prenda_id) {
          setActiveValue(prendaType.name);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {selectOptions && selectOptions.workOptions && (
        <div className="input-container select">
          <div className="input-label">{name}</div>
          <>
            {/* Display collapsed */}
            <div className="input-select-collapsed">
              <input
                type="text"
                disabled
                placeholder={valuePlaceholder}
                className={displayError ? "errorInput" : ""}
                value={activeValue}
              />
              <Chevron />
            </div>
            {/* Display expanded */}
            <div className="input-select-expanded">
              <div className="input-select-expanded__container">
                {/* fixed search */}
                <div className="input-select-expanded__search">
                  <input type="text" onChange={filterSelectOptions} />
                  <MagnifyingGlass />
                </div>
                <div className="input-select-expanded__option-container">
                  {/* Filter - Select - Options - API */}
                  {selectOptions &&
                    selectOptions.workOptions.map((item, index) => (
                      <div
                        className={
                          "input-select-expanded__option " + isCurrent(item.id)
                        }
                        key={index}
                        data-name="tipo_prenda_id"
                        data-optionvalue={item.id}
                        data-optionname={item.name}
                        onClick={loadInputValue}
                      >
                        {item.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default FieldOrderGarment;
