import React, { useState, useEffect } from "react";
//Components
import Table from "../../tableSections/Table";
import Loading from "../../tableSections/Loading";
import ModalDeleteLineaPedido from "../../modal/ModalDeleteLineaPedido";
//Cookie token
import cookie from "react-cookies";
//Router
import { useParams } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadOriginalResults,
  loadWorkResults,
  loadSettings,
  loadPaginatorItems,
  reloadResults,
} from "../../../actions/tableDataActions";
//Utilities
import { paginator, getPaginatorNavItems } from "../../../utilities/paginator";
import { getNewResults } from "../../../utilities/pedido";

const PedidoView = () => {
  //Redirect if not logged in
  const token = cookie.load("usertk");

  let { id } = useParams();
  //States
  const [displayTable, setDisplayTable] = useState(false);

  //API
  const GET_ROWS_PEDIDO = gql`
  query getPedido {
    getPedido(id: ${id}) {
      id
      order_date
      order_number
      client{
        name
        phone
        email
      }
      rows {
        id
        worker_name
        worker_code
        dni
        workplace
        job
        sex
        garments{
          prenda {
            name
            reference
          }
          size
          quantity
        }
      }
    }
  }
  `;

  const [getPedido, { error, data }] = useLazyQuery(GET_ROWS_PEDIDO);

  //Store data
  const { reload, search, originalData, settings, orderBy } = useSelector(
    (state) => state.dataTable
  );

  //Redux actions
  const dispatch = useDispatch();
  const storeOriginalLoad = () => dispatch(loadOriginalResults(data));
  const storeWorkLoad = (paginatedResults) =>
    dispatch(loadWorkResults(paginatedResults));
  const storeSettings = (defaultSettings) =>
    dispatch(loadSettings(defaultSettings));
  const loadNewPaginatorItems = (paginatorItems) =>
    dispatch(loadPaginatorItems(paginatorItems));
  const storeReloadResults = (config) => dispatch(reloadResults(config));
  const loadNewResults = (newResults) => dispatch(loadWorkResults(newResults));

  //Functions
  const goHome = () => {
    //Redirects if not logged in
    window.location.href = "/";
  };

  //useEffect
  useEffect(() => {
    // Results without error
    if (data && !error) {
      //Section Settings
      const defaultSettings = {
        entity: "pedidoRow",
        queryName: "getPedido",
        title: `Ver Pedido - ${data.getPedido.order_number}`,
        text: "Expanda la información de la línea que desee, edítela, elimínela o cree una línea nueva. Puede buscar por nombre, puesto o centro de trabajo y emitir documentos.",
        info: [
          {
            label: "Fecha del pedido",
            text: data.getPedido.order_date,
            size: "20",
          },
          {
            label: "Nombre del cliente",
            text: data.getPedido.client.name,
            size: "20",
          },
          {
            label: "",
            text: "",
            size: "25",
          },
          {
            label: "Teléfono",
            text: data.getPedido.client.phone,
            size: "15",
          },
          {
            label: "Email",
            text: data.getPedido.client.email,
            size: "15",
          },
        ],
        createButtonText: "Crear Nueva Línea en este pedido",
        createButtonLink: "/nueva-linea-pedido/" + id,
        documentsButtonText: "Emitir documentos",
        documentsButtonLink: "/emitir-documentos/" + id,
        exportButtonText: "Exportar Excel",
        tableHeaders: [
          { text: "Nombre", size: 20, orderBy: true, field: "worker_name" },
          { text: "Puesto", size: 15, orderBy: false, field: "job" },
          {
            text: "C. de trabajo",
            size: 15,
            orderBy: false,
            field: "workplace",
          },
          {
            text: "Cantidad",
            size: 10,
            orderBy: false,
            field: "quantity",
          },
          {
            text: "Nº Interno",
            size: 10,
            orderBy: false,
            field: "worker_code",
          },
          {
            text: "Sexo",
            size: 10,
            orderBy: false,
            field: "sex",
          },
          {
            text: "Opciones disponibles",
            size: 20,
            orderBy: false,
            field: "options",
          },
        ],
        searchLabel: "Buscar pedido",
        paginationSettings: { page: 1, perPage: 6 },
        expandibleRows: true,
        rowContent: [
          { size: 20, content: "worker_name", align: "left" },
          { size: 15, content: "job", align: "left" },
          { size: 15, content: "workplace", align: "left" },
          { size: 10, calc: ["garments", "quantity"], align: "left" },
          { size: 10, content: "worker_code", align: "left" },
          { size: 10, content: "sex", align: "left" },
          {
            size: 20,
            buttons: [
              {
                text: "Editar",
                action: "link",
                link: "/editar-linea-pedido",
                classes: "neutral",
              },
              { text: "Eliminar", action: "modal", classes: "unsafe" },
            ],
          },
        ],
        extendedRowContent: [
          { size: 35, keyName: "garments", content: "prenda", align: "left" },
          { size: 15, keyName: "garments", content: "size", align: "left" },
          { size: 50, keyName: "garments", content: "quantity", align: "left" },
        ],
      };
      //Load defaultSettings in store
      storeSettings(defaultSettings);
    } else if (data && data.getPedido.rows.length === 0) {
      // No results message display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          No existe ningún resultado.
          <br />
          <br />
          Por favor, modifique la búsqueda o los filtros aplicados.
        </div>
      );
      toast.error(<Msg />);
    } else if (error) {
      // Technical error display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          Se ha producido un error técnico
          <br />
          <br />
          Por favor, contacte con nosotros en el email que encontrará al pie de
          esta página.
        </div>
      );
      console.log(error);
      toast.error(<Msg />);
    }
    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    if (settings && data && !error) {
      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        data.getPedido,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      //Load work copy paginated
      let paginatedResults = {
        getPedido: paginator(
          data.getPedido,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };

      //Load pagination items
      loadNewPaginatorItems(paginatorItems);

      // Loads original copy in store
      storeOriginalLoad(data);
      
      storeWorkLoad(paginatedResults);

      // Displays table
      setDisplayTable(true);
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    //Generates new content and pagination
    if (reload) {
      //reset load flag to false to avoid loop
      storeReloadResults(false);

      // Gets new results
      const newResults = getNewResults(originalData, search, orderBy);
      
      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        newResults.getPedido.rows,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);

      // Gets paginated results
      let paginatedResults = {
        getPedido: paginator(
          newResults.getPedido.rows,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };

      //Load paginated results
      loadNewResults(paginatedResults);
      
    }
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    //Runs query at first load
    getPedido();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        goHome()
      ) : (
        <>
          <div className="table-container">
            <Loading />
            {displayTable && <Table />}
          </div>
          <ModalDeleteLineaPedido />
        </>
      )}
    </>
  );
};

export default PedidoView;
