import React, { useState, useEffect } from "react";
//Styles
import "../../../../../assets/css/print-order-labels-11.css";
//Redux
import { useSelector } from "react-redux";
//Components
import Label11Alcampo from "./Label11Alcampo";
import Labels11Ready from "../Labels11Ready";

const Labels11Alcampo = ({setFrameTarjet}) => {
  //Store data
  const { rowsToPrint } = useSelector((state) => state.dataTable);
  const { pedido } = useSelector((state) => state.dataForm);

  //States
  const [showDocuments, setShowDocuments] = useState(false);
  const [results, setResults] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState([]);

  //Functions
  const getRowsToPrint = () => {
    let res = [];
    if (rowsToPrint.length === 0) {
      res = pedido.rows;
    } else {
      // eslint-disable-next-line
      rowsToPrint.map((rowToPrint) => {
        res.push(pedido.rows[rowToPrint]);
      });
    }
    return res;
  };
  function chunkArray(myArray, chunk_size) {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = myArray.slice(index, index + chunk_size);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  //useEffect
  useEffect(() => {
    if (pedido) {
      setResults(getRowsToPrint());
    }
    // eslint-disable-next-line
  }, [pedido, rowsToPrint]);

  useEffect(() => {
    if (results && results.length > 0) {
      const newPagedResults = [];
      // eslint-disable-next-line
      results.map((result) => {
        //Separate garments due max document capacity
        let separatedDocumentRows = chunkArray(result.garments, 24);
        // eslint-disable-next-line
        separatedDocumentRows.map((separatedDocumentRow) => {
          const pagedResult = {
            cliente: pedido.client.name,
            workplace: result.workplace,
            job: result.job,
            worker_code: result.worker_code,
            worker_dni: result.worker_dni,
            worker_name: result.worker_name,
            worker_sex: result.worker_sex,
            garments: separatedDocumentRow,
          };
          newPagedResults.push(pagedResult);
        });
      });
      setPaginatedResults(newPagedResults);
      setShowDocuments(true);
    } else {
      setShowDocuments(false);
    }
    // eslint-disable-next-line
  }, [results]);

  return (
    <div id="labels-to-print-1-1">
      {showDocuments && (
        <>
          {
            // eslint-disable-next-line
            paginatedResults.map((result, index) => (
              <div key={"labels 1-8-result-" + index}>
                <div>
                  <div
                    id={"label"}
                    style={{
                      whiteSpace: 'pre-wrap',
                      boxSizing: "border-box",
                      border: "1px solid black",
                      height: `${
                        index !== paginatedResults.length - 1 ? "100%" : "99%"
                      }`,
                      minHeight: "100%",
                      width: "100%",
                      margin: "auto",
                      marginTop: "0px !important",
                      fontFamily: "SF Pro Display Regular",
                      marginBottom: `${
                        index !== paginatedResults.length - 1 ? "3%" : "0"
                      }`,
                    }}
                    key={Math.floor(Math.random() * 100001)}
                  >
                    <Label11Alcampo pedido={pedido} result={result} />
                  </div>
                </div>
              </div>
            ))
            
          }
          <Labels11Ready setFrameTarjet={setFrameTarjet}/>
        </>
      )}
    </div>
  );
};

export default Labels11Alcampo;
