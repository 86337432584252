import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as Labels11 } from "../../../../assets/images/labels1-1.svg";
import { ReactComponent as CountByWorkplace } from "../../../../assets/images/countByWorkplace.svg";
//Templates
import CountByGarmentsTemplate from "../count/CountByGarmentsTemplate";
import CountByWorkplaceTemplate from "./CountByWorkplacesTemplate";

const PrintOrderCountButton = () => {
  //States
  const [frameTarget, setFrameTarjet] = useState("");
  const [isByGarmentsReady, setIsByGarmentsReady] = useState(false);
  const [isByWorkplaceReady, setIsByWorkplaceReady] = useState(false);

  //Functions
  const retrieveAction = (e) => {

    switch (e.target.dataset.type) {
      case "garments":
        setIsByGarmentsReady(true);
        break;
      case "workplaces":
        setIsByWorkplaceReady(true);
        break;
      default:
        break;
    }
  };

  const sendToPrint = (frameTarget) => {
    var content = document.getElementById(frameTarget);

    var pri = document.getElementById("documents-to-print").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
    setFrameTarjet("");
    setIsByGarmentsReady(false);
    setIsByWorkplaceReady(false);
  };

  //useEffect
  useEffect(() => {
    if (frameTarget !== "") {
      sendToPrint(frameTarget);
    }
  }, [frameTarget]);

  return (
    <div className="print-document__container count">
      <div className="print-document__texts-container">
        <h2 className="print-document__texts-title">Recuentos</h2>
      </div>
      <div className="print-document__buttons-container">
        <div className="print-document__button-container">
          <div
            className="hitPoint"
            data-type="garments"
            onClick={retrieveAction}
          ></div>
          <div className="print-document__buttons-icon">
            <Labels11 />
          </div>
          <div className="print-document__buttons-texts">
            <h2 className="print-document__texts-title">Por prendas</h2>
            <p className="print-document__texts-text">
              Solo se agrupa por prendas.
            </p>
          </div>
        </div>
        <div className="print-document__button-container">
          <div
            className="hitPoint"
            data-type="workplaces"
            onClick={retrieveAction}
          ></div>
          <div className="print-document__buttons-icon">
            <CountByWorkplace />
          </div>
          <div className="print-document__buttons-texts">
            <h2 className="print-document__texts-title">Por centro</h2>
            <p className="print-document__texts-text">
              Se agrupa por centros de trabajo y en cada uno de estos por
              prenda.
            </p>
          </div>
        </div>
      </div>
      {isByGarmentsReady && (
        <CountByGarmentsTemplate setFrameTarjet={setFrameTarjet} />
      )}
      {isByWorkplaceReady && (
        <CountByWorkplaceTemplate setFrameTarjet={setFrameTarjet} />
      )}
    </div>
  );
};

export default PrintOrderCountButton;
