import React from "react";
import { Switch, Route } from "react-router-dom";

//Login
import Login from "../components/sections/login/Login";
import Logout from "../components/sections/login/Logout";
import QueryNewPassword from "../components/sections/login/QueryNewPassword";
import PasswordRecoveryQuery from "../components/sections/login/PasswordRecoveryQuery";
import PasswordSet from "../components/sections/login/PasswordSet";
import ResetPassword from "../components/sections/login/ResetPassword";

//Dashboard
import Dashboard from "../components/sections/Dashboard";

//Prendas
import Prendas from "../components/sections/prendas/Prendas";
import PrendasCreate from "../components/sections/prendas/PrendasCreate";
import PrendasUpdate from "../components/sections/prendas/PrendasUpdate";

//Tipos de prendas
import PrendaTypes from "../components/sections/prendaTypes/PrendaTypes";
import PrendaTypesCreate from "../components/sections/prendaTypes/PrendaTypesCreate";
import PrendaTypesUpdate from "../components/sections/prendaTypes/PrendaTypesUpdate";

//Cliente
import Clientes from "../components/sections/clientes/Clientes";
import ClientesCreate from "../components/sections/clientes/ClientesCreate";
import ClienteView from "../components/sections/clientes/ClienteView";
import ClientesUpdate from "../components/sections/clientes/ClientesUpdate";

//Grupo de empresas
import BusinessGroups from "../components/sections/businessGroups/BusinessGroups";
import BusinessGroupsCreate from "../components/sections/businessGroups/BusinessGroupsCreate";
import BusinessGroupsUpdate from "../components/sections/businessGroups/BusinessGroupsUpdate";

//Pedidos
import PedidoView from "../components/sections/pedidos/PedidoView";
import OrderUpdate from "../components/sections/pedidos/OrderUpdate";
import LineaPedidoCreate from "../components/sections/pedidos/LineaPedidoCreate";
import LineaPedidoUpdate from "../components/sections/pedidos/LineaPedidoUpdate";
import Pedidos from "../components/sections/pedidos/Pedidos";
import OrderCreateSelectClient from "../components/sections/pedidos/OrderCreateSelectClient";
import OrderCreateUploadFile from "../components/sections/pedidos/OrderCreateUploadFile";
import OrderTemplateCheck from "../components/sections/pedidos/OrderTemplateCheck";
import OrderManualAssign from "../components/sections/pedidos/OrderManualAssign";
import OrderManualCheck from "../components/sections/pedidos/OrderManualCheck";

//Localizaciones
//Países
import Countries from "../components/sections/localizaciones/paises/Countries";
import CountriesCreate from "../components/sections/localizaciones/paises/CountriesCreate";
import CountriesUpdate from "../components/sections/localizaciones/paises/CountriesUpdate";
//Provincias
import ProvincesSelectCountry from "../components/sections/localizaciones/provincias/ProvincesSelectCountry";
import ProvincesView from "../components/sections/localizaciones/provincias/ProvincesView";
import ProvincesCreate from "../components/sections/localizaciones/provincias/ProvincesCreate";
import ProvincesUpdate from "../components/sections/localizaciones/provincias/ProvincesUpdate";
//Poblaciones
import LocationsSelectCountry from "../components/sections/localizaciones/poblaciones/LocationsSelectCountry";
import LocationsSelectProvince from "../components/sections/localizaciones/poblaciones/LocationsSelectProvince";
import LocationsView from "../components/sections/localizaciones/poblaciones/LocationsView";
import LocationsCreate from "../components/sections/localizaciones/poblaciones/LocationsCreate";
import LocationsUpdate from "../components/sections/localizaciones/poblaciones/LocationsUpdate";
//Centros de trabajo
import WorkplacesSelectClient from "../components/sections/localizaciones/workplaces/WorkplacesSelectClient";
import WorkplacesView from "../components/sections/localizaciones/workplaces/WorkplacesView";
import WorkplacesCreate from "../components/sections/localizaciones/workplaces/WorkplacesCreate";
import WorkplacesUpdate from "../components/sections/localizaciones/workplaces/WorkplacesUpdate";

//Documentos
import DocumentSelection from "../components/sections/DocumentSelection";

//Exportación
import ExportBusinessGroupCount from "../components/sections/businessGroups/ExportBusinessGroupCount"

const Routes = () => {
  return (
    <div style={{marginTop: "62px", minHeight: "calc(100vh - 102px)"}}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/recuperar-password" component={PasswordRecoveryQuery} />
        <Route exact path="/nuevo-password" component={PasswordSet} />
        <Route exact path="/query-new-password" component={QueryNewPassword} />
        <Route exact path="/reset-password/:token/:user/:dateQueried" component={ResetPassword} />
        

        <Route exact path="/dashboard" component={Dashboard} />

        <Route exact path="/buscar-prenda" component={Prendas} />
        <Route exact path="/crear-prenda" component={PrendasCreate} />
        <Route exact path="/editar-prenda/:id" component={PrendasUpdate} />

        <Route exact path="/buscar-tipo-de-prenda" component={PrendaTypes} />
        <Route exact path="/crear-tipo-de-prenda" component={PrendaTypesCreate} />
        <Route exact path="/editar-tipo-de-prenda/:id" component={PrendaTypesUpdate} />

        <Route exact path="/buscar-cliente" component={Clientes} />
        <Route exact path="/buscar-cliente/0" component={Clientes} />
        <Route exact path="/crear-cliente" component={ClientesCreate} />
        <Route exact path="/editar-cliente/:id" component={ClientesUpdate} />
        <Route exact path="/ver-cliente/:id" component={ClienteView} />

        <Route exact path="/buscar-grupo-de-empresas" component={BusinessGroups} />
        <Route exact path="/crear-grupo-de-empresas" component={BusinessGroupsCreate} />
        <Route exact path="/editar-grupo-de-empresas/:id" component={BusinessGroupsUpdate} />

        <Route exact path="/ver-pedido/:id" component={PedidoView} />
        <Route exact path="/editar-pedido/:id" component={OrderUpdate} />
        <Route exact path="/nueva-linea-pedido/:id" component={LineaPedidoCreate} />
        <Route exact path="/editar-linea-pedido/:id" component={LineaPedidoUpdate} />
        <Route exact path="/buscar-pedido" component={Pedidos} />

        <Route exact path="/crear-pedido/seleccionar-cliente" component={OrderCreateSelectClient} />
        <Route exact path="/crear-pedido/subir-archivo/:id" component={OrderCreateUploadFile} />
        <Route exact path="/crear-pedido/plantilla/comprobar-resultado" component={OrderTemplateCheck} />
        <Route exact path="/crear-pedido/manual/asignar-datos" component={OrderManualAssign} />
        <Route exact path="/crear-pedido/manual/comprobar-resultado" component={OrderManualCheck} />

        <Route exact path="/emitir-documentos/:id" component={DocumentSelection} />


        <Route exact path="/paises" component={Countries} />
        <Route exact path="/paises/crear-pais" component={CountriesCreate} />
        <Route exact path="/paises/editar-pais/:id" component={CountriesUpdate} />

        <Route exact path="/provincias/seleccione-pais" component={ProvincesSelectCountry} />
        <Route exact path="/provincias/ver/:id" component={ProvincesView} />
        <Route exact path="/provincias/crear-provincia/:id" component={ProvincesCreate} />
        <Route exact path="/provincias/editar-provincia/:id" component={ProvincesUpdate} />

        <Route exact path="/poblaciones/seleccione-pais" component={LocationsSelectCountry} />
        <Route exact path="/poblaciones/seleccione-provincia/:id" component={LocationsSelectProvince} />
        <Route exact path="/poblaciones/ver/:id" component={LocationsView} />
        <Route exact path="/poblaciones/crear-poblacion/:id" component={LocationsCreate} />
        <Route exact path="/poblaciones/editar-poblacion/:id" component={LocationsUpdate} />

        <Route exact path="/centros-de-trabajo/seleccione-cliente" component={WorkplacesSelectClient} />
        <Route exact path="/centros-de-trabajo/ver/:id" component={WorkplacesView} />
        <Route exact path="/centros-de-trabajo/crear-centro-de-trabajo/:id" component={WorkplacesCreate} />
        <Route exact path="/centros-de-trabajo/editar-centro-de-trabajo/:id" component={WorkplacesUpdate} />

        <Route exact path="/exportar-recuento-grupo-empresas/:id" component={ExportBusinessGroupCount} />

      </Switch>
    </div>
  );
};

export default Routes;
