export function countryVerModal(item, entity) {
  return {
    id: item.id,
    infoTitle: "Ver país",
    texts: [
      {
        text: "Nombre del país",
        value: item.name,
      },
    ],
    buttons: [
      {
        text: "Editar",
        action: "link",
        link: "/paises/editar-pais",
        classes: "neutral",
        entity: entity,
      },
      {
        text: "Cerrar",
        action: "closeModal",
        classes: "safe",
        entity: entity,
      },
    ],
  };
}
