import React, { useState, useEffect } from "react";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useDispatch, useSelector } from "react-redux";
//Actions
import { loadModalContent } from "../../actions/tableDataActions";
import {
  addVariableRow,
  removeVariableRow,
  removeVariableRowValidation,
} from "../../actions/formDataActions";
//Utilities
import { dispatchModalContent } from "../../utilities/modal";
import { getQueryData } from "../../utilities/button";

const Button = ({ entity, text, action, link, classes, id, pos, disable }) => {
  const queryData = getQueryData(entity, id, text);

  //Store data
  const { info, mutationBackID } = useSelector(
    (state) => state.dataTable.settings
  );
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [allowModal, setAllowModal] = useState(false);

  //Functions
  const retriveAction = () => {
  
    switch (action) {
      case "modal":
        setAllowModal(true);
      
        getModal();
        break;
      case "link":
        if (id === 0 || id === "0") {
          window.location.href = link;
        } else if (mutationBackID) {
          window.location.href = link + mutationBackID;
        } else {
          window.location.href = link + "/" + id;
        }
        break;
      case "closeModal":
        break;
      case "addVariableRow":
        if (!disable) {
          storeAddVariableRow();
        }
        break;
      case "removeVariableRow":
        if (!disable) {
          storeRemoveVariableRow(pos);
          storeRemoveVariableRowValidation(pos);
        }
        break;
      default:
        break;
    }
  };

  //API
  const getQuery = () => {
    return gql`
    query ${queryData.queryName} {
      ${queryData.queryName} ${queryData.queryParameters}{
        ${queryData.queryFields.map((queryField) => queryField)}
      }
    }
  `;
  };

  const [getModal, { error, data }] = useLazyQuery(getQuery());

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadModalContent = (modalContent) =>
    dispatch(loadModalContent(modalContent));
  const storeAddVariableRow = () => dispatch(addVariableRow());
  const storeRemoveVariableRow = (position) =>
    dispatch(removeVariableRow(position));
  const storeRemoveVariableRowValidation = (pos) =>
    dispatch(removeVariableRowValidation(pos));

  //useEffect
  useEffect(() => {
    if (data && !error && allowModal) {
      setAllowModal(false);

      const modalContent = dispatchModalContent(
        entity,
        data[queryData.queryName],
        text,
        info,
        formValues
      );
      storeLoadModalContent(modalContent);
    } else if (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [data, error, allowModal]);

  return (
    <button
      className={
        "button " +
        classes +
        (disable && action === "removeVariableRow" ? " disabled" : "")
      }
      data-action={action}
      id={id}
      onClick={retriveAction}
    >
      {text}
    </button>
  );
};

export default Button;
