export function getNewResults(originalData, search, orderBy) {
  //Gets new results to show using search if any and filter if any
  let res = {
    getBusinessGroups: [],
  };
  // eslint-disable-next-line
  originalData.getBusinessGroups.map((result) => {
    const searchOK = validateResultWithSearch(result, search);

    if (searchOK) {
      res.getBusinessGroups.push(result);
    }
  });

  //Use OrderBy items
  const orderedRes = getOrderedRes(res.getBusinessGroups, orderBy);
  res.getBusinessGroups = orderedRes;
  return res;
}

function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function validateResultWithSearch(result, search) {
  //Filter results with search
  let res = false;
  //Filters by search if any
  if (search !== "") {
    if (
      result &&
      removeAccents(result.name.toLowerCase()).includes(
        removeAccents(search.toLowerCase())
      )
    ) {
      res = true;
    }
  } else {
    res = true;
  }
  return res;
}

function getOrderedRes(results, orderBy) {
  let res = [];
  //If exists orderBy config
  if (orderBy && orderBy.length > 0) {
    //If there are two orders
    if (orderBy.length === 2) {
      const firstFieldToShort = orderBy[0].field;
      const secondFieldToShort = orderBy[1].field;
      res = results.sort(function (a, b) {
        var aSize = a[firstFieldToShort];
        var bSize = b[firstFieldToShort];
        var aSizeLow = removeAccents(aSize.toLowerCase());
        var bSizeLow = removeAccents(bSize.toLowerCase());
        var aLow = a[secondFieldToShort];
        var bLow = b[secondFieldToShort];
        var aLowLow = removeAccents(aLow.toLowerCase());
        var bLowLow = removeAccents(bLow.toLowerCase());

        if (aSizeLow === bSizeLow) {
          return aLowLow < bLowLow ? -1 : aLowLow > bLowLow ? 1 : 0;
        } else {
          return aSizeLow < bSizeLow ? -1 : 1;
        }
      });
    } else {
      const fieldToShort = orderBy[0].field;
      // Sort depending of criteria
      if (orderBy[0].criteria === "asc") {
        res = results.sort((a, b) => {
          const aValue = a[fieldToShort];
          const bValue = b[fieldToShort];
          const aValueLower = removeAccents(aValue.toLowerCase());
          const bValueLower = removeAccents(bValue.toLowerCase());

          if (aValueLower > bValueLower) {
            return -1;
          }
          return aValueLower < bValueLower ? 1 : 0;
        });
      } else {
        res = results.sort((a, b) => {
          const aValue = a[fieldToShort];
          const bValue = b[fieldToShort];
          const aValueLower = removeAccents(aValue.toLowerCase());
          const bValueLower = removeAccents(bValue.toLowerCase());
          if (aValueLower < bValueLower) return -1;
          return aValueLower > bValueLower ? 1 : 0;
        });
      }
    }
  } else {
    return results;
  }
  return res;
}
