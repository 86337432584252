export function lineaPedidoEliminarModal(item, entity) {
  return {
    id: item.id,
    title:
      "Por favor, confirme que desea eliminar esta línea del pedido",
    subtitle:
      "¡CUIDADO! Eliminar esta línea del pedido eliminará todas las prendas pedidas para este trabajador. Esta acción no se puede deshacer.",
    texts: [
      {
        text: "Código del trabajador",
        value: item.worker_code,
      },
      {
        text: "Nombre del trabajador",
        value: item.worker_name,
      },
      {
        text: "Cargo",
        value: item.job,
      },
      {
        text: "Prendas",
        html: item.garments,
      },
    ],
    buttons: [
      {
        text: "Confirmar",
        action: "delete",
        classes: "unsafe",
        entity: entity,
        actionType: "mutation",
      },
      {
        text: "Cancelar",
        action: "closeModal",
        classes: "safe",
        entity: entity,
      },
    ],
  };
}
