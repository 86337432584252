import React, { useState, useEffect } from "react";
//SVG icons
import { ReactComponent as MagnifyingGlass } from "../../assets/images/magnifyingGlass.svg";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  addSearch,
  removeSearch,
  reloadResults,
  loadCurrentPage
} from "../../actions/tableDataActions";

const TableSearch = ({ label }) => {
  //Store data
  const { search } = useSelector(
    (state) => state.dataTable
  );

  //States
  const [searchTermError, setSearchTermError] = useState("false");
  const [searchValue, setSearchValue] = useState("");

  //Redux actions
  const dispatch = useDispatch();
  const loadSearchTerm = (term) => dispatch(addSearch(term));
  const cleanSearchTerm = () => dispatch(removeSearch());
  const storeReloadResults = (config) => dispatch(reloadResults(config));
  const storeLoadCurrentPage = (page) => dispatch(loadCurrentPage(page));

  //Functions
  const searchValidate = (e) => {
    if (e.target.value === "") {
      setSearchTermError("true");
      cleanSearchTerm();
    } else {
      loadSearchTerm(e.target.value);
    }
    
    setSearchValue(e.target.value);

    // Resets page to 1
    storeLoadCurrentPage(1);

    //Adds Flag to reload content
    //storeReloadResults(true);

  };

  //useEffect
  useEffect(() => {
    if (search !== "") {
      setSearchTermError("false");
    }

    // Resets page to 1
    storeLoadCurrentPage(1);

    //Adds Flag to reload content
    storeReloadResults(true);

    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <div className="table-container__search-field">
      <div className="table-container__form-label">{label}</div>
      <div className="table-container__form-input">
        <MagnifyingGlass />
        <input
          type="text"
          onChange={searchValidate}
          className={searchTermError !== "false" ? "errorInput" : ""}
          placeholder={
            searchTermError !== "false"
              ? "Por favor, escriba algo para buscar"
              : ""
          }
        />
      </div>
    </div>
  );
};

export default TableSearch;
