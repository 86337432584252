import React from "react";

const LostPasswordLink = () => {

  return (
    <div className="login-container__text-after">
      ¿No recuerda sus credenciales?{" "}
      <a href="/query-new-password/">
        Haga clic aquí para cambiar su contraseña.
      </a>
    </div>
  );
};

export default LostPasswordLink;
