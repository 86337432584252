import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as Labels11 } from "../../../../assets/images/labels1-1.svg";
//Templates
import DeliveryNotesTemplate from "../deliveryNote/DeliveryNotesTemplate";

const PrintOrderDeliveryNoteButton = () => {
  //States
  const [frameTarget, setFrameTarjet] = useState("");
  const [isDeliveryNoteReady, setIsDeliveryNoteReady] = useState(false);

  //Functions
  const retrieveAction = (e) => {
    setIsDeliveryNoteReady(true);
  };

  const sendToPrint = (frameTarget) => {
    var content = document.getElementById(frameTarget);

    var pri = document.getElementById("documents-to-print").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
    setFrameTarjet("");
    setIsDeliveryNoteReady(false);
  };

  //useEffect
  useEffect(() => {
    if (frameTarget !== "") {
      sendToPrint(frameTarget);
    }
  }, [frameTarget]);

  return (
    <div className="print-document__container delivery-note">
      <div className="print-document__texts-container">
        <h2 className="print-document__texts-title">Albarán</h2>
      </div>
      <div className="print-document__buttons-container">
        <div className="print-document__button-container">
          <div
            className="hitPoint"
            data-type="delivery-note"
            onClick={retrieveAction}
          ></div>
          <div className="print-document__buttons-icon">
            <Labels11 />
          </div>
          <div className="print-document__buttons-texts">
            <h2 className="print-document__texts-title"> </h2>
            <p className="print-document__texts-text">
              Generalmente uno por hoja DIN A4
            </p>
          </div>
        </div>
      </div>
      {isDeliveryNoteReady && (
        <DeliveryNotesTemplate setFrameTarjet={setFrameTarjet} />
      )}
    </div>
  );
};

export default PrintOrderDeliveryNoteButton;
