export function getNewResults(originalData, search, filters, orderBy) {
  //Gets new results to show using search if any and filter if any
  let res = {
    getPrendasType: originalData.getPrendasType,
    getPrendas: [],
  };
  // eslint-disable-next-line
  originalData.getPrendas.map((result) => {
    const searchOK = validateResultWithSearch(result, search);
    const filtersOK = validateResultWithFilters(result, filters);

    if (searchOK && filtersOK) {
      res.getPrendas.push(result);
    }
  });

  //Use OrderBy items
  const orderedRes = getOrderedRes(res.getPrendas, orderBy);
  res.getPrendas = orderedRes;
  return res;
}

function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function validateResultWithSearch(result, search) {
  //Filter results with search
  let res = false;
  //Filters by search if any
  if (search !== "") {
    if (
      result &&
      (removeAccents(result.name.toLowerCase()).includes(
        removeAccents(search.toLowerCase())
      ) ||
        removeAccents(result.reference.toLowerCase()).includes(
          removeAccents(search.toLowerCase())
        ) ||
        removeAccents(result.type.toLowerCase()).includes(
          removeAccents(search.toLowerCase())
        ))
    ) {
      res = true;
    }
  } else {
    res = true;
  }
  return res;
}

function validateResultWithFilters(result, filters) {
  //Filter results with filters
  let res = true;
  let filterValidation = [];

  for (let i = 0; i < filters.length; i++) {
    // If filter matches or is set to none
    if (filters[i].value === result.type || filters[i].value === "") {
      filterValidation[i] = true;
    } else {
      filterValidation[i] = false;
    }
  }

  // eslint-disable-next-line
  filterValidation.map((validation) => {
    // If any filter isn´t disabled or doesn´t match
    if (!validation) {
      res = false;
    }
  });
  return res;
}

function getOrderedRes(results, orderBy) {
  let res = [];
  //If exists orderBy config
  if (orderBy && orderBy.length > 0) {
    //If there are two orders
    if (orderBy.length === 2) {
      const firstFieldToShort = orderBy[0].field;
      const secondFieldToShort = orderBy[1].field;
      res = results.sort(function (a, b) {
        const aSize = a[firstFieldToShort];
        const bSize = b[firstFieldToShort];
        const aSizeLower = removeAccents(aSize.toLowerCase());
        const bSizeLower = removeAccents(bSize.toLowerCase());

        var aLow = a[secondFieldToShort];
        var bLow = b[secondFieldToShort];
        var aLowLower = removeAccents(aLow.toLowerCase());
        var bLowLower = removeAccents(bLow.toLowerCase());

        if (aSizeLower === bSizeLower) {
          return aLowLower < bLowLower ? -1 : aLowLower > bLowLower ? 1 : 0;
        } else {
          return aSize < bSize ? -1 : 1;
        }
      });
    } else {
      const fieldToShort = orderBy[0].field;

      // Sort depending of criteria
      if (orderBy[0].criteria === "asc") {
        res = results.sort((a, b) => {
          const aValue = a[fieldToShort];
          const bValue = b[fieldToShort];
          const aValueLower = removeAccents(aValue.toLowerCase());
          const bValueLower = removeAccents(bValue.toLowerCase());

          if (aValueLower > bValueLower) {
            return -1;
          }
          return a[fieldToShort] < b[fieldToShort] ? 1 : 0;
        });
      } else {
        res = results.sort((a, b) => {
          const aValue = a[fieldToShort];
          const bValue = b[fieldToShort];
          const aValueLower = removeAccents(aValue.toLowerCase());
          const bValueLower = removeAccents(bValue.toLowerCase());
          if (aValueLower < bValueLower) return -1;
          return a[fieldToShort] > b[fieldToShort] ? 1 : 0;
        });
      }
    }
  } else {
    return results;
  }
  return res;
}
