import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormVariableRowValue,
  loadVariableRowValidationState,
} from "../../actions/formDataActions";

const VariableRowFieldText = ({ pos, rowNumber }) => {
  //Store data
  const { settings, formValues } = useSelector((state) => state.dataForm);

  const {
    name,
    label,
    placeholder,
    textOne,
    textTwo,
    defaultValue,
    classes,
    disabled,
    isNumber,
  } = useSelector(
    (state) => state.dataForm.settings.variableRows.fieldGroups[pos]
  );

  const { variableRowValidations } = useSelector((state) => state.dataForm);

  let actualValue =
    formValues[settings.variableRows.entity].create[rowNumber][name];

  //States
  const [displayError, setDisplayError] = useState(false);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormVariableRowValue = (valuePair, rowNumber) =>
    dispatch(loadFormVariableRowValue(valuePair, rowNumber));
  const storeLoadVariableRowValidationState = (rowNumber, name, validated) =>
    dispatch(loadVariableRowValidationState(rowNumber, name, validated));

  //Functions
  const loadInputValue = (e) => {
    storeLoadFormVariableRowValue(
      [e.target.name, isNumber ? parseInt(e.target.value) : e.target.value],
      rowNumber
    );
    //upload store validation state
    // eslint-disable-next-line
    if (e.target.value && e.target.value !== "" && e.target.value !== NaN) {
      storeLoadVariableRowValidationState(rowNumber, name, true);
    } else {
      storeLoadVariableRowValidationState(rowNumber, name, false);
    }
  };

  //useEffect
  useEffect(() => {
    let resu = false;

    if (variableRowValidations && variableRowValidations[rowNumber]) {
      // eslint-disable-next-line
      variableRowValidations[rowNumber].map((rowValidations) => {
        if (rowValidations[0] === name && !rowValidations[1]) {
          resu = true;
        }
      });
    }
    setDisplayError(resu);
    // eslint-disable-next-line
  }, [variableRowValidations]);

  useEffect(() => {
    //In case of update, load current value into form values
    if (defaultValue) {
      storeLoadFormVariableRowValue([name, defaultValue]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="input-container">
      {label && rowNumber === 0 && (
        <div className={"input-label " + (classes ? classes : "")}>{label}</div>
      )}
      {textOne && <div className="input-text">{textOne}</div>}
      {textTwo && <div className="input-text">{textTwo}</div>}
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        className={
          (displayError === true ? " errorInput " : "") +
          (classes ? classes : "")
        }
        onChange={loadInputValue}
        defaultValue={
          formValues[settings.variableRows.entity].create[rowNumber][name]
        }
        disabled={disabled ? true : ""}
        value={actualValue}
      />
    </div>
  );
};

export default VariableRowFieldText;
