import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormValue,
} from "../../../../actions/formDataActions";

const InputName = ({ defaultValue }) => {
  //Store data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [displayError, setDisplayError] = useState(false);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //Functions
  const loadInputValue = (e) => {
    storeLoadFormValue([e.target.name, e.target.value]);
  };

  //useEffect
  useEffect(() => {
    //Check field validation
    if (formValues && formValues.name && formValues.name !== "") {
      setDisplayError(false);
    } else {
      setDisplayError(true);
    }
    // eslint-disable-next-line
  }, [formValues.name]);

  useEffect(() => {
    //In case of update, load current value into form values
    if (defaultValue) {
      storeLoadFormValue(["name", defaultValue]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="input-container select col-4">
      <div className="input-label">Nombre</div>
      <input
        type="text"
        name="name"
        placeholder="Escriba el nombre del cliente *"
        className={(displayError === true ? " errorInput " : "")}
        onChange={loadInputValue}
        defaultValue={defaultValue ? defaultValue : ""}
      />
    </div>
  );
};

export default InputName;
