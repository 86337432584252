import React from "react";
//Router redirect
import { useHistory } from "react-router-dom";
//Redux
import { useSelector } from "react-redux";
//Notifications
import { toast } from "react-toastify";

const ButtonCancelWorkplace = () => {
  //Store Data
  const { formValues } = useSelector((state) => state.dataForm);

  //Redirection
  let history = useHistory();

  //Functions
  const retriveAction = () => {
    const messageKO = "No se han hecho cambios en los centros de trabajo de este cliente";
    const redirection = `/centros-de-trabajo/ver/${formValues.client_id}`;

    //Notify KO
    toast.error(messageKO);

    //Redirect
    history.push(redirection);
  };

  return (
    <button className="button unsafe" onClick={retriveAction}>
      Cancelar
    </button>
  );
};

export default ButtonCancelWorkplace;
