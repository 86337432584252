import React from "react";
import "../../assets/css/header.css";
import NavItem from "./NavItem";
//Cookie token
import cookie from "react-cookies";

const Header = () => {
  var token = cookie.load("usertk");
  var url = window.location.pathname;
  var show = token && url !== "/logout" ? true : false;
  return (
    <>
      {show && (
        <div className="nav">
          <div className="nav-container">
            <div className="nav-container__logo">
              <a href="/dashboard">
                <svg
                  id="oroel-logotipo"
                  xmlns="http://www.w3.org/2000/svg"
                  width="159.514"
                  height="38"
                  viewBox="0 0 159.514 38"
                >
                  <path
                    id="Trazado_34"
                    data-name="Trazado 34"
                    d="M353.528,69.4a7.828,7.828,0,1,0,7.828,7.828h0a7.823,7.823,0,0,0-7.828-7.828Zm0,11.668a3.84,3.84,0,1,1,3.84-3.84h0a3.842,3.842,0,0,1-3.84,3.84Z"
                    transform="translate(-288.485 -57.914)"
                    fill="#2c3d71"
                  />
                  <path
                    id="Trazado_35"
                    data-name="Trazado 35"
                    d="M598.628,69.4a7.828,7.828,0,1,0,7.828,7.828h0a7.823,7.823,0,0,0-7.828-7.828Zm0,11.668a3.84,3.84,0,1,1,3.84-3.84h0a3.821,3.821,0,0,1-3.84,3.84Z"
                    transform="translate(-493.019 -57.914)"
                    fill="#2c3d71"
                  />
                  <path
                    id="Trazado_36"
                    data-name="Trazado 36"
                    d="M734.345,75.658V72.1H724.2V86.78h10.146V83.205h-6.554V81.219h3.459V77.644h-3.459V75.658Z"
                    transform="translate(-604.341 -60.167)"
                    fill="#2c3d71"
                  />
                  <path
                    id="Trazado_37"
                    data-name="Trazado 37"
                    d="M828.9,72.1V86.78h10.146V83.205h-6.554V72.1Z"
                    transform="translate(-691.713 -60.167)"
                    fill="#2c3d71"
                  />
                  <path
                    id="Trazado_38"
                    data-name="Trazado 38"
                    d="M490.137,81.865a5.3,5.3,0,0,0-2.863-9.765H481.1V86.8h3.591V80.624l4.651,6.173h4.5Zm-2.88-2.747h-2.582V75.691h2.582a1.713,1.713,0,1,1,0,3.426Z"
                    transform="translate(-401.475 -60.167)"
                    fill="#2c3d71"
                  />
                  <path
                    id="Trazado_39"
                    data-name="Trazado 39"
                    d="M0,0V38H159.514V0ZM.629,18.139l7.233,1.506L.629,20.9Zm0,3.393,8.358-1.44L.629,36.378Zm.2,15.839L9.566,20.357,26.6,34.392l-4.651,2.979Zm35.766-9.4-9.45,6.057-16.7-13.77ZM11.85,20.026l19.53.265,5.147,7.017ZM23.1,37.371l3.989-2.549,3.095,2.549Zm4.535-2.913,9.55-6.14L38.265,30.7l1.688,3.707-8.838,2.913Zm12.446,2.913H33.051L40.1,35.054Zm4.833,0h-4.2v-2.83h0l-3.294-7.117-5.71-7.779L9.583,19.364.629,17.494V.629H44.918Zm113.95,0H45.547V.629H158.885Z"
                    fill="#2c3d71"
                  />
                  <path
                    id="Trazado_40"
                    data-name="Trazado 40"
                    d="M3.8,109.6v2.747l7.233-1.241Z"
                    transform="translate(-3.171 -91.461)"
                    fill="#d76061"
                  />
                  <path
                    id="Trazado_41"
                    data-name="Trazado 41"
                    d="M3.8,122.84v14.846L12.158,121.4Z"
                    transform="translate(-3.171 -101.308)"
                    fill="#902e14"
                  />
                  <path
                    id="Trazado_42"
                    data-name="Trazado 42"
                    d="M13.739,123,5,140.014H26.118l4.634-2.979Z"
                    transform="translate(-4.172 -102.643)"
                    fill="#fbe706"
                  />
                  <path
                    id="Trazado_43"
                    data-name="Trazado 43"
                    d="M63.1,122.4l16.7,13.77,9.45-6.057Z"
                    transform="translate(-52.657 -102.142)"
                    fill="#262e64"
                  />
                  <path
                    id="Trazado_44"
                    data-name="Trazado 44"
                    d="M71.6,120.9l24.693,7.282-5.164-7Z"
                    transform="translate(-59.75 -100.89)"
                    fill="#018a38"
                  />
                  <path
                    id="Trazado_45"
                    data-name="Trazado 45"
                    d="M176.65,171.1l2.764,6.107-8.838,2.913-3.476-2.88Z"
                    transform="translate(-139.444 -142.782)"
                    fill="#fc65df"
                  />
                  <path
                    id="Trazado_46"
                    data-name="Trazado 46"
                    d="M199.6,214.117l7.051-2.317v2.317Z"
                    transform="translate(-166.565 -176.746)"
                    fill="#71f5ff"
                  />
                  <path
                    id="Trazado_47"
                    data-name="Trazado 47"
                    d="M146.684,212.949l-3.095-2.549-3.989,2.549Z"
                    transform="translate(-116.495 -175.578)"
                    fill="#ea9d13"
                  />
                </svg>
                <span style={{marginLeft: 12, fontWeight: "900", color: "rgb(44, 61, 113)"}}>beta</span>
              </a>
            </div>
            <div className="nav-container__items">
              <NavItem
                parent={"Clientes"}
                children={[
                  { name: "Crear Cliente", link: "/crear-cliente" },
                  { name: "Buscar Cliente", link: "/buscar-cliente" },
                  { name: "Grupos de empresas", link: "/buscar-grupo-de-empresas" },
                ]}
              />
              <NavItem
                parent={"Prendas"}
                children={[
                  { name: "Crear Prenda", link: "/crear-prenda" },
                  { name: "Buscar Prenda", link: "/buscar-prenda" },
                  { name: "Tipos de prendas", link: "/buscar-tipo-de-prenda" },
                ]}
              />
              <NavItem
                parent={"Pedidos"}
                children={[
                  { name: "Crear pedido", link: "/crear-pedido/seleccionar-cliente" },
                  { name: "Buscar pedido", link: "/buscar-pedido" },
                ]}
              />
              <NavItem
                parent={"Localizaciones"}
                children={[
                  { name: "Países", link: "/paises" },
                  { name: "Provincias", link: "/provincias/seleccione-pais" },
                  { name: "Poblaciones", link: "/poblaciones/seleccione-pais" },
                  { name: "Centros de trabajo", link: "/centros-de-trabajo/seleccione-cliente" },
                ]}
              />
              <NavItem parent={"Desconectar"} link="/logout" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
