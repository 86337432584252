import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as Chevron } from "../../../../../assets/images/chevron.svg";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { loadFormValue } from "../../../../../actions/formDataActions";

const FieldSelectSheet = () => {
  //Store data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [valuePlaceholder, setValuePlaceholder] = useState("Seleccione");
  const [selectOptions, setSelectOptions] = useState([]);
  const [activeValue, setActiveValue] = useState("");

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //Functions
  const loadInputValue = (e) => {
    //Loads input value in store
    storeLoadFormValue([
      e.target.dataset.name,
      parseInt(e.target.dataset.optionvalue),
    ]);
    setValuePlaceholder(e.target.dataset.optionname);

    setActiveValue(e.target.dataset.optionname);
  };

  const isCurrent = (option) => {
    //Load style if item is selected
    if (option === formValues["template_id"]) {
      return " current";
    } else {
      return " ";
    }
  };

  //useEffect
  useEffect(() => {
    let res = [];
    //get all available pages
    if (formValues.hojas) {
      // eslint-disable-next-line
      formValues.hojas.map((hoja, index) => {
        res.push({ name: hoja.sheetName, value: index });
      });
      setSelectOptions(res);
    }
    // eslint-disable-next-line
  }, [formValues.hojas]);

  useEffect(() => {
    if (formValues.hoja + 1 > 0) {
      if (
        (formValues.template_id && formValues.template_id === 6) ||
        (formValues.template_id && formValues.template_id === 7)
      ) {
        storeLoadFormValue(["order", formValues.hojas[1].data]);
      } else {
        storeLoadFormValue(["order", formValues.hojas[formValues.hoja].data]);
      }
    }
    // eslint-disable-next-line
  }, [formValues.hoja]);

  return (
    <>
      {formValues.hojas && selectOptions && (
        <div className="input-container select">
          <div className="input-label">Seleccione el nº de hoja</div>
          <>
            {/* Display collapsed */}
            <div className="input-select-collapsed">
              <input
                type="text"
                disabled
                placeholder={valuePlaceholder}
                value={activeValue}
              />
              <Chevron />
            </div>
            {/* Display expanded */}
            <div className="input-select-expanded">
              <div className="input-select-expanded__container">
                <div className="input-select-expanded__option-container">
                  {/* Filter - Select - Options */}
                  {selectOptions &&
                    selectOptions.map((item, index) => (
                      <div
                        className={
                          "input-select-expanded__option " + isCurrent(item.id)
                        }
                        key={index}
                        data-name="hoja"
                        data-optionvalue={item.value}
                        data-optionname={item.name}
                        onClick={loadInputValue}
                      >
                        {item.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default FieldSelectSheet;
