import React from "react";
//Router redirect
import { useHistory } from "react-router-dom";
//GraphQL
import { useMutation, gql } from "@apollo/client";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  loadModalContent,
  deleteTableItem,
  loadCurrentPage,
} from "../../actions/tableDataActions";
import {
  loadValidationStates,
  loadValidationStatesVariableRows,
} from "../../actions/formDataActions";
//Notifications
import { toast } from "react-toastify";
//Utilities
import { validateForm } from "../../utilities/form/validation";
import { getMessageOk, getMutationData } from "../../utilities/buttonMutation";

const ButtonMutation = ({ entity, text, action, classes, id }) => {
  //Store Data
  //const { info } = useSelector((state) => state.dataTable.settings);
  const { formValues, settings, nestedMutation } = useSelector(
    (state) => state.dataForm
  );

  //Redirection
  let history = useHistory();

  //API Data
  const mutationData = getMutationData(entity, id, text, formValues, settings);

  const getMutation = () => {
    return gql`
    mutation ${mutationData.queryName} {
      ${mutationData.queryName} ${mutationData.queryParameters}{
        ${mutationData.queryFields.map((queryField) => queryField)}
      }
      ${nestedMutation ? nestedMutation : ""}
    }
  `;
  };
  // eslint-disable-next-line
  const [executeMutation, { data, error }] = useMutation(getMutation());

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadValidationStates = (validationResult) =>
    dispatch(loadValidationStates(validationResult));
  const storeLoadValidationStatesVariableRows = (
    variableRowsvalidationResult
  ) => dispatch(loadValidationStatesVariableRows(variableRowsvalidationResult));
  const storeLoadCurrentPage = (page) => dispatch(loadCurrentPage(page));
  const storeLoadModalContent = (modalContent) =>
    dispatch(loadModalContent(modalContent));
  const storeDeleteTableItem = (id, queryName) =>
    dispatch(deleteTableItem(id, queryName));

  //Functions
  const retriveAction = () => {
    const messageOK = getMessageOk(entity, action)[0];
    const messageKO = "Por favor, revise los campos marcados en rojo.";
    const redirection = getMessageOk(
      entity,
      action,
      settings.mutationBackID ? settings.mutationBackID : id //For redirection if id are different
    )[1];
    let validation = false;

    if (action === "delete") {
      //Remove modal
      storeLoadModalContent(false);

      //Execute mutation
      executeMutation()
        .then(({ data }) => {
          //Notify OK
          toast.success(messageOK);

          //Delete item from store
          storeDeleteTableItem(id, settings.queryName);

          //Go to first page to prevent show empty page if was last item on page
          if (
            settings.entity !== "pedidoRow" &&
            settings.queryName !== "getPedido"
          ) {
            storeLoadCurrentPage(1);
          }
        })
        .catch((e) => {
          //Notify KO
          toast.error("Lo sentimos, no ha sido posible eliminar el elemento.");
        });
    } else if (action === "save" || action === "update") {
      if (settings.variableRows) {
        validation = validateForm(
          settings.formContent,
          formValues,
          settings.variableRows
        );
      } else {
        validation = validateForm(settings.formContent, formValues);
      }

      if (validation.result) {
        //Validation ok
        //Execute mutation
        executeMutation()
          .then(({ data }) => {
            //Notify OK
            toast.success(messageOK);

            //Redirect
            history.push(redirection);
          })
          .catch((e) => {
            //Notify KO
            toast.error(
              "Lo sentimos, no ha sido posible realizar la operación."
            );
          });
      } else {
        //Validation KO

        //upload store validation state
        storeLoadValidationStates(validation.validationResult);
        if (settings.variableRows) {
          storeLoadValidationStatesVariableRows(
            validation.variableValidationResult
          );
        }

        //Notify KO
        toast.error(messageKO);
      }
    } else if (action === "continue") {
      console.log("Continue");
    }
  };

  return (
    <button
      className={"button " + classes}
      data-action={action}
      id={id}
      onClick={retriveAction}
    >
      {text}
    </button>
  );
};

export default ButtonMutation;
