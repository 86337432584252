import { LOAD_SETTINGS } from "../types/formData";
import { LOAD_SELECT_OPTIONS } from "../types/formData";
import { LOAD_INPUT_VALUE } from "../types/formData";
import { DELETE_INPUT_VALUE } from "../types/formData";
import { LOAD_NEW_SELECT_OPTIONS } from "../types/formData";
import { LOAD_VALIDATION_RESULTS } from "../types/formData";
import { ADD_VARIABLE_ROW } from "../types/formData";
import { REMOVE_VARIABLE_ROW } from "../types/formData";
import { ADD_VARIABLEROW_VALUE } from "../types/formData";
import { ADD_VARIABLEROW_ROOM } from "../types/formData";
import { REMOVE_VARIABLEROW_VALUE } from "../types/formData";
import { LOAD_VROWS_VALIDATION_RESULTS } from "../types/formData";
import { LOAD_VROW_VALUE_VALIDATION } from "../types/formData";
import { LOAD_DEFAULT_VROW_VALIDATION } from "../types/formData";
import { REMOVE_VROW_VALIDATION } from "../types/formData";
import { LOAD_INITIAL_VROWS_VALUES } from "../types/formData";
import { LOAD_NESTED_MUTATION } from "../types/formData";
import { LOAD_GARMENT_DEFAULT } from "../types/formData";
import { LOAD_GARMENT_VALUE } from "../types/formData";
import { LOAD_ORDER } from "../types/formData";
import { LOAD_MODAL_SELECT_PEDIDOROWS } from "../types/formData";

//Loads form settings
export function loadFormSettings(settings) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SETTINGS,
      payload: settings,
    });
  };
}

//Loads form selects options
export function loadSelectOptions(options) {
  return (dispatch) => {
    dispatch({
      type: LOAD_SELECT_OPTIONS,
      payload: options,
    });
  };
}

//Loads form input value
export function loadFormValue(valuePair) {
  return (dispatch) => {
    dispatch({
      type: LOAD_INPUT_VALUE,
      payload: valuePair,
    });
  };
}

//Delete form input value
export function deleteFormValue(key) {
  return (dispatch) => {
    dispatch({
      type: DELETE_INPUT_VALUE,
      key,
    });
  };
}

//Loads new options as result of select search field
export function loadNewSelectOptions(options, position) {
  return (dispatch) => {
    dispatch({
      type: LOAD_NEW_SELECT_OPTIONS,
      payload: {
        options: options,
        position: position,
      },
    });
  };
}

//Loads new options as result of select search field
export function loadValidationStates(validationResults) {
  return (dispatch) => {
    dispatch({
      type: LOAD_VALIDATION_RESULTS,
      payload: validationResults,
    });
  };
}

//Loads new variable row to the front
export function addVariableRow() {
  return (dispatch) => {
    dispatch({
      type: ADD_VARIABLE_ROW,
    });
  };
}

//Removes specific variable row to the front
export function removeVariableRow(position) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_VARIABLE_ROW,
      payload: position,
    });
  };
}

//Makes room in store for another variable Row
export function makeRoomVariableRow() {
  return (dispatch) => {
    dispatch({
      type: ADD_VARIABLEROW_ROOM,
    });
  };
}

//Load to store variable row input value in specific position
export function loadFormVariableRowValue(valueSet, pos) {
  return (dispatch) => {
    dispatch({
      type: ADD_VARIABLEROW_VALUE,
      valueSet,
      pos,
    });
  };
}

export function removeValuesOfVariableRow(pos) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_VARIABLEROW_VALUE,
      pos,
    });
  };
}

//Loads new validation results of variable Rows
export function loadValidationStatesVariableRows(
  variableRowsValidationResults
) {
  return (dispatch) => {
    dispatch({
      type: LOAD_VROWS_VALIDATION_RESULTS,
      payload: variableRowsValidationResults,
    });
  };
}

//Loads new validation state of variable Row value
export function loadVariableRowValidationState(
  rowNumber,
  name,
  validationResult
) {
  return (dispatch) => {
    dispatch({
      type: LOAD_VROW_VALUE_VALIDATION,
      rowNumber,
      name,
      validationResult,
    });
  };
}

//Loads default validation state of new variable Row
export function loadDefaultVRValidation(rowDefaultValues) {
  return (dispatch) => {
    dispatch({
      type: LOAD_DEFAULT_VROW_VALIDATION,
      rowDefaultValues,
    });
  };
}

//Removes validation state of specific variable Row
export function removeVariableRowValidation(pos) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_VROW_VALIDATION,
      pos,
    });
  };
}

//Loads variableRows values of existing Pedido line
export function loadVariableRowsCurrentValues(rowsData, entity) {
  return (dispatch) => {
    dispatch({
      type: LOAD_INITIAL_VROWS_VALUES,
      rowsData,
      entity,
    });
  };
}

//Loads original garments IDs in order to delete before create of update mutation
export function loadNestedMutation(nestedMutation) {
  return (dispatch) => {
    dispatch({
      type: LOAD_NESTED_MUTATION,
      nestedMutation,
    });
  };
}

//Loads default value for new order garment variable Row
export function loadGarmentRowDefault(rowDefault) {
  return (dispatch) => {
    dispatch({
      type: LOAD_GARMENT_DEFAULT,
      rowDefault,
    });
  };
}

//Loads value for order garment variable Row
export function loadGarmentValue(valuePair) {
  return (dispatch) => {
    dispatch({
      type: LOAD_GARMENT_VALUE,
      valuePair
    });
  };
}

//Loads order to print
export function loadPedido(pedido) {
  return (dispatch) => {
    dispatch({
      type: LOAD_ORDER,
      pedido
    });
  };
}

//Loads order to print
export function loadModalSelectOptions(rows) {
  return (dispatch) => {
    dispatch({
      type: LOAD_MODAL_SELECT_PEDIDOROWS,
      rows
    });
  };
}
