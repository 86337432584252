import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormValue,
} from "../../../../../actions/formDataActions";

const InputPostalCode = ({ defaultValue }) => {
  //Store data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [displayError, setDisplayError] = useState(false);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //Functions
  const loadInputValue = (e) => {
    storeLoadFormValue(["postal_code", e.target.value]);
  };

  //useEffect
  useEffect(() => {
    //Check field validation
    if (formValues && formValues.postal_code && formValues.postal_code !== "") {
      setDisplayError(false);
    } else {
      setDisplayError(true);
    }
    // eslint-disable-next-line
  }, [formValues.postal_code]);

  useEffect(() => {
    //In case of update, load current value into form values
    if (defaultValue) {
      storeLoadFormValue(["postal_code", defaultValue]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="input-container">
      <div className="input-label">Código Postal</div>
      <input
        type="text"
        name="postal_code"
        placeholder="Escriba el código postal del centro de trabajo *"
        className={(displayError === true ? " errorInput " : "")}
        onChange={loadInputValue}
        defaultValue={defaultValue ? defaultValue : ""}
      />
    </div>
  );
};

export default InputPostalCode;
