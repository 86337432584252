import React from "react";
//Router redirect
import { useHistory } from "react-router-dom";
//GraphQL
import { useMutation, gql } from "@apollo/client";
//Redux
import { useSelector } from "react-redux";
//Notifications
import { toast } from "react-toastify";

const ButtonCreateWorkplace = () => {
  //Store Data
  const { formValues } = useSelector((state) => state.dataForm);

  //Redirection
  let history = useHistory();

  //API
  const CREATE_WORKPLACE = gql`
    mutation {
        createWorkplace(
            cliente_id: ${formValues.client_id ? formValues.client_id : 0},
            country_id: ${formValues.country_id ? formValues.country_id : 0},
            province_id: ${formValues.province_id ? formValues.province_id : 0},
            location_id: ${formValues.location_id ? formValues.location_id : 0},
            name: "${formValues.name ? formValues.name : ""}",
            address: "${formValues.address ? formValues.address : ""}",
            postal_code: "${formValues.postal_code ? formValues.postal_code : ""}"
        ) {
            id
        }
    }
  `;

  // eslint-disable-next-line
  const [executeMutation, { data, error }] = useMutation(CREATE_WORKPLACE);

  //Functions
  const retriveAction = () => {
    const messageOK = "El centro de trabajo se ha creado correctamente.";
    const messageKO = "Por favor, revise los campos marcados en rojo.";
    const redirection = `/centros-de-trabajo/ver/${formValues.client_id}`;
    let validation = validateWorkplace(formValues);

    if (validation) {
      //Validation ok

      //Execute mutation
      executeMutation()
        .then(({ data }) => {
          //Notify OK
          toast.success(messageOK);

          //Redirect
          history.push(redirection);
        })
        .catch((e) => {
          //Notify KO
          toast.error("Lo sentimos, no ha sido posible realizar la operación.");
        });
    } else {
      //Validation KO

      //Notify KO
      toast.error(messageKO);
    }
  };

  return (
    <button className="button safe" onClick={retriveAction}>
      Crear Centro de trabajo
    </button>
  );
};

export default ButtonCreateWorkplace;

function validateWorkplace(formValues) {
  let res = false;

  if (
    formValues &&
    formValues.client_id &&
    formValues.country_id &&
    formValues.province_id &&
    formValues.location_id &&
    formValues.name &&
    formValues.name !== "" &&
    formValues.address &&
    formValues.address !== "" &&
    formValues.postal_code &&
    formValues.postal_code !== ""
  ) {
    res = true;
  }

  return res;
}
