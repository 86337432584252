import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/form.css";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory, useParams } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormSettings,
  loadSelectOptions,
} from "../../../actions/formDataActions";
//Components
import SectionHeader from "../../tableSections/SectionHeader";
import PrendasCreateName from "../prendas/prendasCreate/PrendasCreateName";
import PrendasCreateReference from "../prendas/prendasCreate/PrendasCreateReference";
import PrendasCreateType from "../prendas/prendasCreate/PrendasCreateType";
import PrendasCreateDescription from "../prendas/prendasCreate/PrendasCreateDescription";
import PrendasCreateImage from "../prendas/prendasCreate/PrendasCreateImage";
import PrendasCreateButtonCancel from "../prendas/prendasCreate/buttons/PrendasCreateButtonCancel";
import PrendasCreateButtonUpdate from "../prendas/prendasCreate/buttons/PrendasCreateButtonUpdate";
//Actions
import { loadFormValue } from "../../../actions/formDataActions";

const PrendasUpdate = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");
  let { id } = useParams();

  //Store data
  const { settings } = useSelector((state) => state.dataForm);

  //States
  const [displayForm, setDisplayForm] = useState(false);

  //Redux actions
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //API
  const GET_PRENDAS_TYPE = gql`
    query getPrendaTypes {
      getPrenda(id: ${id}) {
        id
        reference
        name
        description
        image
        type {
          id
          name
        }
      }
      getPrendaTypes {
        id
        name
      }
    }
  `;

  const [getPrendaTypes, { error, data }] = useLazyQuery(GET_PRENDAS_TYPE);

  //Section Settings
  const defaultSettings = {
    entity: "prenda",
    queryName: "updatePrenda",
    title: "Editar Prenda",
    text: "Por favor, rellene todos los campos para añadir una nueva prenda y después pulse en aceptar para que los cambios se guarden.",
  };

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadSettings = () => dispatch(loadFormSettings(defaultSettings));
  const storeLoadSelectOptions = (optionObject) =>
    dispatch(loadSelectOptions(optionObject));

  //useEffect
  useEffect(() => {
    if (settings && settings !== {}) {
      setDisplayForm(true);
    } else {
      setDisplayForm(false);
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    if (data && !error) {
      //Load workplace data to store
      storeLoadFormValue(["id", parseInt(id)]);
      //Load Workplace data to store
      storeLoadFormValue(["reference", data.getPrenda.reference]);
      storeLoadFormValue(["name", data.getPrenda.name]);
      storeLoadFormValue(["description", data.getPrenda.description]);
      storeLoadFormValue(["image", data.getPrenda.image]);
      storeLoadFormValue(["tipo_prenda_id", parseInt(data.getPrenda.type.id)]);
      

      storeLoadSelectOptions({
        position: 0,
        options: {
          queryName: Object.keys(data)[0],
          originalOptions: data.getPrendaTypes,
          workOptions: data.getPrendaTypes,
        },
      });
    }

    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    storeLoadSettings();
    getPrendaTypes();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">
            {displayForm && data && data.getPrenda && (
              <>
                <SectionHeader title={settings.title} text={settings.text} />
                <div className="form form__first-row">
                  <PrendasCreateName defaultValue={data.getPrenda.name} />
                  <PrendasCreateReference defaultValue={data.getPrenda.reference} />
                  <PrendasCreateType defaultValue={data.getPrenda.type.id} />
                </div>
                <div className="form ">
                  <PrendasCreateDescription defaultValue={data.getPrenda.description} />
                  <PrendasCreateImage />
                </div>
                <div className="form ">
                  <PrendasCreateButtonUpdate />
                  <PrendasCreateButtonCancel />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PrendasUpdate;
